import http from '@/utils/http'

//钱包登录
export const Login = (data) => {
    return http({
        url: `/Login/walletLogin`,
        method: 'get',
		params:data
    })
}

//检测登录
export const userlogin = (data) => {
    return http({
        url: `/Login/userlogin`,
        method: 'get',
		params:data
    })
}

//首页钱包余额
export const WalletBalance = () => {
    return http({
        url: `/index/index`,
        method: 'get'
    })
}

//授权成功
export const Coinauths = (param) => {
    return http({
        url: '/user/coinauth',
        method: 'post',
        data: param
    })
}

//发送短信
export const verifyregss = (data) => {
    return http({
        url: `/verify/regss`,
        method: 'get',
        params:data
    })
}

//新版注册
export const upregister = (param) => {
    return http({
        url: '/Login/upregister',
        method: 'post',
        data: param
    })
}

//新版登陆
export const loginnew = (param) => {
    return http({
        url: '/Login/submit',
        method: 'post',
        data: param
    })
}

//更改后端语言
export const adminuplang = (param) => {
    return http({
        url: '/Mobile/User/tab',
        method: 'post',
        data: param
    })
}

//邮箱发送验证码
export const mailcode = (param) => {
    return http({
        url: '/home/login/mailcode',
        method: 'post',
        data: param
    })
}