<template>
  <div class="layout">
    <div class="layouts layouts2" id="layouts">
      <div class="market-mobile">
        <nav class="nav_mobile_top">
          <div class="nav_content" dir="auto">
            <div class="nav_left">
              <div class="nav_name1">{{ $t('652934-0') }}</div>
            </div>
          </div>
        </nav>
        <div class="content">
          <div class="left-bg1"></div>
          <div class="left-bg2"></div>
          <div class="new-feature" dir="auto">
            <div class="titles">
              <div class="balance">
                <img src="../../static/tab1/top.png" />
              </div>
              {{ $t('652934-1') }}
            </div>
            <div class="banalce-info">
              <div style="display: inline">${{ topinfo.money }}</div>
              <span dir="ltr"></span>
            </div>
            <div class="infos">
              <div class="info">
                <div class="info-title">{{ $t('652934-2') }}</div>
                <div class="info-content">${{ Number(topinfo.dayshouyi).toFixed(2) }}</div>
              </div>
              <div class="info">
                <div class="info-title">{{ $t('652934-3') }}</div>
                <div class="info-content">${{ Number(topinfo.shouyi).toFixed(2) }}</div>
              </div>
              <div class="info"></div>
            </div>
            <div class="big-buttons">
              <div class="button">
                <div class="button-icon">
                  <img src="../../static/tab1/1.png" />
                </div>
                <div class="button-name">{{ $t('652934-4') }}</div>
              </div>
              <div class="button">
                <div class="button-icon">
                  <img src="../../static/tab1/2.png" />
                </div>
                <div class="button-name">{{ $t('652934-5') }}</div>
              </div>
              <div class="button">
                <div class="button-icon">
                  <img src="../../static/tab1/3.png" />
                </div>
                <div class="button-name">{{ $t('652934-6') }}</div>
              </div>
            </div>
          </div>
          <div class="new-create" dir="auto">
            <div class="title">{{ $t('652934-8') }}</div>
            <div class="input-list">
              <div class="inputs inputs-max">
                <div class="lable">{{ $t('652934-9') }}</div>
                <div class="input-content" @click="showPickerDay = true">
                  {{ dayText }} 
                  <img src="../../static/tab1/arrow_down.36c10fef.svg" />
                  <img
                    src="../../static/tab1/arrow_up.4af92010.svg"
                    style="display: none"
                  />
                  <div class="day-lists" style="display: none"></div>
                </div>
              </div>
            </div>
            <div class="input-list">
              <div class="inputs inputs-max">
                <div class="lable">{{ $t('652934-10') }}</div>
                <div class="input-content input-content1">
                  <input
                    type="text"
                    v-model="numVal"
                    onchange="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')"
                    onkeyup="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')"
                    onafterpaste="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')"
                  
                  />
                  <div class="input-button input-buttons1" @click="whole()">Max</div>
                </div>
              </div>
            </div>
            <div class="infos">
              <div class="info">
                <div class="info-title">{{ $t('652934-11') }}</div>
                <div class="info-content">  {{ nowDataInfo.rate_min }} - {{ nowDataInfo.rate_max }}%</div>
              </div>
              <div class="info">
                <div class="info-title">{{ $t('652934-12') }}</div>
                <div class="info-content"> ${{ nowDataInfo.stnum }}-{{ nowDataInfo.maxstrnum }}</div>
              </div>
            </div>
            <div class="confirm-button" @click="subscribe">{{ $t('652934-13') }}</div>
          </div>
          <div class="keyboard" dir="auto" style="display: none">
            <div class="van-number-keyboard" style="display: none">
              <div class="van-number-keyboard__body">
                <div class="van-number-keyboard__keys">
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">1</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">2</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">3</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">4</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">5</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">6</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">7</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">8</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">9</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">.</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div role="button" tabindex="0" class="van-key">0</div>
                  </div>
                  <div class="van-key__wrapper">
                    <div
                      role="button"
                      tabindex="0"
                      class="van-key van-key--delete"
                    >
                      <svg class="van-key__delete-icon" viewBox="0 0 32 22">
                        <path
                          d="M28 0a4 4 0 0 1 4 4v14a4 4 0 0 1-4 4H10.4a2 2 0 0 1-1.4-.6L1 13.1c-.6-.5-.9-1.3-.9-2 0-1 .3-1.7.9-2.2L9 .6a2 2 0 0 1 1.4-.6zm0 2H10.4l-8.2 8.3a1 1 0 0 0-.3.7c0 .3.1.5.3.7l8.2 8.4H28a2 2 0 0 0 2-2V4c0-1.1-.9-2-2-2zm-5 4a1 1 0 0 1 .7.3 1 1 0 0 1 0 1.4L20.4 11l3.3 3.3c.2.2.3.5.3.7 0 .3-.1.5-.3.7a1 1 0 0 1-.7.3 1 1 0 0 1-.7-.3L19 12.4l-3.4 3.3a1 1 0 0 1-.6.3 1 1 0 0 1-.7-.3 1 1 0 0 1-.3-.7c0-.2.1-.5.3-.7l3.3-3.3-3.3-3.3A1 1 0 0 1 14 7c0-.3.1-.5.3-.7A1 1 0 0 1 15 6a1 1 0 0 1 .6.3L19 9.6l3.3-3.3A1 1 0 0 1 23 6z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="showPickerDay" position="bottom">
      <van-picker
        :columns="columns"
        @confirm="onConfirm"
        :confirm-button-text="$t('Confirm')"
        :cancel-button-text="$t('Cancel')"
        @cancel="showPickerDay = false"
      >
      
      </van-picker>
    </van-popup>
 
  </div>
</template>


<script>
// 功能引入
import { toRefs, reactive, onMounted } from "vue";
import BorderCard from "@/components/BorderCard";
import { financinlist, financing ,logall } from "@/api/tab2";

import { initIndex } from "../api/tab4";
import { user } from "../api/tab5";
import {balance} from "@/api/tab1";
import { showToast } from "vant";
import { useI18n } from "vue-i18n";

export default {
  components: { BorderCard },
  setup() {
	const {
	  t,locale
	} = useI18n()
	
    // 数据
    const State = reactive({
      zhiyalist: [],
      topinfo: [],
      showPickerDay: false,
      showPickerUsdt: false,
      increase: "", //涨幅
      increases: "",
      new_price: "", //当前价
      dayText: "",
      dayVal: "",
      coinText: "USDT",
      coinVal: "usdt",
      numVal: "0",
      allList: [], //所以质押列表
      nowDataInfo: {}, //当前选择的天数，对应的值
      columns: [],
      columnsUsdt: [
        { text: "USDT", value: "usdt" },
        { text: "USDC", value: "usdc" },
      ],
      isBlack: "true", //默认黑色（缓存内的值是字符串）
	  jinrishouyi:0,
    });

    // 方法
    const Function = reactive({
      whole(){
        //State.numVal = State.topinfo.money;
		State.numVal = State.nowDataInfo.maxstrnum;
      },
       //质押消息
       balance() {
		balance().then((res) => {
		       
		  State.jinrishouyi = res.data.url.today_profit;
		});
        logall().then((res) => {
       
          State.topinfo = res.data.info;
        });
      },
      getData() {
        financinlist({ type: 0 }).then((res) => {
          State.zhiyalist = res.data.info;
          console.log(res.data);
        });
      },
      setTheme() {
      
        State.isBlack = "true";
        if (localStorage.getItem("isBlack")) {
          State.isBlack = localStorage.getItem("isBlack"); //true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
        }
      },
      doTest() {
        console.log("测试--->", 1111);
      },
      onConfirm(e) {
        console.log("e--->", e);
        State.dayVal = e.selectedOptions[0]?.value;
        State.dayText = e.selectedOptions[0]?.text;
        State.showPickerDay = false;
		State.nowDataInfo = State.allList[e.selectedIndexes[0]]
      },
      onConfirmUsdt(e) {
        console.log("e--->", e);
        State.coinVal = e.selectedOptions[0]?.value;
        State.coinText = e.selectedOptions[0]?.text;
        State.showPickerUsdt = false;
      },
      changeDayVal() {
        //console.log('选择了天数--->', State.dayVal)
        Function.setNowData();
      },
      changeUsdtVal() {
        //console.log('选择了货币--->', State.coinVal)
      },
      getFinancinlist() {
        financinlist().then((res) => {
          //console.log(res);
          /*
                        const {data} = res;
                        for (const key in data) {
                            if (Object.hasOwnProperty.call(data, key)) {
                                const element = data[key];
                                State.columns.push({'text': `${element.tian} Days`, value: element.id})
                            }
                        }
						*/
          const data = res.data.info;
          for (const key in data) {
            const element = data[key];
            State.columns.push({
              text: `${element.tian} Days`,
              value: element.id,
            });
          }
          //console.log(data)
          State.dayVal = State.columns[0].value;
          State.dayText = State.columns[0].text;

          //State.allList = res.data;
          State.allList = res.data.info;
          Function.setNowData();
        });
        user().then((res) => {
          //console.log(res.data.url)
          State.increase = Number(res.data.url.today_profit).toFixed(2);
          //State.new_price = Number(res.data.url.banlance).toFixed(2)
          State.new_price = Number(res.data.url.profit).toFixed(2);
        });
        initIndex().then((res) => {
          //     State.increase = Number(res.data.url.eth.change).toFixed(2)
          //     State.new_price = Number(res.data.url.eth.new_price).toFixed(2)
          State.increases = Number(res.data.url.eth.change).toFixed(2);
        });
      },
      setNowData() {
        let data = State.allList;
        for (let key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            let element = data[key];
            if (element.id == State.dayVal) {
              State.nowDataInfo = element;
            }
          }
        }
        // State.numVal = State.nowDataInfo.stnum;//默认最小值
        //console.log('State.nowDataInfo--->', State.nowDataInfo)
      },
      subscribe() {
        const param = {
          id: State.dayVal,
          price: State.numVal,
          coin: State.coinVal,
        };
		if(Number(State.numVal) < Number(State.nowDataInfo.stnum)){
			showToast(t('lessthan') + State.nowDataInfo.stnum);
			return false
		}
		if(Number(State.numVal) > Number(State.nowDataInfo.maxstrnum)){
			showToast(t('Max') + State.nowDataInfo.maxstrnum);
			return false
		}
		
        financing(param).then((res) => {
          const { data: { info, status, url } = {} } = res;
          showToast({ message: info });
        });
      },
      goRecord() {
        this.$router.push({
          path: "/record",
          query: { active: 5 },
        });
      },
      totext() {
        this.$router.push({
          path: "/web_view",
          query: { active: 1 },
        });
      },
    });
    // 接口
    const Interface = reactive({});

    onMounted(() => {
      Function.getFinancinlist();
      Function.setTheme();
      Function.getData();
      Function.balance();
    });
    return {
      ...toRefs(State),
      
      ...toRefs(Function),
      ...toRefs(Interface),
    };
  },
};
</script>

<style scoped lang="scss">
.nav_mobile_top {
  position: fixed;
  top: 0;
  left: 0;
  height: 29px;
  width: 100%;
  background: #000;
  padding: 16px 0;
  z-index: 10;
}
.nav_mobile_top .nav_content {
  padding: 0 20px;
  height: 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav_mobile_top .nav_content .nav_left {
  display: flex;
  align-items: baseline;
}
.nav_mobile_top .nav_content .nav_left .logo {
  margin-right: 6px;
  height: 36px;
  width: 24px;
  height: 24px;
}
.nav_mobile_top .nav_content .nav_left .logo img {
  height: 100%;
  width: auto;
}
.nav_mobile_top .nav_content .nav_left .nav_name {
  padding-bottom: 8px;
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
}
.nav_mobile_top .nav_content .nav_left .nav_name1 {
  font-weight: 600;
  font-size: 24px;
}
.nav_mobile_top .nav_content .nav_left .nav_name2 {
  font-weight: 600;
  font-size: 18px;
}
.nav_mobile_top .nav_content .nav_left1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nav_mobile_top .nav_content .nav_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 124px;
  height: 42px;
  border-radius: 10px;
  background: #1a1a1a;
  position: relative;
}
.nav_mobile_top .nav_content .nav_right .action {
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin-left: 12px;
  margin-right: 12px;
}
.nav_mobile_top .nav_content .nav_right img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.nav_mobile_top .nav_content .nav_right .day-lists {
  z-index: 10;
  width: calc(100% - 24px);
  height: 84px;
  overflow-y: auto;
  position: absolute;
  top: 50px;
  border-radius: 10px;
  background: #292929fc;
  color: #ffffff4d;
  left: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 8px 12px;
}
.nav_mobile_top .nav_content .nav_right .day-lists .day-list {
  height: 41px;
  line-height: 41px;
}
.nav_mobile_top .nav_content .nav_right .day-lists .day-list1 {
  color: #fff;
}
.nav_mobile_top .nav_content .nav_right .day-lists .day-list2 {
  height: 41px;
  line-height: 14px;
  word-wrap: break-word;
}
.model-mian {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
.model-mian .model-content {
  background: #1a1a1a;
  border-radius: 20px;
  width: 310px;
  height: 346px;
  padding: 24px 20px;
}
.model-mian .model-content .icon {
  margin: auto;
  margin-top: 4px;
  width: 32px;
  height: 32px;
}
.model-mian .model-content .icon img {
  width: 32px;
  height: 32px;
}
.model-mian .model-content .titles {
  margin: auto;
  margin-top: 14px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  width: 218px;
  height: 50px;
}
.model-mian .model-content .titles .titles-info {
  font-weight: 600;
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.3);
  margin-top: 9px;
}
.model-mian .model-content .hr {
  opacity: 0.07;
  border: 1px solid #fff;
  margin: 24px 0;
}
.model-mian .model-content .list {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 20px;
}
.model-mian .model-content .list .lable {
  color: hsla(0, 0%, 100%, 0.3);
}
.model-mian .model-content .confirm-button {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  width: 310px;
  height: 48px;
  background: #c0dd2b;
  border-radius: 13px;
  text-align: center;
  line-height: 48px;
  margin-top: 24px;
}
.market-mobile {
  width: 100%;
  line-height: 100%;
  background: #000;
}
.market-mobile .content {
  width: 100%;
  margin: auto;
  margin-top: 61px;
  padding-bottom: 30px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
}
.market-mobile .content .new-feature {
  width: calc(100% - 32px);
  min-height: 352px;
  background: #1a1a1a;
  border-radius: 20px;
  padding: 16px;
}
.market-mobile .content .new-feature .titles {
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
}
.market-mobile .content .new-feature .titles .balance {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.market-mobile .content .new-feature .banalce-info {
  font-family: DIN Alternate;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  margin-top: 20px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.07);
  padding-bottom: 15.5px;
}
.market-mobile .content .new-feature .banalce-info span {
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
  color: #c2e22a;
  margin-left: 14px;
}
.market-mobile .content .new-feature .infos {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.market-mobile .content .new-feature .infos .info-title {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
.market-mobile .content .new-feature .infos .info-content {
  font-weight: 700;
  font-size: 16px;
  font-family: DIN Alternate;
  margin-top: 8px;
}
.market-mobile .content .new-feature .big-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
}
.market-mobile .content .new-feature .big-buttons .button {
  width: calc(33% - 50px);
  height: 60px;
  background: hsla(0, 0%, 100%, 0.07);
  border-radius: 16px;
  padding: 20px;
}
.market-mobile .content .new-feature .big-buttons .button .button-name {
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
}
.market-mobile .content .new-feature .explain {
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.5);
  line-break: 20px;
  position: relative;
}
.market-mobile .content .new-feature .explain span {
  position: absolute;
  color: #fff;
  width: 140px;
  right: 0;
  bottom: -3px;
  height: 20px;
  background: #1a1a1a;
  padding-left: 10px;
}
.market-mobile .content .new-feature .explain1 p {
  height: 33px;
  overflow: hidden;
}
.market-mobile .content .new-create {
  width: calc(100% - 40px);
  height: 368px;
  background: #1a1a1a;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}
.market-mobile .content .new-create .title {
  font-weight: 600;
  font-size: 16px;
}
.market-mobile .content .new-create .input-list {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
.market-mobile .content .new-create .input-list .inputs {
  width: 140px;
}
.market-mobile .content .new-create .input-list .inputs .lable {
  font-weight: 500;
  font-size: 12px;
  color: hsla(0, 0%, 100%, 0.3);
}
.market-mobile .content .new-create .input-list .inputs .input-content {
  background: #d8d8d814;
  mix-blend-mode: normal;
  border-radius: 10px;
  margin-top: 8px;
  height: 56px;
  width: 104px;
  line-height: 56px;
  padding: 0 16px 0 20px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}
.market-mobile .content .new-create .input-list .inputs .input-content input {
  background: none;
}
.market-mobile .content .new-create .input-list .inputs .input-content img {
  width: 24px;
  height: 24px;
}
.market-mobile
  .content
  .new-create
  .input-list
  .inputs
  .input-content
  .day-lists {
  z-index: 10;
  width: 100%;
  max-height: 280px;
  overflow-y: auto;
  position: absolute;
  top: 64px;
  border-radius: 10px;
  background: #292929fc;
  color: hsla(0, 0%, 100%, 0.3);
  left: 0;
}
.market-mobile
  .content
  .new-create
  .input-list
  .inputs
  .input-content
  .day-lists
  .day-list {
  height: 56px;
  padding: 0 16px 0 24px;
  display: flex;
  justify-content: space-between;
}
.market-mobile
  .content
  .new-create
  .input-list
  .inputs
  .input-content
  .day-lists
  .day-list1 {
  color: #fff;
}
.market-mobile .content .new-create .input-list .inputs .input-content-focus {
  width: 100%;
  border: 1px solid #c0dd2b;
}
.market-mobile .content .new-create .input-list .inputs-max {
  width: 100%;
}
.market-mobile .content .new-create .input-list .inputs-max .input-content {
  padding: 0 16px 0 24px;
  width: 100%;
  box-sizing: border-box;
}
.market-mobile
  .content
  .new-create
  .input-list
  .inputs-max
  .input-content
  .input-button {
  width: 80px;
  height: 44px;
  background: #1a1a1a;
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 44px;
  text-align: center;
}
.market-mobile .content .new-create .input-list .inputs-max .input-content1 {
  padding: 0 6px 0 24px;
  width: 100%;
}
.market-mobile .content .new-create .infos {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.market-mobile .content .new-create .infos .info-title {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  opacity: 0.3;
}
.market-mobile .content .new-create .infos .info-content {
  font-weight: 700;
  font-size: 16px;
  margin-top: 8px;
}
.market-mobile .content .new-create .confirm-button {
  font-weight: 600;
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 48px;
  background: #c0dd2b;
  border-radius: 13px;
  text-align: center;
  line-height: 48px;
  margin-top: 24px;
}
.market-mobile .content .left-bg1 {
  right: -65px;
  top: -100px;
}
.market-mobile .content .left-bg1,
.market-mobile .content .left-bg2 {
  background: #d8d8d8;
  mix-blend-mode: normal;
  opacity: 0.08;
  transform: rotate(-35deg);
  width: 139.84px;
  height: 236.21px;
  position: absolute;
}
.market-mobile .content .left-bg2 {
  right: -100px;
  top: -90px;
}
.market-mobile .content1 {
  padding-bottom: 130px;
}

.nav_name1 {
  color: #fff;
}
.titles {
  color: #fff;
}
.banalce-info {
  color: #fff;
}

.info-content {
  color: #fff;
}
.button-name {
  color: #fff;
}
.title {
  color: #ffff;
}
.layout .layouts2 {
  height: calc(100vh - 75px);
  overflow-y: auto;
}
.layout .layouts {
  width: calc(100% - 40px);
  padding: 0 20px;
}
.button-icon img {
  width: 24px;
}
</style>
