export default {
	account: {
		Start_making_money_plan: "Start making money plan",
		Market: "Market",
		Migital_currency: "Migital currency",
		Foreign_exchange: "Foreign exchange",
		Noble_metal: "Noble metal",
		Send_Cryop_Now: "Send Cryop Now",
		Send: "Send",
		Receive: "Receive",
		Buy: "Buy",
		Currency_account: "Currency account",
		Contract_account: "Contract account",
		Transfer: "Transfer",
		Select_a_wallet: "Select a wallet",
		Select_Coin: "Select Coin",
		Confirm: "Confirm",
		Wallet: "Wallet",
		Availavle: "Availavle",
		Frozen: "Frozen",
		Convert: "Convert",
		Receiving_Address: "Receiving Address",
		Amount: "Amount",
		Max: "Max",
		Send_Now: "Send Now",
		Please_check_if: "Please check if your receiving address is correct before sending, so as not to cause loss of assets Withdrawal fee is ",
		new1: 'cryptocurrency',
		new2: 'Index',
		new3: 'Bulk',
		new4: 'Forex',
		new5: 'Total assets',
	},
	"Confirm": "Confirm",
	"Cancel": "Cancel",
	"964128-0": "Day",
	"964128-1": "Oops!",
	"964128-2": "You have no transaction records. The list is empty.",
	"652934-0": "AI Quantitative Trading",
	"652934-1": "AI Quantitative Amount",
	"652934-2": "Today's Profit",
	"652934-3": "Total Profit",
	"652934-4": "Funds Security",
	"652934-5": "Stable Profit",
	"652934-6": "Easy Operation",
	"652934-7": "Show More",
	"652934-8": "Create AI Quantitative",
	"652934-9": "Term",
	"652934-10": "Amount",
	"652934-11": "Return Rate",
	"652934-12": "Quota",
	"652934-13": "Confirm",
	"652934-14": "Top Information",
	"652934-15": "Theme Mode",
	"652934-16": "Test---",
	"314962-0": "Google 2FA",
	"314962-1": "Download google Authenticator to bind",
	"314962-2": "Copy code",
	"314962-3": "Enter verification code",
	"314962-4": "Request failed:",
	"314962-5": "Verification code copied",
	"314962-6": "Unable to copy text:",
	"314962-7": "Copy failed, please try again",
	"199917-0": "Settings",
	"199917-1": "Notifications",
	"199917-2": "Google 2FA",
	"199917-3": "Email",
	"199917-4": "Language",
	"199917-5": "Version",
	"199917-6": "Logout",
	home: {
		USDTAccount: 'USDT Account',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: 'Open',
		ETHTodayProfit: 'ETH Today\'s Profit',
		totalRevenue: 'Total Revenue',
		portfolio: 'Portfolio',
		reliableSecurity: 'Reliable Security Guarantee',
		stableReliable: 'Stable and Reliable Investment',
		convenienEasy: 'Convenien and Easy Operation',
		pleaseLookForward: 'Please look forward to...',
		rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
	},
	"ConnectW": "Connect Wallet",
	"dappbrowser": "This website only accepts access from decentralized wallet DAPP browsers.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "Earn Interest",
	"$": "$",
	"ETH Today's Profit": "ETH Today's Profit",
	"Wallet": "Wallet",
	"Support": "Support",
	"General": "General",
	"Notifications": "Notifications",
	"Invite Friends": "Invite Friends",
	"FAQ": "FAQ",
	"Privacy & Security": "Privacy & Security",
	"Legality": "Legality",
	"Authenticator": "Authenticator",
	"Settings": "Settings",
	"Change Language": "Change Language",
	"Daytime mode": "Daytime mode",
	"Black mode": "Black mode",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',
	'About_us': 'About us',





	//钱包
	TransactiónDetails: 'Transactión Details',
	dealdetails: 'deal details',
	ViewTransactionHistory: 'View Transaction History',
	Deposit: 'Deposit',
	Rechargeaddress: 'Recharge address：',
	DepositAmount: 'Deposit Amount：',
	EnterDepositAmount: 'Enter Deposit Amount',
	EnterHash: 'Enter Hash',
	Hash: 'Hash：',
	Voucherimage: 'Voucher image：',
	Continue: 'Continue',
	Withdraw: 'Withdraw',
	Withdrawal: 'Withdrawal：',
	EnterAmounttoWithdraw: 'Enter Amount to Withdraw',
	Available: "Available：",
	ReceiveAddress: 'Receive Address：',
	EnterWalletAddress: 'Enter Wallet Address',
	WithdrawalDesc: 'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
	Exchange: 'Exchange',
	Send: 'Send:',
	Max: 'Max:',
	rechargeAmount: 'Please input a recharge amount',
	hashcode: 'hash code and screenshot cannot be empty at the same time',
	correcthashcode: 'please input a correct hash code',
	maximumwithdrawalamount: 'The maximum withdrawal amount is',
	Incorrectamountformat: 'Incorrect amount format',
	copy: 'copy',


	//tab2
	"Total Profit": "Total Profit",
	"Today's Profit": "Today's Profit",
	"What is Automated Trading?": "What is Automated Trading?",
	"Learn about earning": "Learn about earning",
	"Staking Period": "Staking Period",
	"Staking Amount": "Staking Amount",
	"Yield": "Yield",
	"Limit": "Limit",
	"Subscribe": "Subscribe",
	"Record": "Record",

	//tab3
	"Proof of Stake": "Proof of Stake",
	"ETH Today is Price": "ETH Today is Price",
	"ETH Today's Increase": "ETH Today's Increase",
	"Joint Staking": "Joint Staking",
	"Individual Staking": "Individual Staking",
	"tab3long1": "Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2": "Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1": "Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2": "By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3": "Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4": "Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction": "Introduction",
	"webviewlong5": "Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)": "Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6": 'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process": "The Staking Process",
	"webviewlong7": "To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks": "Staking Rewards and Risks",
	"webviewlong8": "By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking": "Joint Staking and Individual Staking",
	"webviewlong9": "For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion": "Conclusion",
	"webviewlong10": "Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total": "Total",

	//record
	"Account": "Account",
	"interest-bearing": "interest-bearing",
	"POS Staking": "POS Staking",
	"convert": "convert",
	"transaction": "transaction",
	"pledge": "pledge",
	"recordno": "You don't have any news yet. The list is empty.",
	"Oops": "Oops",
	"recharge": "recharge",
	"payment": "payment",
	"staking": "staking",
	"income": "income",
	"Pledge quantity": "Pledge quantity",
	"cycle": "cycle",
	"Days Remaining": "Days Remaining",
	"cumulative gain": "cumulative gain",
	"Price increase": "Price increase",
	"Price decrease": "Price decrease",
	"Opening price": "Opening price",
	"Closing price": "Closing price",
	"loss": "loss",
	"pfofit": "pfofit",
	"amount": "amount",

	//notifiCations
	"notifiCationslong1": "Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2": "The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3": "Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4": "Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5": "VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6": "VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7": "VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8": "VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9": "VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10": "VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11": "VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12": "VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13": "VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn": "Refer and Earn",
	"Copy Link": "Copy Link",

	//faq
	"Frequently Asked Questions": "Frequently Asked Questions",

	//authenticator
	"mailbox": "mailbox",
	"Front photo": "Front photo",
	"Back photo": "Back photo",
	"Handheld ID photo": "Handheld ID photo",
	"define": "define",

	//legality
	"legalitylong1": "Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2": "Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3": "1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4": "2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5": "3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6": "Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7": 'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language": "language",
	"Confirm": "Confirm",

	"newtext1": "Sign out",
	"newtext2": "Cancel",
	"newtext3": "Are you sure you want to log out of this account?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "Login",
	"newtext9": "username",
	"newtext10": "Mobile phone number",
	"newtext11": "Please enter user name",
	"newtext12": "Please enter your mobile phone number",
	"newtext13": "password",
	"newtext14": "Please enter password",
	"newtext15": "No account yet",
	"newtext16": "Register",
	"newtext17": "Country",
	"newtext18": "Select a country",
	"newtext19": "Mobile phone number verification code",
	"newtext20": "Please enter the verification code",
	"newtext21": "Email address",
	"newtext22": "Please enter your email address",
	"newtext23": "Confirm password",
	"newtext24": "Please enter the confirmation password",
	"newtext25": "Have an account",
	"newtext26": "Send verification code",
	"newtext27": "Mobile phone number is incorrect",
	"newtext28": "Sent successfully",
	"newtext29": "Registration successful",

	"newtext30": 'currency',
	"newtext31": 'Forex',
	"newtext32": 'precious metals',

	"newtext33": 'Account',
	"newtext34": 'Platform Activities',
	"newtext35": 'Online Service',
	"newtext36": 'Invite',
	"newtext37": 'KYC Verification',
	"newtext38": 'Setting',
	wallet_p: {
		text1: "Copy Address",
		text2: 'Confirm',
		text3: 'Swap from',
		text4: "Swap to",
		text5: "MAX",
		text6: "Invite",
		text7: 'Get referral rewards',
		text8: "When your friends participate in AI arbitrage and rent mining machines,you can get referral rewards",
		text9: "copy",
		text10: 'Share Link',
		text11: "Start verification",
		text12: "Country",
		text13: "Please select a country",
		text14: "First name",
		text15: "Please enter your first name",
		text16: "Last name",
		text17: "Please enter your last name",
		text18: "Certificate Type",
		text19: "Please select Certificate Type",
		text20: "Ceritificate number",
		text21: "Please enter then ceritificate number",
		text22: "Please upload a photo of the front of your ID card",
		text23: "Please upload a photo of the back of your ID card",
		text24: "Please upload a photo of the front of your passport",
		text25: "Please upload a photo of the back of your passport",
		text26: "Email",
		text27: "Please enter your email",
		text28: "send",
		text29: "Vertify code",
		text30: "Please enter your vertify code",
		text31: "ID",
		text32: "passport",
		text33: "cancel",
		text34: "Country/Region",
		text35: "Please enter the correct information to submit for authentication",
		text36: "Successfully sent!",
		text37: "Email format error!",
		text38: "Driver's license",
		text39: "Please upload a photo of the front of your driver's license",
		text40: "Please upload a photo of the reverse side of your driver's license",

		text41: "ID number",
		text42: "Please enter your ID number",
		text43: "Passport number",
		text44: "Please enter passport number",
		text45: "driver's license number",
		text46: "Please enter your driver's license number",
		
		text47:'Please upload a photo of you holding your driver\'s license',
		text48:'Please upload a photo of you holding your ID card',
		text49:'Please upload a photo of you holding your passport',
		text50:'demo',
	},
	jiaoyi: {
		text1: 'Second',
		text2: 'Perpetual',
		text3: 'Spot',
		text4: 'Entrust Now',
		text5: 'Account Type',
		text6: 'Staking Amount',
		text7: 'Price Range',
		text8: 'Purchase Price',
		text9: 'Free',
		text10: 'Balance',
		text11: 'At Least',
		text12: 'Expected',
		text13: 'Purchase Amount',
		text14: 'Direction',
		text15: 'Purchase Price',
		text16: 'Live price',
		text17: 'Delivery Time',
		text18: 'My Contract',
		text19: 'Profit',
		text20: 'Contract',
		text21: 'Delivery Price',
		text22: 'Delivery Time',
		text23: 'Market price',
		text24: 'Up',
		text25: 'Down',
		text26: 'Purchase Price',
		text27: 'MAX',
		text28: 'Margin',
		text29: 'Handling fees',
		text30: 'Buy',
		text31: 'Sell',
		text32: 'Purchase quantity',
		text33: 'Real Account',
		text34: 'Virtual Users',
		text35: 'No data yet',
		text36: 'Current position',
		text37: 'Historical commission',
		text38: 'Closing a Position',
		text39: 'Margin',
		text40: 'Profit and Loss',
		text41: 'hold',
		text42: 'Sell ​​record',
		text43: 'Selling record',
		text44: 'Available',
		text45: 'Convert',
		text46: 'Authentication successful',
		text47: 'Purchase time',
		text48: 'Unread',
		text49: 'Read',
		text50: 'Host order',
		text51: 'Total arbitrage',
		text58: 'Today\'s earnings',
		text52: 'How artificial intelligence robots work',
		text53: 'arbitrage product',
		text54: 'day',
		text55: 'Amount',
		text56: 'Daily income',
		text57: 'Currency type',
		text59: 'Join AI arbitrage',
		text60: 'Zero risk, quick returns',
		text61: 'AI arbitrage',
		text62: 'Expected earnings',
		text63: 'Arbitrage coin type',
		text64: 'Escrow amount',
		text65: 'Host now',
		text66: 'Daily income sent to your USDT wallet',
		text67: 'Investment Fund Zero Risk',
		text68: 'You can withdraw your funds at any time',
		text69: 'Artificial intelligence works 24 hours a day',
		text70: 'Cumulative income',
		text71: 'Expiration time',
		text72: 'all',
		text73: 'In progress',
		text74: 'Complete',
		text75: 'Hosting details',
		text76: 'Currency',
		text77: 'Technical support',
		text78: 'Send email',
	},
	tabbar:{
		text1:'Homepage',
		text2:'Market',
		text3:'Ai Quantitative',
		text4:'Loan',
		text5:'Account',
		text6:'Account Balance',
		text7:'Today\'s Earnings',
		text8:'ROR',
		text9:'See more',
		text10:'Coming soon',
		text11:'New Coin Pre-sale',
		text12:'Embark on a Journey to Digital Wealth',
		text13:'Buy Now',
		text14:'Easy to use',
		text15:'AI Quantitative Trading',
		text16:'Safe / Stable / Simple',
		text17:'Create Transaction',
		text18:'Total Commission',
		text19:'Share this link with your friends and get up to $100 in rewards. ',
		text20:'Recommendation Link',
		text21:'Share with your friends via social software',
	},
	tab4: {
		text1: "Account Balance",
		text2: "Today's Earnings",
		text3: "AI Quantification",
		text4: "Return Rate",
		text5: "Deposit",
		text6: "Wire Transfer",
		text7: "Withdrawal",
		text8: "Deposit",  // Note: This is a duplicate, might need to review if it should be replaced or removed
		text9: "Wallet Address",
		text10: "Copy",
		text11: "Upload Proof",
		text12: "Please double-check the deposit address to ensure it is correct, as incorrect transfers may not be reversible. Deposits require 5 block confirmations before being credited to your account.",
		text13: "Wire Transfer",
		text14: "Before Initiating Wire Transfer:",
		text15: "Before initiating a wire transfer, please contact our customer service team to obtain accurate wire transfer account information. This step ensures the safety and smooth arrival of your funds.",
		text16: "Wire Transfer Processing Time:",
		text17: "Before initiating a wire transfer, please contact our customer service team to obtain accurate wire transfer account information. This step ensures the safety and smooth arrival of your funds.",
		text18: "Assistance During Wire Transfer:",
		text19: "If you encounter any issues or have questions during the wire transfer process, please feel free to contact our customer service team. We are committed to providing assistance and support to ensure a smooth transaction experience for you.",
		text20: "Amount",
		text21: "Withdrawal Currency",
		text22: "Confirm",
		text23: "Please ensure that you confirm your receiving address, as incorrect transfers are irreversible. Withdrawals are subject to a 3% fee and will be credited to your account after 5 network confirmations.",
		text24: "Options Orders",
		text25: "Contract Orders",
		text26: "AI Quantification Orders",
		text27: "History",
		text28: "Invite Friends",
		text29: "FAQs",
		text30: "Contact Us",
		text31: "About Us",
		text32: "Change Language",
		text33: "Logout"
	},
	uploadProof: {
		text1: "Upload Proof",
		text2: "Select Image",
		text3: "Preview",
		text4: "Upload Your Deposit Screenshot",
		text5: "Amount",
		text6: "Transaction Hash",
		text7: "Last 6 Digits",
		text8: "Please upload your cryptocurrency payment screenshot and wait for review after submission",
		text9: "Confirm"
	},
	newadd:{
		text1:'success',
		text2:'failure',
		text3:'Pending',
		text4:'My Cown Token',
		text5:'Supply',
		text6:'Sold',
		text7:'Pre-sale Ended',
		text8:'Amount',
		text9:'Exchange',
		text10:'Technical Features',
		text11:'New Coin uses blockchain technology, with fast transaction speed, low handling fees, high transaction security, as well as smart contract functions and privacy protection technology. ',
		text12:'Project Vision',
		text13:'New Coin\'s vision is to become the world\'s leading digital asset trading platform, providing users with safer, more convenient and efficient digital asset trading services. ',
		text14:'Application Scenarios',
		text15:'New Coin has a wide range of application scenarios, including digital currency trading, digital securities trading, digital asset trading, payment settlement, supply chain finance and other fields. ',
		text16:'Loan amount',
		text17:'Verify your identity and get more services! ',
		text18:'Start verification',
		text19:'I want to borrow...',
		text20:'Loan term',
		text21:'Days',
		text22:'Daily interest rate',
		text23:'Total interest amount',
		text24:'No interest is required within 1 day after the loan, and interest is required after that. ',
		text25:'Borrow now',
		text26:'Borrowing record',
		text27:'Not borrowed yet',
		text28:'Can\'t find your loan information',
		text29:'Exceeds the loan limit',
		text30:'Applying',
		text31:'In progress',
		text32:'Ended',
		text33:'Open',
		text34:'Closed',
		text35:'record',
		text36:'Deposit',
		text37:'Withdraw',
		text38:'Oops!',
		text39:'You have no transaction records yet. The list is empty.',
		text40:'Please enter the amount',
		text41:'Normal',
		text42:'Ended',
		
		text43:"Let's start with Login!",
		text44:'Email',
		text45:'Enter your Email..',
		text46:'Password',
		text47:'Enter your Password..',
		text48:'Forgot the password?',
		text49:'SIGN IN',
		text50:'Don’t have an account?',
		text51:'Register',
		text52:'Verification code sent',
		text53:'verify',
		text54:"Let's start with register!",
		text55:'Confirm Password',
		text56:'Referral/Promo Code',
		text57:'Enter Referral/Promo Code  (Optional)',
		text59:'Already have an account?',
		text60:'Please enter your email address',
		text61:'Please enter your password',
		text62:'Please enter the confirmation password',
		
		text63:'User Authentication',
		text64:'Credit score',
		text65:'To be reviewed',
		text66:'Review passed',
		text67:'Thank you for submitting your KYC documents. Our team will review your information within 48 hours. Please be patient and wait for the review result.',
		text68:'Termination of transaction',
		text69:'Termination Success',
		
		text70:'Status',
		text71:'Send currency',
		text72:'Receive amount',
		
		text73:'Early termination',
		text74:'Early termination will result in a penalty',
		text75:'Liquidated penalty',
		text76:'Refund amount',
		
		text77:'Loan Details',
		text78:'Please check your loan details and confirm',
		text79:'Your loan amount',
		text80:'Loan term',
		text81:'Please confirm and sign',
		text82:'Loan Agreement',
		text83:'Borrow Now',
		text84:'Please read the loan agreement carefully and provide your signature',
		text85:'Loan Agreement ("{xieyimingcheng}") dated {shengxiaori} {shengxiaoyue}month {shengxiaonian} ("Effective Date") entered into by the following parties:',
		text86:'Borrower:',
		text87:'First name:',
		text88:'Last name:',
		text89:'Address: ',
		text90:'Phone:',
		text91:'Lender:',
		text92:'Name:',
		text93:'Together referred to as the "Parties".',
		text94:'Repayment Terms:',
		text95:'1.	Loan Amount: {dknum}',
		text96:'2.	Borrower agrees to repay $ {dknum} ("Loan").',
		text97:'3.	Interest Rate: {interest}%',
		text98:'4.	The agreed-upon interest rate is {interest}% (calculated on a daily basis).',
		text99:'5.	Loan Term: {tian}',
		text100:'6.	The term of this loan is {tian} days.',
		text101:'7.	Repayment Method:',
		text102:'8.	Borrower agrees to repay Lender $ {dknum} principal and $ {zonglixinum} interest upon maturity.',
		text103:'Repayment Applicability:',
		text104:'1.	Late Payment Fee: {zhinajin} * days',
		text105:'2.	If Borrower fails to repay on time, Borrower shall pay a late fee of {zhinajin} (calculated on a daily basis), which shall be considered a penalty rather than a fine.',
		text106:'3.	Early Repayment:',
		text107:'4.	No penalty shall be imposed on Borrower for early repayment.',
		text108:'5.	Default:',
		text109:'6.	If Borrower fails to repay on time, Borrower shall immediately pay all outstanding principal and interest without the need for notice or demand.',
		text110:'7.	Fees:',
		text111:"8.	If Borrower fails to fulfill the repayment obligation on time, Borrower agrees to bear all costs incurred by Lender in the collection process, including but not limited to attorney's fees.",
		text112:'9.	Severability:',
		text113:'10.	If any provision of this Agreement is found to be invalid or unenforceable, such provision shall be voided, but all other provisions shall remain valid and binding.',
		text114:'11.	Legal Effect:',
		text115:'12.	This Agreement is legally binding on both parties and may be signed and executed within the United States and Europe. Both parties declare that they have the authority to sign this Agreement.',
		text116:'13.	Miscellaneous:',
		text117:'14.	According to the terms of this Agreement, the company shall pay in the form of currency in the account balance. Both parties have the right to sign this Agreement. Neither party shall infringe upon the rights of third parties or violate any other agreements between the parties.',
		text118:'15.	Applicable Law:',
		text119:'16.	This Agreement shall be interpreted and enforced in accordance with the laws of the country in which the borrower is located.',
		text120:'17.	Entire Agreement:',
		text121:'18.	Both parties confirm and agree that this Agreement constitutes the entire agreement between the parties. If either party wishes to change, add, or modify any provision, it must be done in writing and signed by both parties.',
		text122:'Both parties agree to the above terms and conditions and sign as follows:',
		text123:'Lender: {xieyimingcheng}',
		text124:'"Borrower"',
		text125:'Borrower Signature:',
		text126:'Signature Date:{shengxiaori} {shengxiaoyue}month {shengxiaonian}',
		text127:'sign',
		text128:'Please provide your authentic signature as it appears on your ID card',
		text129:'Re-sign',
		text130:'confirm',
		text131:'cancel',
		text132:'Repayment amount',
		text133:'Repayment date',
		text134:'Repayment',
		text135:'Download PDF',
		
		text136:'Please verify your real name first',
		text137:'Verified',
	}
}
