<template>
  <div  class="homePage" :class="isBlack != 'true'?'whiteBox':''">
    <div class="top_header">
      <div class="header">
        <div class="header_left">
          <img src="../../static/demo/logo.png" class="logoimg" />
        </div>
        <div class="header_right" @click="upzhstatus=!upzhstatus">
        	<div class="header_right_action">
        		{{username}}
        		<img v-if="upzhstatus" src="../../static/demo/arrow_up.4af92010.svg"  alt="" style="">
        		<img v-else src="../../static/demo/arrow_down.36c10fef.svg" alt="" style="">
        		<div v-if="upzhstatus" class="day-lists-new" style="">
        			<template v-if="is_cs==0">
        				<div class="day-list-new day-list1-new" @click="quehuanzhanghua">{{username}}</div>
        				<div class="day-list-new" @click="quehuanzhanghua">{{$t('wallet_p.text50')}}</div>
        			</template>
        			<template v-else>
        				<div class="day-list-new" @click="quehuanzhanghua">{{username}}</div>
        				<div class="day-list-new day-list1-new" @click="quehuanzhanghua">{{$t('wallet_p.text50')}}</div>
        			</template>
        		</div>
        	</div>
        </div>
      </div>
    </div>
  <div class="content">
    <div class="left-bg1"></div>
    <div class="left-bg2"></div>
    <div class="tab4-content1">
      <span class="tab4-content1-title1"><img src="../../static/demo/tab4_1.svg" />{{ $t('tab4.text1') }}</span>
      <div class="tab4-content1-balance">
        <span>{{ Number(balance).toFixed(0) }}</span>
        <span class="tab4-content1-balance-baifenbi">{{baifenbi}}%</span>
      </div>
      <div class="tab4-content1-info-list">
        <div class="tab4-content1-info">
          <span>{{ $t('tab4.text2') }}</span>
          <span>${{ Number(jinrishouyi).toFixed(2) }}</span>
        </div>
        <div class="tab4-content1-info">
          <span>{{ $t('tab4.text3') }}</span>
          <span>${{ Number(topinfo.shouyi).toFixed(2) }}</span>
        </div >
        <div class="tab4-content1-info">
          <span>{{ $t('tab4.text4') }}</span>
          <span>{{zhiyabaifenbi}}%</span>
        </div>
      </div>
    </div>
    <div  class="menus" dir="auto">
      <div  class="menus-menu"  :class="active === 0? 'menus-menu-active': ''" @click="active = 0">{{ $t('tab4.text5') }}</div>
      <div  class="menus-menu" v-if="false" :class="active === 1? 'menus-menu-active': ''" @click="active = 1">{{ $t('tab4.text6') }}</div>
      <div  class="menus-menu" :class="active === 2? 'menus-menu-active': ''" @click="active = 2">{{ $t('tab4.text7') }}</div>
    </div>
    <div v-if="active === 0" class="content-info" dir="auto">
      <div class="content-info-title2">{{ $t('tab4.text5') }}</div>
      <div class="content-info-input-content" @click="changeContent">
        {{coin}}
        <img src="../../static/demo/arrow_down.36c10fef.svg" v-if="!isDrown"  alt="">
        <img src="../../static/demo/arrow_up.4af92010.svg" v-if="isDrown" alt="" >
        <div class="day-lists-news" v-if="isDrown" >
          <div class="day-list-news" v-for="(item,index) in rechargeList" :key="index" @click="setItem(index)">{{item.title}}</div>
        </div>
      </div>
      <div id="canvas-recharge" class="content-info-qr-img" height="140" width="140" style="height: 140px; width: 140px;">
        <img :src="qrcode">
      </div>
      <div class="content-info-input-list">
        <div class="inputs inputs-max">
          <div class="lable-new">{{ $t('tab4.text9') }}</div>
          <div class="input-content input-content1">
            {{ xianshiaddress }}
            <div class="input-button input-button1" id="copyBtn" @click="copyAddress" data-clipboard-text="3JA7Wz36RoCQSUB3MNHJbqDbPkY3R1vr8K">{{ $t('tab4.text10') }}</div>
          </div>
        </div>
      </div>
      <div class="content-info-confirm-button" @click="goToUploadImg">{{ $t('tab4.text11') }}</div>
      <div class="content-info-seed">
        <p>{{ $t('tab4.text12') }}</p>
      </div>
    </div>
    <div  v-if="active === 1" class="content-info" dir="auto">
      <div class="title2">{{ $t('tab4.text13') }}</div>
      <div class="content-info-content-info-list">
        <div class="list-title-info-list">{{ $t('tab4.text14') }}</div>
        <div class="list-content-info-list">
          {{ $t('tab4.text15') }}
        </div>
        <div class="list-title-info-list">{{ $t('tab4.text16') }}</div>
        <div class="list-content-info-list">
          {{ $t('tab4.text17') }}
        </div>
        <div class="list-title-info-list">{{ $t('tab4.text18') }}</div>
        <div class="list-content-info-list">
          {{ $t('tab4.text19') }}
        </div>
      </div>
      <div class="content-info-confirm-button">{{ $t('tab4.text11') }}</div>
      </div>
    <div v-if="active === 2" class="content-info" dir="auto">
      <div class="content-info-title2">{{ $t('tab4.text7') }}</div>
      <div class="content-info-input-content" @click="changeContent">
        {{ curType.network[curindex].title }}
        <img src="../../static/demo/arrow_down.36c10fef.svg" v-if="!isDrown"  alt="">
        <img src="../../static/demo/arrow_up.4af92010.svg" v-if="isDrown" alt="" >
        <div class="day-lists-news" v-if="isDrown" >
          <div class="day-list-news" v-for="(item, index) in curType.network" @click="settxItem(index)">{{item.title}}</div>
        </div>
      </div>
     <div class="content-info-input-list">
       <div class="inputs inputs-max">
         <div class="lable-new">{{ $t('tab4.text20') }}</div>
         <div class="input-content">
           <input type="text" v-model="amount" onchange="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')" onkeyup="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')" onafterpaste="this.value=this.value.replace(/^\D*(\d{0,9}(?:\.\d{0,9})?).*$/g,'$1')" class="readonly-input">
           <div class="input-button input-buttons" style="background: #1a1a1a;color: #fff;">Max</div>
         </div>
       </div>
     </div>
      <div class="content-info-input-list">
        <div class="inputs inputs-max">
          <div class="lable-new">{{ $t('tab4.text9') }}</div>
          <div class="input-content">
            <input type="text" class="readonly-input" v-model="txaddr">
          </div>
        </div>
      </div>
      <div class="content-info-confirm-button" @click="submittx">{{ $t('tab4.text22') }}</div>
      <div class="content-info-seed">
        <p>{{ $t('tab4.text23') }}</p>
      </div>
    </div>
    <div  class="seeting-main" dir="auto">
		
		<div class="seeting-list" @click="toyanzheng">
		  <div class="lable">{{ $t('newadd.text63') }}</div>
		  <div class="left-icon">
		    <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
		  </div>
		</div>
		<div class="seeting-list" @click="showxinyongpingfen">
		  <div class="lable">{{ $t('newadd.text64') }}</div>
		  <div class="left-icon">
		    <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
		  </div>
		</div>
		
      <div class="seeting-list" @click="tocontractOrder">
        <div class="lable">{{ $t('tab4.text24') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div v-if="false" class="seeting-list" @click="tooptionOrder">
        <div class="lable">{{ $t('tab4.text25') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="topledgehistory">
        <div class="lable">{{ $t('tab4.text26') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="toRecord">
        <div class="lable">{{ $t('tab4.text27') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="toInvite">
        <div class="lable">{{ $t('tab4.text28') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="toFaq">
        <div class="lable">{{ $t('tab4.text29') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="gotoshezhiurl">
        <div class="lable">{{ $t('tab4.text30') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" @click="toabout">
        <div class="lable">{{ $t('tab4.text31') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list"  @click="toLanguage">
        <div class="lable">{{ $t('tab4.text32') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
      <div class="seeting-list" v-if="false"  @click="loginout">
        <div class="lable">{{ $t('tab4.text33') }}</div>
        <div class="left-icon">
          <img src="../../static/demo/arrow-right.c815b06d.svg" alt="" srcset="">
        </div>
      </div>
    </div>
  </div>
    <Loading :show="loading"></Loading>
  </div>
</template>

<script>
	// import {
	//   exchange,
	//   purse,
	//   Exchangeshenqing,
	//   convert,
	//   recharge,
	//   Financerecharge,
	//   uploadimage, banlace
	// } from '@/api/tab5'
import { user, purse,recharge,Exchangeshenqing } from "@/api/tab5";
import { initIndex,loginout,getkefulianjie } from "@/api/tab4";
import { showNotify, showToast, showConfirmDialog, showDialog } from "vant";
import { ElLoading } from "element-plus";
import Loading from "@/components/Loading.vue";
import QRCode from "qrcode";
import {balance} from "@/api/tab1";
import { logall } from "@/api/tab2";
import { loginnew,Login } from '@/api/hometab'

export default {
  components: {Loading},
  data() {
    return {
      increase: "", //涨幅
      new_price: "", //当前价
      walletaccount: "", //钱包账号
      userid: "", //当前用户id
      isBlack: "true", //默认黑色主题 //true代表黑色主题，false代表白色主题。。。
      setThemeVal: true,
      vipimgsrc: "",
      vipshow: false,
      loading: false,
      active: 0,
      isDrown: false,
      coin: "",
      address: "",
	  xianshiaddress:'',
      qrcode: "",
      balance: "",
	  
	  rechargeList: [],
	  czindex:0,
	  username:'',
	  
	  curType:{
	  	network: [{title: 'ERC20', active: true, type:'USDT'}, {title: 'TRC20', active: false, type:'USDT'}, {title: 'BTC', active: false, type:'BTC'}]
	  },
	  curindex:0,
	  tbchannel:'',
	  amount:'',
	  txaddr:'',
	  
	  upzhstatus:false,
	  is_cs:0,//0:正常 1：模拟
	  
	  topinfo: [],
	  xinyongpingfen:0,
	  jinrishouyi:0,
	  loginip:'0,0,0,0',
	  baifenbi:'0.00',
	  zhiyabaifenbi:'0.00',
	  
	  tzurl:'',
    };
  },
  created() {
	var element = document.getElementById('app');
	element.style.height='100%' 
    this.getHomeData();
	this.getBalance();
	fetch('https://09122.13lt.com/api/getip').
	then(response => response.json()).
	then(data => {
		if(data.status===1){
			this.loginip = data.info
		}
	}).catch(error => {
		console.error(error)
	})
  },
  methods: {
	submittx(){
		let data = {
			amount:this.amount,
			addr:this.txaddr,
			qianbao:this.txaddr,
			tbchannel:this.tbchannel
		}
		Exchangeshenqing(data).then(res => {
		    const {
		        data: {
		            info,
		            status,
		            url
		        } = {}
		    } = res;
		    showToast({
		        message: info
		    });
			this.amount = ''
			this.txaddr = ''
		})
	},
	settxItem(index){
		this.curindex = index
		this.tbchannel = this.curType.network[this.curindex].type+'_'+this.curType.network[this.curindex].title
	},
    generateQRCode() {
        QRCode.toDataURL(this.address, { errorCorrectionLevel: 'H' }, (err, url) => {
          if (err) console.error(err)
          this.qrcode = url;
        })
    },
    changeContent(){
      this.isDrown = !this.isDrown;
    },
    setItem(index){
		this.czindex = index
		this.coin = this.rechargeList[this.czindex].title
		this.address = this.rechargeList[this.czindex].url
		this.xianshiaddress = this.address.substr(0,5) +'...'+this.address.substr(-14)
		this.generateQRCode();
    },
    getBalance(){
      balance().then((res)=>{
        this.balance = res.data.url.banlance;
		this.jinrishouyi = res.data.url.today_profit;
		this.baifenbi = (Number(res.data.url.today_ratio) * 100).toFixed(2);
		this.zhiyabaifenbi = (Number(res.data.url.today_zhiya) * 100).toFixed(2);
      })
		recharge().then(res => {
			this.rechargeList = res.data.info
			this.coin = this.rechargeList[this.czindex].title
			this.address = this.rechargeList[this.czindex].url
			this.xianshiaddress = this.address.substr(0,5) +'...'+this.address.substr(-14)
			this.generateQRCode();
			//console.log(this.rechargeList[this.czindex].title)
		})
    },
    goToUploadImg(){
      this.$router.push({
        path: "/uploadProof",
        query: {
          currneyName: this.rechargeList[this.czindex].id
        }
      });
    },
    async copyAddress() {
      try {
        await navigator.clipboard.writeText(this.address);
        showToast("Copy Success")
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    },
	quehuanzhanghua(){
		const loading = ElLoading.service({
		  lock: true,
		  text: "Loading",
		  background: "rgba(0, 0, 0, 0.7)",
		});
		if(this.is_cs==0){
			//切换到模拟
			//loginnew({type:2,username:localStorage.getItem('username'),is_cs:1}).then(res => {
			Login({username:localStorage.getItem('username'),ip:this.loginip,is_cs:1}).then(res => {
				loading.close();
				if(res.data.status==0){
					showToast(res.data.info)
				} else if(res.data.status==1){
					window.location.reload()
				}
			}).catch((error) => {
				//console.log(error.message)
				showToast(error)
			})
		} else {
			//切换到正式
			//loginnew({type:2,username:localStorage.getItem('username'),password:localStorage.getItem('loginpassword'),is_cs:0}).then(res => {
			Login({username:localStorage.getItem('username'),ip:this.loginip,is_cs:0}).then(res => {
				loading.close();
				if(res.data.status==0){
					showToast(res.data.info)
				} else if(res.data.status==1){
					window.location.reload()
				}
			}).catch((error) => {
				//console.log(error.message)
				showToast(error)
			})
		}
	},
    //获取首页数据
    getHomeData() {
      //todo 模拟切换主题动画，提升交互效果
	  //let usernamedata = localStorage.getItem('username').split('@')
	  //this.username = usernamedata[0]
	  this.username = localStorage.getItem('username').substr(-8)
	  this.tbchannel = this.curType.network[this.curindex].type+'_'+this.curType.network[this.curindex].title
	  user().then(res => {
	  	//console.log('用户中心',res.data)
	  	this.is_cs = res.data.url.is_cs
		this.xinyongpingfen = Number(res.data.url.integral).toFixed(0)
	  })
      initIndex()
        .then((res) => {
          //this.increase = Number(res.data.url.eth.change).toFixed(2)
          this.new_price = Number(res.data.url.eth.new_price).toFixed(2);
          //let username = sessionStorage.getItem("username") || "";
		  let username = localStorage.getItem('username') || "";
          //this.walletaccount = new Array(10).join('*') + username.substr(-4)
          this.walletaccount = username;
          this.userid = res.data.url.userid;
          if (res.data.url.vip.img) {
            this.vipimgsrc = "https://09121.13lt.com" + res.data.url.vip.img;
            this.vipshow = true;
          }
        })
        .catch((err) => {
          console.log("err-->", err);
        });
      purse()
        .then((res) => {
          //console.log(res.data.info.allfee.eth)
          this.increase = Number(res.data.info.allfee.eth).toFixed(2);
        })
        .catch((err) => {
          console.log("err-->", err);
        });
		logall().then((res) => {
		       
		  this.topinfo = res.data.info;
		});
		getkefulianjie().then((res) => {
			//console.log('获取后台设置的跳转链接',res.data)
			if(res.data.info.length > 0){
				this.tzurl = res.data.info[0].url
			}
			//console.log(this.tzurl)
		});
    },
	gotoshezhiurl(){
		window.open(this.tzurl);
	},
	showxinyongpingfen(){
		showDialog({
		  message: this.$t('newadd.text64')+'：'+this.xinyongpingfen,
		}).then(() => {
		  // on close
		});
	},
	toyanzheng(){
		this.$router.push({
		    path: "/verification"
		});
	},
    towallte() {
      this.$router.push({
        path: "/wallet",
      });
    },
    toNotifications() {
      this.$router.push({
        path: "/notifiCations",
      });
    },
    toInvite() {
      this.$router.push({
        path: "/invite",
      });
    },
    toLegality() {
      this.$router.push({
        path: "/legality",
      });
    },
    toFaq() {
      this.$router.push({
        path: "/faq",
      });
    },
    toLanguage() {
      this.$router.push({
        path: "/language",
      });
    },
    google(){
        this.$router.push({
        path: "/google",
      });
    },
    loginout() {
      showConfirmDialog({
        title: "",
        message: this.$t("newtext3"),
        confirmButtonText: this.$t("Confirm"),
        cancelButtonText: this.$t("newtext2"),
        confirmButtonColor: '#c0dd2b'
      })
        .then(() => {
				loginout().then((res) => {
				    //sessionStorage.removeItem("username");
				    localStorage.removeItem('username')
				    localStorage.removeItem('loginpassword')
				  //   this.$router.push({
						// path: "/login",
				  //   });
				  this.$router.push({
				  	path: "/tab1",
				  });
				}).catch((err) => {
				    console.log("err-->", err);
				});
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //主题切换/*0708改*/
    toChangeTheme() {
      let that = this;
      that.setThemeVal = !that.setThemeVal;
      localStorage.setItem("isBlack", that.setThemeVal); //true代表黑色主题，false代表白色主题 | 注意缓存里面为字符串，，非bool型

      //todo 模拟切换主题动画，提升交互效果
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      setTimeout(() => {
        that.isBlack = localStorage.getItem("isBlack");
        //loading.close()
        location.reload();
      }, 1500);

      // showNotify({ type: 'success', message: 'success' });
    },
    setTheme() {
      this.isBlack = "true";
      if (localStorage.getItem("isBlack")) {
        this.isBlack = localStorage.getItem("isBlack"); //true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
      }
      if (this.isBlack == "true") {
        this.setThemeVal = true;
      } else {
        this.setThemeVal = false;
      }
    },
    toAuthenticator() {
      this.$router.push({
        path: "/authenticator",
      });
    },
	tocontractOrder(){
		this.$router.push({
		  path: "/contractOrder",
		});
	},
	toabout(){
		this.$router.push({
		  path: "/about",
		});
	},
	tooptionOrder(){
		this.$router.push({
		  path: "/optionOrder",
		});
	},
	topledgehistory(){
		this.$router.push({
		  path: "/pledgehistory",
		});
	},
    toRecord() {
      this.$router.push({
        path: "/record",
      });
    },
    toRecords() {
      this.$router.push({
        path: "/record",
        query: {
          active: 2,
        },
      });
    },
    toService() {
      window.open(
        "https://chat.ichatlink.net/widget/standalone.html?eid=231b4a7f30bee254e60c1be7bd19288c&language=en"
      );
    },
  },
  mounted() {
    this.setTheme(); /*0708改*/
  },
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000000;
  background-size: 100% 100%;
}
.top {
  height: 40px;
  display: flex;
  align-items: center;
}
.back {
  margin-left: 20px;
  width: 10px;
}

.title {
  width: 80%;
  height: 20px;
  font-size: 20px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}

.language_box {
  width: 90%;
  height: 100%;
  margin-left: 5%;
  margin-top: 20px;
  margin-bottom: 300px;
}

.language_box ul {
  width: 100%;
  height: 100%;
}

.language_box ul li {
  width: 90%;
  height: 10.99%;
  border-bottom: 1px solid #ffffff;
  margin-left: 5%;
}

.language_box ul li p {
  display: inline-block;
  font-size: 14px;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  color: #ffffff;
  line-height: 14px;
  margin-top: 30px;
}

.language_box ul li i {
  display: block;
  width: 14px;
  height: 14px;
  background: url("/static/my/success.png") no-repeat;
  background-size: 100% 100%;
  float: right;
  margin-top: 30px;
}
.language_box p {
  color: #ffffff;
  font-size: 16px;
  /* text-align: justify;
		text-indent: 16px; */
  text-align: left;
  line-height: 25px;
  margin-bottom: 15px;
}
button {
  width: 90%;
  height: 50px;
  border-radius: 4px;
  /* border: 1px solid #FFFFFF; */
  margin-top: 10px;
  margin-left: 5%;
}

.button_usdt {
  background: #222222;
  margin-bottom: 50px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
}

.button_usdt p {
  display: inline-block;
  width: 13px;
  height: 13px;
  background: url("/static/my/invite/copy_link.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 60%;
}
.allContentAtTheBottom {
  position: absolute;
  top: 80px;
  width: 91%;
  margin: 0 auto;
  left: 4%;
}

.item {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #333333;
}
.leftContent {
  display: flex;
  flex: 1;
}

.leftContent img {
  width: 24px;
  height: 24px;
}
.fl1 {
  /*! width: 70px; */
  /*! height: 12px; */
  /*! font-family: Manrope, Manrope; */
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
  /*! line-height: 12px; */
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin-left: 12px;
}
.rightSideEntry img {
  width: 12px;
}
.logout {
  width: 335px;
  height: 48px;
  background: #96ea63;
  border-radius: 8px 8px 8px 8px;
  text-align: center;
  line-height: 48px;
  color: #fff;
  /*! width: 59px; */
  /*! height: 16px; */
  /*! font-family: Manrope, Manrope; */
  font-weight: bold;
  font-size: 16px;
  color: #07070a;
  /*! line-height: 16px; */
  /*! text-align: left; */
  font-style: normal;
  text-transform: none;
  margin: 0 auto;
  margin-top: 30px;
}

.myNameIs {
  /*! width: 71px; */
  /*! height: 20px; */
  font-family: eryaxindahei, eryaxindahei;
  font-weight: 400;
  font-size: 20px;
  color: #ffffff;
  /*! line-height: 20px; */
  text-align: center;
  font-style: normal;
  text-transform: none;
  margin: 0 auto;
  margin-top: 20px;
}
.myBackground {
  text-align: center;
  margin-bottom: 20px;
}
.myBackground img {
  width: 80px;
}
.homePage {
  background-color: #000;
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  color: #ffffff;
}

.top_header{
    position: fixed;
    top: 0;
    left: 0;
    height: 29px;
    width: 100%;
    background: #000;
    padding: 16px 0;
    z-index: 10;
  }
  .header{
    padding: 0 20px;
    height: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
  }
  .header_left{
    display: flex;
    align-items: baseline;
  }
  .logoimg{
    /* width: 24px; */
    height: 24px;
    margin-right: 6px;
  }
  .leftname{
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
  }
  .header_right{
    //display: flex;
    align-items: center;
    justify-content: space-between;
    width: 124px;
    height: 42px;
    border-radius: 10px;
    background: #1a1a1a;
    position: relative;
    text-align: center;
    line-height: 42px;

  }
  .header_right_action{
    //display: flex;
    text-align: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    //margin-left: 12px;
    //margin-right: 12px;
  }

  .content{
    width: 90%;
    margin: auto;
    margin-top: 61px;
    padding-bottom: 90px;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
  }
  
  .left-bg1{
    background: #d8d8d8;
    mix-blend-mode: normal;
    opacity: .08;
    transform: rotate(-35deg);
    width: 140px;
    height: 235px;
    position: absolute;
    right: -65px;
    top: -61px;
    //z-index: 99;
  }
  .left-bg2{
    background: #d8d8d8;
    mix-blend-mode: normal;
    opacity: .08;
    transform: rotate(-35deg);
    width:140px;
    height: 235px;
    position: absolute;
    right: -100px;
    top: -61px;
    //z-index: 99;
  }
  .tab4-content1{
    height: 176px;
    background: #1a1a1a;
    border-radius: 20px;
    padding: 16px;
  }
  
  .tab4-content1-title1{
    display: block;
    font-size: 14px;
    font-width: 500;
    img{
      margin-right: 10px;
    }
  }
  .tab4-content1-balance{
    display: flex;
    justify-content: start;
    align-items: baseline;
    font-family: DIN Alternate;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin-top: 10px;
    border-bottom: 1px solid hsla(0, 0%, 100%, .07);
    padding-bottom: 5px;
    span:nth-child(2){
      font-family: Inter;
      font-weight: 600;
      font-size: 14px;
      color: #c2e22a;
      margin-left: 14px;
     }
  }
  .tab4-content1-balance-baifenbi{
	font-family: Inter;
	font-weight: 600;
	font-size: 14px;
	color: #c2e22a;
	margin-left: 14px;  
  }
  .tab4-content1-info-list{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 25px;
  }
  .tab4-content1-info{
    width: 33%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span:nth-child(1){
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      opacity: .3;
    }
    span:nth-child(2){
      font-weight: 700;
      font-size: 16px;
      font-family: DIN Alternate;
      margin-top: 8px;
    }
  }
  
  
  .menus{
    margin-top: 23px;
    display: flex;
    justify-content: flex-start;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff4d;
  }
  .menus-menu {
    padding: 10px 17px;
    border-radius: 19px;
  }
  
  
  .content-info{
    min-height: 369px;
    padding: 25px 20px;
    background: #1a1a1a;
    border-radius: 21px;
    margin-bottom: 16px;
    margin-top: 24px;
    position: relative;
  }
  .content-info-title2{
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 25px;
    }
  .content-info-qr-img {
      background: #fff;
      border-radius: 10px;
      margin: auto;
      margin-top: 24px;
      padding: 10px;
      display: block;
    }
	
	
	
	
  .content-info-input-list {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
	.inputs-max{
				width: 100%;
			}
	.lable-new{
	  font-weight: 500;
	  font-size: 12px;
	  color: hsla(0, 0%, 100%, .3);
	}
	.input-content{
	  padding: 0 6px 0 24px;
	  width: 100%;
	  box-sizing: border-box;
	  background: #d8d8d814;
	  margin: auto;
	  mix-blend-mode: normal;
	  border-radius: 10px;
	  margin-top: 8px;
	  height:56px;
	  line-height: 56px;
	  display: flex;
	  justify-content: space-between;
	  color: #fff;
	  align-items: center;
	  font-weight: 600;
	  /* font-size: 14px; */
	font-size: 12px;
	  position: relative;
	}
	.input-button{
			width: 80px;
			height:44px;
			background: #c0dd2b;
			color: #000;
			border-radius:10px;
			font-weight: 400;
			font-size: 14px;
			line-height: 44px;
			text-align: center;
	}
	.input-content1 {
	  background: #d8d8d84d;
	}
	
    .content-info-confirm-button {
      font-weight: 600;
      font-size: 16px;
      color: #000;
      //width: 100%;
      height: 48px;
      background: #c0dd2b;
      border-radius: 13px;
      text-align: center;
      line-height: 48px;
      margin-top: 24px;
    }
    .content-info-seed{
      margin-top: 24px;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff4d;
    }
	
    .content-info-input-content {
      background: #d8d8d814;
      margin: auto;
      mix-blend-mode: normal;
      border-radius: 10px;
      margin-top: 8px;
      height: 56px;
      width: 104px;
      line-height: 56px;
      padding: 0 16px 0 20px;
      display: flex;
      justify-content: space-between;
      color: #fff;
      align-items: center;
      font-weight: 600;
      /* font-size: 14px; */
  	font-size: 12px;
      position: relative;
    }
	.day-lists-news{
	    z-index: 10;
	    width: 100%;
	    max-height: 168px;
	    overflow-y: auto;
	    position: absolute;
	    top: 62px;
	    border-radius: 10px;
	    background: #292929fc;
	    color: #fff;
	    left: 0;
	  }
	  .day-list-news {
	    height: 56px;
	    width: 104px;
	    padding: 0 16px 0 20px;
	  }
	  .readonly-input{
	    background: none;
	    width: calc(100% - 24px);
	    cursor: text;
	    outline: none;
	    border: none;
	  }
	  /* input{
	    background: none;
	    width: calc(100% - 24px);
	    cursor: text;
	    outline: none;
	    border: none;
	  } */
	
	
	
	
    .content-info-content-info-list{
      
    }
	.list-title-info-list {
	   color: #fff;
	   font-family: Inter;
	   font-size: 14px;
	   font-style: normal;
	   font-weight: 500;
	   line-height: normal;
	   margin-bottom: 8px;
	 }
	.list-content-info-list  {
	   color: #fff;
	   font-family: Inter;
	   font-size: 14px;
	   font-style: normal;
	   font-weight: 400;
	   line-height: normal;
	   opacity: .3;
	   margin-bottom: 16px;
	 }
  
  
  
  
  .content-info:before {
    content: "";
    display: inline-block;
    background: #c2e22a;
    border-radius: .05rem;
    width: 5px;
    height: 24px;
    top: 25px;
    left: 0;
    position: absolute;
  }
  .seeting-main {
    margin: auto;
    margin-top: 20px;
  }
  .seeting-list{
    height: 54px;
    line-height: 54px;
    font-weight: 500;
    font-size: 16px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    background: #1a1a1a;
    border-radius: 20px;
  }
  .seeting-list:before {
    content: "";
    display: inline-block;
    width: 3px;
    height: 22px;
    background: #c0dd2b;
    position: absolute;
    left: 25px;
    top: 16px;
    border-radius: 3px;
  }
  .lable {
    margin-left: 15px;
  }
  .left-icon{
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  
.menus-menu-active {
  background: #1a1a1a;
  color: #fff;
}
.day-lists-new {
	    z-index: 10;
	    width: calc(100% - 24px);
	    height: 84px;
	    overflow-y: auto;
	    position: absolute;
	    top: 50px;
	    border-radius: 10px;
	    background: #292929fc;
	    color: #ffffff4d;
	    left: 0;
	    font-weight: 500;
	    font-size: 14px;
	    padding: 8px 12px;
	}
	.day-list-new {
	    height: 41px;
	    line-height: 41px;
	}
	.day-list1-new {
	    color: #fff;
	}
</style>