export default {
	account: {
		Start_making_money_plan: "Mula membuat rancangan wang",
		Market: "Pasaran",
		Migital_currency: "Mata wang migital",
		Foreign_exchange: "Pertukaran asing",
		Noble_metal: "Logam mulia",
		Send_Cryop_Now: "Hantar Cryop Sekarang",
		Send: "Hantar",
		Receive: "terima",
		Buy: "Beli",
		Currency_account: "Akaun mata wang",
		Contract_account: "Akaun kontrak",
		Transfer: "Pemindahan",
		Select_a_wallet: "Pilih dompet",
		Select_Coin: "Pilih Syiling",
		Confirm: "sahkan",
		Wallet: "sahkan",
		Availavle: "Tersedia",
		Frozen: "beku",
		Convert: "Tukar",
		Receiving_Address: "Alamat Penerimaan",
		Amount: "Jumlah",
		Max: "Maks",
		Send_Now: "Hantar sekarang",
		Please_check_if: "Sila semak sama ada alamat penerimaan anda adalah betul sebelum menghantar, supaya tidak menyebabkan kehilangan aset Yuran pengeluaran adalah",
		new1: 'mata wang kripto',
		new2: 'indeks',
		new3: 'Pukal',
		new4: 'Forex',
		new5: 'jumlah aset',
	},
	"964128-0": "Hari",
	"Confirm": "Sahkan",
	"Cancel": "Batal",
	"964128-1": "Oh!",
	"964128-2": "Anda tiada rekod transaksi. Senarai kosong.",
	"652934-0": "Perdagangan Kuantitatif AI",
	"652934-1": "Jumlah Kuantitatif AI",
	"652934-2": "Keuntungan Hari Ini",
	"652934-3": "Keuntungan Keseluruhan",
	"652934-4": "Keselamatan Dana",
	"652934-5": "Keuntungan Stabil",
	"652934-6": "Operasi Mudah",
	"652934-7": "Lihat Lagi",
	"652934-8": "Cipta Perdagangan Kuantitatif AI",
	"652934-9": "Tempoh",
	"652934-10": "Jumlah",
	"652934-11": "Kadar Pulangan",
	"652934-12": "Kuota",
	"652934-13": "Sahkan",
	"652934-14": "Maklumat Atas",
	"652934-15": "Mod Tema",
	"652934-16": "Ujian---",
	"314962-0": "Google 2FA",
	"314962-1": "Muat turun Google Authenticator untuk mengikat",
	"314962-2": "Salin kod pengesahan",
	"314962-3": "Masukkan kod pengesahan",
	"314962-4": "Permintaan gagal:",
	"314962-5": "Kod pengesahan telah disalin",
	"314962-6": "Tidak dapat menyalin teks:",
	"314962-7": "Salinan gagal, sila cuba lagi",
	"199917-0": "Pengaturan",
	"199917-1": "Pemberitahuan",
	"199917-2": "Google 2FA",
	"199917-3": "Emel",
	"199917-4": "Bahasa",
	"199917-5": "Versi",
	"199917-6": "Keluar",
	home: {
		USDTAccount: 'USDT Account',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: 'Open',
		ETHTodayProfit: 'ETH Today\'s Profit',
		totalRevenue: 'Total Revenue',
		portfolio: 'Portfolio',
		reliableSecurity: 'Reliable Security Guarantee',
		stableReliable: 'Stable and Reliable Investment',
		convenienEasy: 'Convenien and Easy Operation',
		pleaseLookForward: 'Please look forward to...',
		rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
	},
	"ConnectW": "Connect Wallet",
	"dappbrowser": "This website only accepts access from decentralized wallet DAPP browsers.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "Earn Interest",
	"$": "$",
	"ETH Today's Profit": "ETH Today's Profit",
	"Wallet": "Wallet",
	"Record": "Record",
	"Support": "Support",
	"General": "General",
	"Notifications": "Notifications",
	"Invite Friends": "Invite Friends",
	"FAQ": "FAQ",
	"Privacy & Security": "Privacy & Security",
	"Legality": "Legality",
	"Authenticator": "Authenticator",
	"Settings": "Settings",
	"Change Language": "Change Language",
	"Daytime mode": "Daytime mode",
	"Black mode": "Black mode",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

	// marketInfo.vue
	'Open': 'Open',
	'High': 'High',
	'Low': 'Low',
	'Close': 'Close',
	'Up': 'Up',
	'Down': 'Down',
	'Time': 'Time',
	'TransactionFee': 'Transaction Fee',
	'AvailableBalance': 'Available Balance',
	'submit': 'Subscribe',
	'Direction': 'Direction',
	'Profit': 'Profit',
	'Quantity': 'Quantity',
	'Price': 'Price',
	'different': 'Click on different ratios to display the lowest limit',
	'lessthan': 'At least cannot be less than',
	'least': 'least：',
	'Transactionhistory': 'Transaction history',
	'Transactionrecords': 'Transaction records',
	'Number': 'Number',
	'Profitloss': 'Profit loss',
	'Ror': 'Ror',
	'operate': 'operate',





	//钱包
	TransactiónDetails: 'Transactión Details',
	dealdetails: 'deal details',
	ViewTransactionHistory: 'View Transaction History',
	Deposit: 'Deposit',
	Rechargeaddress: 'Recharge address：',
	DepositAmount: 'Deposit Amount：',
	EnterDepositAmount: 'Enter Deposit Amount',
	EnterHash: 'Enter Hash',
	Hash: 'Hash：',
	Voucherimage: 'Voucher image：',
	Continue: 'Continue',
	Withdraw: 'Withdraw',
	Withdrawal: 'Withdrawal：',
	EnterAmounttoWithdraw: 'Enter Amount to Withdraw',
	Available: "Available：",
	ReceiveAddress: 'Receive Address：',
	EnterWalletAddress: 'Enter Wallet Address',
	WithdrawalDesc: 'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
	Exchange: 'Exchange',
	Send: 'Send:',
	Max: 'Max:',
	rechargeAmount: 'Please input a recharge amount',
	hashcode: 'hash code and screenshot cannot be empty at the same time',
	correcthashcode: 'please input a correct hash code',
	maximumwithdrawalamount: 'The maximum withdrawal amount is',
	Incorrectamountformat: 'Incorrect amount format',
	copy: 'copy',


	//tab2
	"Total Profit": "Total Profit",
	"Today's Profit": "Today's Profit",
	"What is Automated Trading?": "What is Automated Trading?",
	"Learn about earning": "Learn about earning",
	"Staking Period": "Staking Period",
	"Staking Amount": "Staking Amount",
	"Yield": "Yield",
	"Limit": "Limit",
	"Subscribe": "Subscribe",
	"Record": "Record",

	//tab3
	"Proof of Stake": "Proof of Stake",
	"ETH Today is Price": "ETH Today is Price",
	"ETH Today's Increase": "ETH Today's Increase",
	"Joint Staking": "Joint Staking",
	"Individual Staking": "Individual Staking",
	"tab3long1": "Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2": "Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1": "Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2": "By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3": "Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4": "Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction": "Introduction",
	"webviewlong5": "Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)": "Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6": 'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process": "The Staking Process",
	"webviewlong7": "To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks": "Staking Rewards and Risks",
	"webviewlong8": "By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking": "Joint Staking and Individual Staking",
	"webviewlong9": "For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion": "Conclusion",
	"webviewlong10": "Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total": "Total",

	//record
	"Account": "Account",
	"interest-bearing": "interest-bearing",
	"POS Staking": "POS Staking",
	"convert": "convert",
	"transaction": "transaction",
	"pledge": "pledge",
	"recordno": "You don't have any news yet. The list is empty.",
	"Oops": "Oops",
	"recharge": "recharge",
	"payment": "payment",
	"staking": "staking",
	"income": "income",
	"Pledge quantity": "Pledge quantity",
	"cycle": "cycle",
	"Days Remaining": "Days Remaining",
	"cumulative gain": "cumulative gain",
	"Price increase": "Price increase",
	"Price decrease": "Price decrease",
	"Opening price": "Opening price",
	"Closing price": "Closing price",
	"loss": "loss",
	"pfofit": "pfofit",
	"amount": "amount",

	//notifiCations
	"notifiCationslong1": "Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2": "The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3": "Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4": "Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5": "VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6": "VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7": "VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8": "VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9": "VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10": "VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11": "VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12": "VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13": "VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn": "Refer and Earn",
	"Copy Link": "Copy Link",

	//faq
	"Frequently Asked Questions": "Frequently Asked Questions",

	//authenticator
	"mailbox": "mailbox",
	"Front photo": "Front photo",
	"Back photo": "Back photo",
	"Handheld ID photo": "Handheld ID photo",
	"define": "define",

	//legality
	"legalitylong1": "Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2": "Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3": "1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4": "2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5": "3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6": "Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7": 'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language": "language",
	"Confirm": "Confirm",

	"newtext1": "Sign out",
	"newtext2": "Cancel",
	"newtext3": "Are you sure you want to log out of this account?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "Login",
	"newtext9": "username",
	"newtext10": "Mobile phone number",
	"newtext11": "Please enter user name",
	"newtext12": "Please enter your mobile phone number",
	"newtext13": "password",
	"newtext14": "Please enter password",
	"newtext15": "No account yet",
	"newtext16": "Register",
	"newtext17": "Country",
	"newtext18": "Select a country",
	"newtext19": "Mobile phone number verification code",
	"newtext20": "Please enter the verification code",
	"newtext21": "Email address",
	"newtext22": "Please enter your email address",
	"newtext23": "Confirm password",
	"newtext24": "Please enter the confirmation password",
	"newtext25": "Have an account",
	"newtext26": "Send verification code",
	"newtext27": "Mobile phone number is incorrect",
	"newtext28": "Sent successfully",
	"newtext29": "Registration successful",

	"newtext30": 'currency',
	"newtext31": 'Forex',
	"newtext32": 'precious metals',

	"newtext33": 'Akaun',
	"newtext34": 'Aktiviti platform',
	"newtext35": 'Perkhidmatan Pelanggan',
	"newtext36": 'jemputan',
	"newtext37": 'Pengesahan KYC',
	"newtext38": 'setting',
	wallet_p: {
		text1: "Salin Alamat",
		text2: 'Sahkan',
		text3: 'Tukar dari',
		text4: "Tukar kepada",
		text5: "MAX",
		text6: "Jemput",
		text7: 'Dapatkan ganjaran rujukan',
		text8: "Apabila rakan anda mengambil bahagian dalam AI arbitrage dan sewa mesin penambangan, anda boleh dapatkan ganjaran rujukan",
		text9: "salin",
		text10: 'Kongsi Pautan',
		text11: "Pengesahan KYC",
		text12: "Negara",
		text13: "Sila pilih negara",
		text14: "Nama pertama",
		text15: "Sila masukkan nama pertama anda",
		text16: "Nama keluarga",
		text17: "Sila masukkan nama keluarga anda",
		text18: "Jenis Sijil",
		text19: "Sila pilih Jenis Sijil",
		text20: "Nombor sijil",
		text21: "Sila masukkan nombor sijil",
		text22: "Sila muat naik gambar bahagian depan kad pengenalan anda",
		text23: "Sila muat naik gambar bahagian belakang kad pengenalan anda",
		text24: "Sila muat naik gambar bahagian depan pasport anda",
		text25: "Sila muat naik gambar bahagian belakang pasport anda",
		text26: "Emel",
		text27: "Sila masukkan emel anda",
		text28: "hantar",
		text29: "Kod pengesahan",
		text30: "Sila masukkan kod pengesahan anda",
		text31: "ID",
		text32: "pasport",
		text33: "batalkan",
		text34: "Negara/Wilayah",
		text35: "Sila masukkan maklumat yang betul untuk kemukakan untuk pengesahan",
		text36: "Berjaya dihantar!",
		text37: "Ralat format emel!",
		text38: "lesen memandu",
		text39: "Sila muat naik gambar hadapan lesen memandu anda",
		text40: "Sila muat naik foto bahagian belakang lesen memandu anda",

		text41: "Nombor ID",
		text42: "Sila masukkan nombor ID anda",
		text43: "Nombor pasport",
		text44: "Sila masukkan nombor pasport",
		text45: "nombor lesen memandu",
		text46: "Sila masukkan nombor lesen memandu anda",
	},
	jiaoyi: {
		text1: 'Kontrak Kedua',
		text2: 'Kontrak Kekal',
		text3: 'Transaksi spot',
		text4: 'Delegasikan serta-merta',
		text5: 'Jenis akaun',
		text6: 'masa penghantaran',
		text7: 'Julat harga',
		text8: 'Harga belian',
		text9: 'kos',
		text10: 'Baki',
		text11: 'sekurang-kurangnya',
		text12: 'dijangka',
		text13: 'Jumlah pembelian',
		text14: 'arah',
		text15: 'Harga belian',
		text16: 'Harga masa nyata',
		text17: 'Countdown',
		text18: 'Kontrak Saya',
		text19: 'Keuntungan',
		text20: 'Kontrak',
		text21: 'harga penghantaran',
		text22: 'masa penghantaran',
		text23: 'Harga pasaran',
		text24: 'bangkit',
		text25: 'jatuh',
		text26: 'Harga belian',
		text27: 'maksimum',
		text28: 'Keuntungan',
		text29: 'yuran pengendalian',
		text30: 'Beli',
		text31: 'Jual',
		text32: 'Kuantiti pembelian',
		text33: 'akaun sebenar',
		text34: 'Akaun Maya',
		text35: 'Tiada data lagi',
		text36: 'Kedudukan semasa',
		text37: 'Suruhanjaya sejarah',
		text38: 'Tutup kedudukan',
		text39: 'Margin',
		text40: 'Untung dan rugi',
		text41: 'tahan',
		text42: 'Rekod pembelian',
		text43: 'Rekod jualan',
		text44: 'Tersedia',
		text45: 'ditukar',
		text46: 'Otentikasi berhasil',
		text47: 'Masa pembelian',
		text48: 'belum dibaca',
		text49: 'Baca',
		text50: 'Pesanan hos',
		text51: 'Jumlah timbang tara',
		text58: 'Pendapatan hari ini',
		text52: 'Cara robot kecerdasan buatan berfungsi',
		text53: 'produk arbitraj',
		text54: 'hari',
		text55: 'Jumlah',
		text56: 'Pendapatan harian',
		text57: 'Jenis mata wang',
		text59: 'Sertai arbitraj AI',
		text60: 'Risiko sifar, pulangan cepat',
		text61: 'Arbitraj AI',
		text62: 'Pendapatan yang dijangkakan',
		text63: 'Jenis syiling arbitraj',
		text64: 'Jumlah escrow',
		text65: 'Hos sekarang',
		text66: 'Pendapatan harian dihantar ke dompet USDT anda',
		text67: 'Risiko Sifar Dana Pelaburan',
		text68: 'Anda boleh mengeluarkan dana anda pada bila-bila masa',
		text69: 'Kecerdasan buatan berfungsi 24 jam sehari',
		text70: 'Pendapatan terkumpul',
		text71: 'Masa tamat tempoh',
		text72: 'semua',
		text73: 'Sedang berjalan',
		text74: 'Lengkap',
		text75: 'Butiran pengehosan',
		text76: 'Pilih mata wang',
		text77: 'Sokongan teknikal',
		text78: 'Hantar e-mel',
	},
	tabbar:{
		text1:'Halaman utama',
		text2:'Pasaran',
		text3:'Kuantiti Ai',
		text4:'Pinjaman',
		text5:'Akaun',
		text6:'Baki akaun',
		text7:'Pendapatan hari ini',
		text8:'Kadar pulangan',
		text9: 'lebih',
		text10: 'Akan datang',
		text11:'Pra-jualan syiling baharu',
		text12:'Mulakan perjalanan kekayaan digital',
		text13:'Beli sekarang',
		text14: 'Mudah untuk dikendalikan',
		text15:'AI perdagangan kuantitatif',
		text16:'Selamat / stabil / mudah',
		text17:'Buat transaksi',
		text18:'Jumlah komisen',
		text19:'Kongsi pautan ini dengan rakan anda dan dapatkan sehingga $100. ',
		text20:'Pautan disyorkan',
		text21:'Kongsi dengan rakan anda melalui perisian sosial',
	},
	newadd:{
		text1: 'kejayaan',
		text2:'Kegagalan',
		text3:'To-do',
	}
}
