import axios from 'axios'
import { router } from "@/router"
var http=axios.create({
    baseURL:'api',//解决跨域问题
    timeout: 50000
})
//在请求和响应在被catch和then处理之前处理他们
//拦截请求，在请求之前拦截，一般可以在请求头中加上token
http.interceptors.request.use((config)=>{
    //config就是很多参数被封装在了一个对象里，
    //里面的参数也是属性,最常见的比如说url(必要)/method（默认get）/params
    // `headers` 是即将被发送的自定义请求头，在请求头中加上token
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    return config//参数已经修改好
}, err => {//响应出错执行，比如说网断了
    console.log(err);
})
//响应拦截器
http.interceptors.response.use(response=>{
    if (response.status === 200) {
		if(response.data.info=='Please log in first！'){
			//sessionStorage.clear();
			//sessionStorage.removeItem('username')
			//localStorage.clear();
			//router.push('empty_wallet')
			//router.push('login')
			localStorage.removeItem('username')
			localStorage.removeItem('loginpassword')
			router.push('tab1')
		}
		if(response.data.info=='请先登录！'){
			//sessionStorage.clear();
			//sessionStorage.removeItem('username')
			//localStorage.clear();
			//router.push('empty_wallet')
			//router.push('login')
			localStorage.removeItem('username')
			localStorage.removeItem('loginpassword')
			router.push('tab1')
		}
		if(response.data.info=='請先登錄！'){
			//sessionStorage.clear();
			//sessionStorage.removeItem('username')
			//localStorage.clear();
			//router.push('empty_wallet')
			//router.push('login')
			localStorage.removeItem('username')
			localStorage.removeItem('loginpassword')
			router.push('tab1')
		}
        if (response.data.state === 401) {
            //如果是token过期，跳转至登录
            alert("登录已过期，请重新登录！");
        } else if (response.data.state === 0) {
            return response;
        } else {
            return response;
        }
    }
},err=>{
    return Promise.reject(err)
})
 
 
export default http