<template>
  <van-tabbar  :class="isBlack != 'true'?'tabbar-white':'tabbar'" v-model="active" route>
    <van-tabbar-item replace to="/tab1">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_home1.5eb73990.svg" />
        <img class="image" v-else src="~assets/home/icon_home.efc1304f.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text1')}}</div>
		<div v-else class="name">{{$t('tabbar.text1')}}</div>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
		  <img class="image" v-if="props.active" src="~assets/home/icon_home1.5eb73990.svg" />
		  <img class="image" v-else src="~assets/home/icon_home.efc1304f.svg">
		  <div v-if="props.active" class="name name-active">{{$t('tabbar.text1')}}</div>
		  <div v-else class="name">{{$t('tabbar.text1')}}</div>
	  </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/market">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_market1.5fc008d1.svg" />
        <img class="image" v-else src="~assets/home/icon_market.1027b756.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text2')}}</div>
		<div v-else class="name">{{$t('tabbar.text2')}}</div>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
		  <img class="image" v-if="props.active" src="~assets/home/icon_market1.5fc008d1.svg" />
		  <img class="image" v-else src="~assets/home/icon_market.1027b756.svg">
		  <div v-if="props.active" class="name name-active">{{$t('tabbar.text2')}}</div>
		  <div v-else class="name">{{$t('tabbar.text2')}}</div>
	  </template>
    </van-tabbar-item>
    <van-tabbar-item to="/tab2">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_strategy1.c2229412.svg" />
        <img class="image" v-else src="~assets/home/icon_strategy.de961571.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text3')}}</div>
		<div v-else class="name">{{$t('tabbar.text3')}}</div>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
	  	<img class="image" v-if="props.active" src="~assets/home/icon_strategy1.c2229412.svg" />
	  	<img class="image" v-else src="~assets/home/icon_strategy.de961571.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text3')}}</div>
		<div v-else class="name">{{$t('tabbar.text3')}}</div>
	  </template>
    </van-tabbar-item>
    <van-tabbar-item  to="/tab3">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/loan.10275181.svg" />
        <img class="image" v-else src="~assets/home/loan1.ef5dbbe6.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text4')}}</div>
		<div v-else class="name">{{$t('tabbar.text4')}}</div>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
	  	<img class="image" v-if="props.active" src="~assets/home/loan.10275181.svg" />
	  	<img class="image" v-else src="~assets/home/loan1.ef5dbbe6.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text4')}}</div>
		<div v-else class="name">{{$t('tabbar.text4')}}</div>
	  </template>
    </van-tabbar-item>
    <van-tabbar-item to="/tab4">
      <template v-if="isBlack == 'true'" #icon="props">
        <img class="image" v-if="props.active" src="~assets/home/icon_account1.28bc5249.svg" />
        <img class="image" v-else src="~assets/home/icon_account.191ff45f.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text5')}}</div>
		<div v-else class="name">{{$t('tabbar.text5')}}</div>
      </template>
	  <template v-else-if="isBlack == 'false'" #icon="props">
	  	<img class="image" v-if="props.active" src="~assets/home/icon_account1.28bc5249.svg" />
	  	<img class="image" v-else src="~assets/home/icon_account.191ff45f.svg">
		<div v-if="props.active" class="name name-active">{{$t('tabbar.text5')}}</div>
		<div v-else class="name">{{$t('tabbar.text5')}}</div>
	  </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script setup>
import {ref} from 'vue'

const active = ref(0);
const isBlack = localStorage.getItem('isBlack') ? localStorage.getItem('isBlack') : 'true'
//console.log(localStorage.getItem('isBlack'))
</script>

<style scoped lang="scss">
.tabbar{
  background: #1a1a1a;
  height: 75px;
  
  .image{
    width: 17px;
    height: 17px;
	margin: 0 auto;
  }
  .van-tabbar-item--active{
    background-color: #1a1a1a;
  }
}

.tabbar-white{
	background: #ffffff;
}
.name {
    font-weight: 500;
    font-size: 12px;
	color: #ffffff;
	margin-top: 5px;
}
.name-active {
    color: #c0dd2b !important;
}
</style>
