export default {
	account: {
		Start_making_money_plan: "Empieza a hacer un plan de dinero",
		Market: "Mercado",
		Migital_currency: "Moneda digital",
		Foreign_exchange: "Divisas",
		Noble_metal: "metal noble",
		Send_Cryop_Now: "Envía Cryop ahora",
		Send: "Enviar",
		Receive: "Recibir",
		Buy: "Comprar",
		Currency_account: "Cuenta corriente",
		Contract_account: "Cuenta de contrato",
		Transfer: "Transferir",
		Select_a_wallet: "Seleccione una billetera",
		Select_Coin: "Seleccionar moneda",
		Confirm: "Confirmar",
		Wallet: "Billetera",
		Availavle: "Disponible",
		Frozen: "Congelado",
		Convert: "Convertir",
		Receiving_Address: "Dirección de recepción",
		Amount: "Cantidad",
		Max: "Máximo",
		Send_Now: "Enviar ahora",
		Please_check_if: "Por favor, verifique si su dirección de recepción es correcta antes de enviar, para no causar pérdida de activos. La tarifa de retiro es",
		new1: 'criptomoneda',
		new2: 'índice',
		new3: 'A granel',
		new4: 'Forex',
		new5: 'saldo total',
	},
	"964128-0": "Día",
	"Confirm": "Confirmar",
	"Cancel": "Cancelar",
	"964128-1": "¡Ay!",
	"964128-2": "No tienes registros de transacciones. La lista está vacía.",
	"652934-0": "Comercio Cuantitativo AI",
	"652934-1": "Cantidad Cuantitativa AI",
	"652934-2": "Beneficio de hoy",
	"652934-3": "Beneficio total",
	"652934-4": "Seguridad de fondos",
	"652934-5": "Rentabilidad estable",
	"652934-6": "Operación sencilla",
	"652934-7": "Mostrar más",
	"652934-8": "Crear cuantitativa AI",
	"652934-9": "Plazo",
	"652934-10": "Cantidad",
	"652934-11": "Tasa de retorno",
	"652934-12": "Cupo",
	"652934-13": "Confirmar",
	"652934-14": "Información superior",
	"652934-15": "Modo de tema",
	"652934-16": "Prueba---",
	"314962-0": "Google 2FA",
	"314962-1": "Descarga Google Authenticator para vincular",
	"314962-2": "Copiar código de verificación",
	"314962-3": "Introduce el código de verificación",
	"314962-4": "Solicitud fallida:",
	"314962-5": "Código de verificación copiado",
	"314962-6": "No se puede copiar el texto:",
	"314962-7": "Copia fallida, por favor inténtalo de nuevo",
	"199917-0": "Configuración",
	"199917-1": "Notificaciones",
	"199917-2": "Google 2FA",
	"199917-3": "Correo electrónico",
	"199917-4": "Idioma",
	"199917-5": "Versión",
	"199917-6": "Cerrar sesión",
	home: {
		USDTAccount: 'USDT Account',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: 'Open',
		ETHTodayProfit: 'ETH Today\'s Profit',
		totalRevenue: 'Total Revenue',
		portfolio: 'Portfolio',
		reliableSecurity: 'Reliable Security Guarantee',
		stableReliable: 'Stable and Reliable Investment',
		convenienEasy: 'Convenien and Easy Operation',
		pleaseLookForward: 'Please look forward to...',
		rateOfReturn: 'Rate of Return',
		walletnot: 'Please install MetaMask or any Ethereum Extension Wallet',
		wallettip: 'Please bind your address in order not to affect the experience',
	},
	"ConnectW": "Connect Wallet",
	"dappbrowser": "This website only accepts access from decentralized wallet DAPP browsers.",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "Earn Interest",
	"$": "$",
	"ETH Today's Profit": "ETH Today's Profit",
	"Wallet": "Wallet",
	"Record": "Record",
	"Support": "Support",
	"General": "General",
	"Notifications": "Notifications",
	"Invite Friends": "Invite Friends",
	"FAQ": "FAQ",
	"Privacy & Security": "Privacy & Security",
	"Legality": "Legality",
	"Authenticator": "Authenticator",
	"Settings": "Settings",
	"Change Language": "Change Language",
	"Daytime mode": "Daytime mode",
	"Black mode": "Black mode",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'Open',
    'High':'High',
    'Low':'Low',
    'Close':'Close',
    'Up':'Up',
    'Down':'Down',
    'Time':'Time',
    'TransactionFee':'Transaction Fee',
    'AvailableBalance':'Available Balance',
    'submit':'Subscribe',
    'Direction':'Direction',
    'Profit':'Profit',
    'Quantity':'Quantity',
    'Price':'Price',
    'different':'Click on different ratios to display the lowest limit',
    'lessthan':'At least cannot be less than',
    'least':'least：',
    'Transactionhistory':'Transaction history',
    'Transactionrecords':'Transaction records',
    'Number':'Number',
    'Profitloss':'Profit loss',
	'Ror':'Ror',
	'operate':'operate',
	'About_us': 'Acerca de nosotros',





	//钱包
	TransactiónDetails: 'Transactión Details',
	dealdetails: 'deal details',
	ViewTransactionHistory: 'View Transaction History',
	Deposit: 'Deposit',
	Rechargeaddress: 'Recharge address：',
	DepositAmount: 'Deposit Amount：',
	EnterDepositAmount: 'Enter Deposit Amount',
	EnterHash: 'Enter Hash',
	Hash: 'Hash：',
	Voucherimage: 'Voucher image：',
	Continue: 'Continue',
	Withdraw: 'Withdraw',
	Withdrawal: 'Withdrawal：',
	EnterAmounttoWithdraw: 'Enter Amount to Withdraw',
	Available: "Available：",
	ReceiveAddress: 'Receive Address：',
	EnterWalletAddress: 'Enter Wallet Address',
	WithdrawalDesc: 'Withdrawal in English requires a 1% handling fee, which needs to be confirmed by the network nodebefore it can be credited Please do not transfer cryptocurrency to strangers',
	Exchange: 'Exchange',
	Send: 'Send:',
	Max: 'Max:',
	rechargeAmount: 'Please input a recharge amount',
	hashcode: 'hash code and screenshot cannot be empty at the same time',
	correcthashcode: 'please input a correct hash code',
	maximumwithdrawalamount: 'The maximum withdrawal amount is',
	Incorrectamountformat: 'Incorrect amount format',
	copy: 'copy',


	//tab2
	"Total Profit": "Total Profit",
	"Today's Profit": "Today's Profit",
	"What is Automated Trading?": "What is Automated Trading?",
	"Learn about earning": "Learn about earning",
	"Staking Period": "Staking Period",
	"Staking Amount": "Staking Amount",
	"Yield": "Yield",
	"Limit": "Limit",
	"Subscribe": "Subscribe",
	"Record": "Record",

	//tab3
	"Proof of Stake": "Proof of Stake",
	"ETH Today is Price": "ETH Today is Price",
	"ETH Today's Increase": "ETH Today's Increase",
	"Joint Staking": "Joint Staking",
	"Individual Staking": "Individual Staking",
	"tab3long1": "Joint staking is achieved by matching participants through smart contracts to reach a total staking amount of 32 ETH.",
	"tab3long2": "Independent staking does not require matching participants through smart contracts. By reaching a staking amount of 32 ETH, one can complete individual staking",


	//web_view
	"webviewlong1": "Cryptocurrency airdrops help emerging blockchain-based projects grow. Their roots connect community members with rewards and common goals. The outcome of a successful airdrop is a stronger community, better projects, and rewards for end users. Over the years, we have seen many airdrops delivered on proof-of-work (POW) blockchains. The proof-of-stake (POS) Ethereum chain now supports hundreds of project cryptocurrency airdrops across dozens of networks. With PoS becoming the dominant consensus paradigm, the number of airdrops on the Ethereum network shows no signs of slowing down.",
	"webviewlong2": "By staking your cryptocurrencies, you can be eligible to unlock valuable airdrop rewards based on the amount of cryptocurrencies you have staked.",
	"webviewlong3": "Reward equity is only available to stake owners, it comes from hundreds of different project airdrops, and its yield is much higher than any one independent airdrop.",
	"webviewlong4": "Airdrop rewards are often difficult for cryptocurrency owners depositing on exchanges, and some custodians do not support airdrops at all. This is why it is important to manage your own cryptocurrencies. Ethereum is a non-custodial entity that can be staked across all major networks",
	"Introduction": "Introduction",
	"webviewlong5": "Ethereum, a leading blockchain platform, is transitioning from a Proof of Work (PoW) consensus mechanism to a Proof of Stake (PoS) mechanism through its Ethereum 2.0 upgrade. PoS staking is an essential component of this transition, providing users with an opportunity to support network security and operations while potentially earning rewards. This article provides an overview of Ethereum PoS staking and its benefits.",
	"Ethereum 2.0 and Proof of Stake (PoS)": "Ethereum 2.0 and Proof of Stake (PoS)",
	"webviewlong6": 'Ethereum 2.0 is a major network upgrade aimed at improving scalability, security, and sustainability. One of the key changes is the shift from the energy-intensive PoW consensus mechanism to the more environmentally friendly PoS mechanism. In PoS, validators are chosen to create new blocks and confirm transactions based on the amount of cryptocurrency they hold and are willing to "stake" as collateral.',
	"The Staking Process": "The Staking Process",
	"webviewlong7": "To participate in Ethereum PoS staking, users must stake a minimum of 32 ETH by depositing it into the Ethereum 2.0 deposit contract. Once staked, the ETH is locked for a certain period and serves as collateral to secure the network. Validators are chosen based on the amount of staked ETH and their online activity. They are responsible for proposing and validating new blocks, as well as confirming transactions on the network.",
	"Staking Rewards and Risks": "Staking Rewards and Risks",
	"webviewlong8": "By participating in PoS staking, users can earn rewards in the form of newly minted ETH and transaction fees. The rewards are based on the amount of ETH staked and the overall network activity. However, staking also comes with risks, such as the potential for penalties if a validator behaves maliciously or fails to stay online consistently. Additionally, the staked ETH is locked for a certain period, making it illiquid and subject to price fluctuations.",
	"Joint Staking and Individual Staking": "Joint Staking and Individual Staking",
	"webviewlong9": "For users who do not have the required 32 ETH to stake individually, joint staking offers an alternative. In joint staking, multiple users pool their ETH together to reach the required staking amount. This pooling is typically facilitated through smart contracts or staking services, allowing users to participate in Ethereum PoS staking with smaller amounts of ETH.",
	"Conclusion": "Conclusion",
	"webviewlong10": "Ethereum PoS staking is a significant aspect of the Ethereum 2.0 upgrade, offering users the opportunity to support network security, improve decentralization, and earn rewards. By understanding the staking process, its rewards, and risks, users can make informed decisions about participating in this crucial evolution of the Ethereum network.",


	//mywallet
	"Total": "Total",

	//record
	"Account": "Account",
	"interest-bearing": "interest-bearing",
	"POS Staking": "POS Staking",
	"convert": "convert",
	"transaction": "transaction",
	"pledge": "pledge",
	"recordno": "You don't have any news yet. The list is empty.",
	"Oops": "Oops",
	"recharge": "recharge",
	"payment": "payment",
	"staking": "staking",
	"income": "income",
	"Pledge quantity": "Pledge quantity",
	"cycle": "cycle",
	"Days Remaining": "Days Remaining",
	"cumulative gain": "cumulative gain",
	"Price increase": "Price increase",
	"Price decrease": "Price decrease",
	"Opening price": "Opening price",
	"Closing price": "Closing price",
	"loss": "loss",
	"pfofit": "pfofit",
	"amount": "amount",

	//notifiCations
	"notifiCationslong1": "Note: VIP registration is based on the recharge amount of the corresponding overall account to determine your level. The interval needs to deduct the total recharge amount after the withdrawal of the existing account to determine the membership level.",
	"notifiCationslong2": "The membership duration is also divided according to different levels. To maintain your membership status, you need to recharge at least 5,000 USDT each time within the specified time to enjoy the privileges of membership. After each recharge of the membership status, the cycle is recalculated and can be accumulated.",
	"notifiCationslong3": "Customer service is divided based on membership levels. Official customer service is provided by APP staff and offers unified service to the majority of members. The number of exclusive secretaries is limited, ensuring better service. Exclusive personal secretaries provide users with dedicated reception services and financial advice.",
	"notifiCationslong4": "Contact customer service in advance to make an appointment for recharge. Complete the recharge within the specified time to receive additional rewards for appointment recharge based on the corresponding membership level.",
	"notifiCationslong5": "VIP1: Based on the UID account, accumulate a total recharge of 10,000 USD and receive 177 USDT as a bonus. After reaching the VIP level and obtaining the General Member Medal, the points will be airdropped to the account.",
	"notifiCationslong6": "VIP2: Based on the UID account, cumulative recharge reaches 30,000 USD, and 777 USDT will be given away. The bonus amount will be credited to the account immediately after reaching VIP level and obtain the Bronze Member Medal.",
	"notifiCationslong7": "VIP3: Based on the UID account, accumulate a total of $70,000 in recharge and receive 1777 USDT as a gift. After reaching VIP level and obtaining the Silver Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 1.5% reward on the next booking recharge amount.",
	"notifiCationslong8": "VIP4: Based on the UID account, accumulate a total recharge of $150,000 and receive 2,777 USDT. After reaching VIP level and obtaining the Gold Member Medal, points will be airdropped to the account. Additionally, you will receive an extra 2% reward on the next booking recharge amount.",
	"notifiCationslong9": "VIP5: Based on the UID account, cumulative recharge reaches 300,000 USD, and 4777 USDT will be given as a gift. After reaching VIP level and obtaining the Diamond VIP badge, points will be airdropped to the account. Additionally, the next booking recharge amount will receive an additional 2.5% bonus.",
	"notifiCationslong10": "VIP6: Based on the UID account, cumulative recharge reaches 800,000 USD, and 16,888 USDT will be given as a bonus. The bonus amount will be airdropped to the account immediately after reaching the VIP level and obtaining the Black Diamond membership badge, and an additional 3% of the next pre-registered recharge amount will be obtained. You will have a private exclusive secretary to provide any financial advice or handle tax issues.",
	"notifiCationslong11": "VIP7: Based on the UID account, the cumulative recharge amount reaches 3 million US dollars, and a 15-day luxury trip to Europe (with discounts) will be given. CoinBpos global lifetime honorary VIP will be awarded and the crown member medal will be obtained. Extra 3.5% bonus will be given for the next appointment recharge.",
	"notifiCationslong12": "VIP8: According to the UID account, if the cumulative recharge reaches 8 million US dollars, you can get one CoinBpos Morocco Annual Banquet voucher. Reward: 1 BTC, and obtain CoinBpos Global Lifetime Honor VIP. Next appointment recharge can receive an additional 4% bonus.",
	"notifiCationslong13": "VIP9: Based on the UID account, cumulative deposits reach 30 million US dollars, you can get 3% of CoinBpos shares. And enjoy a 3% annual profit dividend. Free gift of Cronix pure gold commemorative badge.",

	//invite
	"Refer and Earn": "Refer and Earn",
	"Copy Link": "Copy Link",

	//faq
	"Frequently Asked Questions": "Frequently Asked Questions",

	//authenticator
	"mailbox": "mailbox",
	"Front photo": "Front photo",
	"Back photo": "Back photo",
	"Handheld ID photo": "Handheld ID photo",
	"define": "define",

	//legality
	"legalitylong1": "Coinbpos, composed of multiple cryptocurrency companies, together they provide tools to help the encryption community create, grow, maintain community stability, power thousands of decentralized applications, promote the future of cryptocurrency as well as promote the digitization of traditional currencies and to promote global economic prosperity.",
	"legalitylong2": "Each company within Coinbpos acts as a data controller for the processing of personal data related to its services, the data controller for each service is:",
	"legalitylong3": "1. Blockchain Technology - Ethereum Blockchain.",
	"legalitylong4": "2.Decentralized Wallet - Trust Wallet, MetaMask and Crypto Wallet.",
	"legalitylong5": "3. Trading Technology-Coinbase, Crypto, linch and Binance.",
	"legalitylong6": "Privacy Policy details how Coinbpos processes personal data in accordance with its obligations under relevant data protection laws, including The European Union's General Data Protection Regulation (GDPR),",
	"legalitylong7": 'The California Consumer Privacy Act (CCPA) and other General Laws (collectively referred to as "Data Protection Laws*).',

	//language
	"language": "language",
	"Confirm": "Confirm",

	"newtext1": "Sign out",
	"newtext2": "Cancel",
	"newtext3": "Are you sure you want to log out of this account?",

	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "Login",
	"newtext9": "username",
	"newtext10": "Mobile phone number",
	"newtext11": "Please enter user name",
	"newtext12": "Please enter your mobile phone number",
	"newtext13": "password",
	"newtext14": "Please enter password",
	"newtext15": "No account yet",
	"newtext16": "Register",
	"newtext17": "Country",
	"newtext18": "Select a country",
	"newtext19": "Mobile phone number verification code",
	"newtext20": "Please enter the verification code",
	"newtext21": "Email address",
	"newtext22": "Please enter your email address",
	"newtext23": "Confirm password",
	"newtext24": "Please enter the confirmation password",
	"newtext25": "Have an account",
	"newtext26": "Send verification code",
	"newtext27": "Mobile phone number is incorrect",
	"newtext28": "Sent successfully",
	"newtext29": "Registration successful",

	"newtext30": 'currency',
	"newtext31": 'Forex',
	"newtext32": 'precious metals',

	"newtext33": 'Cuenta',
	"newtext34": 'Actividades de la plataforma',
	"newtext35": 'Servicio al Cliente',
	"newtext36": 'invitación',
	"newtext37": 'verificación KYC',
	"newtext38": 'configuración',
	wallet_p: {
		text1: "Copiar dirección",
		text2: 'Confirmar',
		text3: 'Intercambiar desde',
		text4: "Intercambiar a",
		text5: "MAX",
		text6: "Invitar",
		text7: 'Obtener recompensas por referidos',
		text8: "Cuando tus amigos participan en arbitraje de IA y alquiler de máquinas mineras, puedes obtener recompensas por referidos",
		text9: "copiar",
		text10: 'Compartir enlace',
		text11: "Verificación KYC",
		text12: "País",
		text13: "Seleccione un país",
		text14: "Nombre",
		text15: "Ingrese su nombre",
		text16: "Apellido",
		text17: "Ingrese su apellido",
		text18: "Tipo de certificado",
		text19: "Seleccione el tipo de certificado",
		text20: "Número de certificado",
		text21: "Ingrese el número de certificado",
		text22: "Cargue una foto de la parte frontal de su documento de identidad",
		text23: "Cargue una foto de la parte posterior de su documento de identidad",
		text24: "Cargue una foto de la parte frontal de su pasaporte",
		text25: "Cargue una foto de la parte posterior de su pasaporte",
		text26: "Correo electrónico",
		text27: "Ingrese su correo electrónico",
		text28: "enviar",
		text29: "Código de verificación",
		text30: "Ingrese su código de verificación",
		text31: "DNI",
		text32: "pasaporte",
		text33: "cancelar",
		text34: "País/Región",
		text35: "Ingrese la información correcta para enviar la autenticación",
		text36: "¡Enviado correctamente!",
		text37: "¡Error en el formato de correo electrónico!",
		text38: "Licencia de conducir",
		text39: "Sube una foto del frente de tu licencia de conducir.",
		text40: "Sube una foto del reverso de tu licencia de conducir.",

		text41: "número de identificación",
		text42: "Por favor ingrese su número de identificación",
		text43: "Número de pasaporte",
		text44: "Por favor ingrese el número de pasaporte",
		text45: "número de licencia de conducir",
		text46: "Por favor ingrese su número de licencia de conducir",
		
		text47:'Por favor suba una foto suya con su licencia de conducir',
		text48:'Por favor sube una foto tuya sosteniendo tu tarjeta de identificación',
		text49:'Por favor sube una foto tuya con tu pasaporte',
		text50:'Cuenta demo',
	},
	jiaoyi: {
		text1: 'Segundo Contrato',
		text2: 'Contrato perpetuo',
		text3: 'Transacción al contado',
		text4: 'Delegar inmediatamente',
		text5: 'Tipo de cuenta',
		text6: 'tiempo de entrega',
		text7: 'Rango de precios',
		text8: 'Precio de compra',
		text9: 'costo',
		text10: 'Saldo',
		text11: 'al menos',
		text12: 'esperado',
		text13: 'Monto de la compra',
		text14: 'dirección',
		text15: 'Precio de compra',
		text16: 'Precio en tiempo real',
		text17: 'Cuenta regresiva',
		text18: 'Mi contrato',
		text19: 'Beneficio',
		text20: 'Contrato',
		text21: 'precio de entrega',
		text22: 'tiempo de entrega',
		text23: 'Precio de mercado',
		text24: 'en ascenso',
		text25: 'caída',
		text26: 'Precio de compra',
		text27: 'máximo',
		text28: 'Beneficio',
		text29: 'tarifa de gestión',
		text30: 'Comprar',
		text31: 'Vender',
		text32: 'Cantidad de compra',
		text33: 'cuenta real',
		text34: 'Cuenta Virtual',
		text35: 'Aún no hay datos',
		text36: 'Posición actual',
		text37: 'Comisión histórica',
		text38: 'Cerrar posición',
		text39: 'Margen',
		text40: 'Pérdidas y ganancias',
		text41: 'mantener',
		text42: 'Registro de compra',
		text43: 'Venta de récord',
		text44: 'Disponible',
		text45: 'convertido',
		text46: 'Autenticación exitosa',
		text47: 'tiempo de compra',
		text48: 'no leído',
		text49: 'Leer',
		text50: 'Orden de anfitrión',
		text51: 'arbitraje total',
		text58: 'Ganancias de hoy',
		text52: 'Cómo funcionan los robots con inteligencia artificial',
		text53: 'producto de arbitraje',
		text54: 'día',
		text55: 'Cantidad',
		text56: 'Ingreso diario',
		text57: 'Tipo de moneda',
		text59: 'Únete al arbitraje de IA',
		text60: 'Riesgo cero, retornos rápidos',
		text61: 'arbitraje de IA',
		text62: 'Ganancias esperadas',
		text63: 'Tipo de moneda de arbitraje',
		text64: 'Monto del depósito en garantía',
		text65: 'Anfitrión ahora',
		text66: 'Ingreso diario enviado a su billetera USDT',
		text67: 'Fondo de Inversión Riesgo Cero',
		text68: 'Puedes retirar tus fondos en cualquier momento',
		text69: 'La inteligencia artificial funciona las 24 horas del día',
		text70: 'Ingresos acumulados',
		text71: 'Tiempo de vencimiento',
		text72: 'todos',
		text73: 'En progreso',
		text74: 'Completo',
		text75: 'Detalles de alojamiento',
		text76: 'Seleccionar moneda',
		text77: 'Apoyo técnico',
		text78: 'Enviar correo electrónico',
	},
	tabbar:{
		text1:'Página de inicio',
		text2:'Mercado',
		text3:'Cuantificación de Ai',
		text4:'Préstamo',
		text5:'Cuenta',
		text6:'Saldo de la cuenta',
		text7:'Ganancias de hoy',
		text8:'Tasa de retorno',
		text9:'más',
		text10:'Próximamente',
		text11:'Nueva preventa de monedas',
		text12:'Embárcate en un viaje de riqueza digital',
		text13:'Comprar ahora',
		text14:'Fácil de operar',
		text15:'comercio cuantitativo de IA',
		text16:'Seguro / estable / simple',
		text17:'Crear transacción',
		text18:'Comisión total',
		text19:'Comparte este enlace con tus amigos y obtén hasta $100. ',
		text20:'Enlace recomendado',
		text21:'Comparte con tus amigos a través del software social',
	},
	tab4:{
		text1: "saldo de la cuenta",
		text2: "ganancias de hoy",
		text3: "cuantificación de ia",
		text4: "tasa de rendimiento",
		text5: "depósito",
		text6: "transferencia bancaria",
		text7: "retiro",
		text8: "depósito",
		text9: "dirección de la billetera",
		text10: "copiar",
		text11: "cargar comprobante",
		text12: "revise cuidadosamente la dirección del depósito para asegurarse de que es correcta, ya que la transferencia incorrecta puede no ser revocada. El depósito requiere la confirmación de 5 bloques para ingresar a su cuenta. ",
		text13: "transferencia bancaria",
		text14: "antes de iniciar la transferencia bancaria:",
		text15: "antes de iniciar la transferencia bancaria, Póngase en contacto con nuestro equipo de servicio al cliente para obtener información precisa sobre la cuenta de transferencia bancaria. Este paso garantiza la seguridad y la llegada sin problemas de sus fondos. ",
		text16: "tiempo de procesamiento de transferencia bancaria:",
		text17: "antes de iniciar la transferencia bancaria, Póngase en contacto con nuestro equipo de servicio al cliente para obtener información precisa sobre la cuenta de transferencia bancaria. Este paso garantiza la seguridad y la llegada sin problemas de sus fondos. ",
		text18: "asistencia en el proceso de transferencia bancaria:",
		text19: "si tiene algún problema o duda durante la transferencia bancaria, no dude en ponerse en contacto con nuestro equipo de servicio al cliente. Estamos comprometidos a brindar asistencia y apoyo para garantizar una experiencia comercial fluida para usted. ",
		text20: "cantidad",
		text21: "moneda de retiro",
		text22: "confirmado",
		text23: "asegúrese de confirmar su dirección de recepción porque la transferencia incorrecta no se puede cancelar. El retiro de efectivo requiere una tarifa de manejo del 3% y debe registrarse en su cuenta después de obtener 5 confirmaciones de red. ",
		text24: "pedido de opciones",
		text25: "orden de contrato",
		text26: "ai cuantifica los pedidos",
		text27: "registros históricos",
		text28: "invitar a amigos",
		text29: "preguntas y respuestas comunes",
		text30: "contáctanos",
		text31: "sobre nosotros",
		text32: "cambio de idioma",
		text33: "salir del desembarco"
	},
	uploadProof:{
		text1: "cargar comprobante",
		text2: "seleccione la imagen",
		text3: "previsualización",
		text4: "suba una captura de pantalla de su depósito",
		text5: "cantidad",
		text6: "transacción hash",
		text7: "los últimos 6 números",
		text8: "por favor, suba una captura de pantalla de su pago en criptomoneda y espere la revisión después de enviarlo",
		text9: "confirmado"
	},
	newadd:{
		text1:'éxito',
		text2:'Fallo',
		text3:'Tareas pendientes',
		text4:'Mi ficha de corona',
		text5:'Suministro',
		text6:'Vendido',
		text7:'Fin de la preventa',
		text8:'cantidad',
		text9:'Intercambio',
		text10:'Características técnicas',
		text11:'La nueva moneda utiliza tecnología blockchain, que tiene una velocidad de transacción rápida, tarifas de manejo bajas, alta seguridad de transacción, así como funciones de contrato inteligente y tecnología de protección de la privacidad. ',
		text12:'Visión del Proyecto',
		text13:'La visión de Xincoin es convertirse en la plataforma de comercio de activos digitales líder en el mundo, brindando a los usuarios servicios de comercio de activos digitales más seguros, convenientes y eficientes. ',
		text14:'Escenario de aplicación',
		text15: 'La nueva moneda tiene una amplia gama de escenarios de aplicación, incluidas transacciones de moneda digital, transacciones de valores digitales, transacciones de activos digitales, liquidación de pagos, financiación de la cadena de suministro y otros campos. ',
		text16:'Monto del préstamo',
		text17:'¡Verifica tu identidad y obtén más servicios! ',
		text18:'Iniciar verificación',
		text19:'Quiero pedir prestado...',
		text20:'Período de préstamo',
		text21:'día',
		text22:'Tasa de interés diaria',
		text23:'Monto total de intereses',
		text24:'No se pagarán intereses durante 1 día después de la obtención del préstamo, los intereses se pagarán posteriormente. ',
		text25:'pedir prestado ahora',
		text26:'Registro de préstamo',
		text27:'Aún no hay préstamo',
		text28:'No se puede encontrar la información de su préstamo',
		text29:'Excede el rango de límite de préstamo',
		text30:'Aplicando',
		text31:'en progreso',
		text32:'Terminado',
		text33:'Abrir',
		text34:'Cerrar',
		text35:'registro',
		text36:'Depósito',
		text37:'Retiro',
		text38:'¡Ups!',
		text39:'Aún no tienes ningún registro de transacción. La lista está vacía. ',
		text40:'Por favor ingrese el monto',
		text41:'normal',
		text42:'Terminado',
		
		text43: "¡Comencemos iniciando sesión!",
		text44: "buzón",
		text45: "Ingrese su correo electrónico...",
		text46: "contraseña",
		text47: "Ingrese su contraseña...",
		text48: "¿Olvidaste tu contraseña?",
		text49: "Iniciar sesión",
		text50: "¿No tienes una cuenta?",
		text51:"Registrarse",
		text52: "Se ha enviado el código de verificación",
		text53:"verificación",
		text54: "¡Empecemos con el registro!",
		text55: "Confirmar contraseña",
		text56: "Recomendación/Código de promoción",
		text57: "Ingrese el código de promoción/referencia (opcional)",
		text59: "¿Ya tienes una cuenta?",
		text60: "Por favor ingrese su dirección de correo electrónico",
		text61: "Por favor ingrese su contraseña",
		text62: "Por favor ingrese la contraseña de confirmación",
		
		text63:'Autenticación de usuario',
		text64:'puntaje de crédito',
		text65:'Para ser revisado',
		text66:'Aprobado',
		text67:'Gracias por enviar sus documentos KYC. Nuestro equipo revisará su información dentro de las 48 horas. Espere pacientemente los resultados de la revisión.',
		text68:'Terminar transacción',
		text69:'Terminación exitosa',
		
		text70: 'estado',
		text71:'Enviar moneda',
		text72:'Recibir importe',
		
		text73:'Terminación anticipada',
		text74:'La rescisión anticipada dará lugar a una penalización',
		text75:'Daños liquidados',
		text76:'Monto del reembolso',
		
		text77:'Detalles del préstamo',
		text78:'Por favor verifique los detalles de su préstamo y confirme',
		text79:'El monto de su préstamo',
		text80:'Período de préstamo',
		text81:'Por favor confirme y firme',
		text82:'Contrato de Préstamo',
		text83:'pedir prestado ahora',
		text84:'Lea atentamente el contrato de préstamo y proporcione su firma',
		text85:'Las siguientes partes celebran un Acuerdo de Préstamo ("{xieyimingcheng}") con fecha {shengxiaori} {shengxiaoyue} mes {shengxiaonian} (la "Fecha de Entrada en Vigor"):',
		text86:'Prestatario:',
		text87:'Nombre:',
		text88:'Apellido:',
		text89:'Dirección:',
		text90:'Teléfono:',
		text91:'Prestamista:',
		text92:'Nombre:',
		text93:'Denominadas colectivamente las "Partes". ',
		text94:'Condiciones de pago:',
		text95:'Monto del préstamo:{dknum}',
		text96:'El Prestatario se compromete a reembolsar ${dknum} (el "Préstamo").',
		text97:'tasa de interés:{interest}%',
		text98:'La tasa de interés acordada es {interest}% (calculada diariamente).',
		text99:'Plazo del préstamo:{tian}',
		text100:'El plazo de este préstamo es de {tian} días.',
		text101:'Método de pago:',
		text102:'El Prestatario se compromete a reembolsar al Prestamista ${dknum} principal y ${zonglixinum} intereses a su vencimiento.',
		text103:'Aplicabilidad de reembolso:',
		text104:'Cargo por pago atrasado: {zhinajin} * día',
		text105:'Si el prestatario no paga a tiempo,El prestatario deberá pagar la comisión por pago atrasado de {zhinajin} (calculada diariamente),Esta tarifa debe considerarse una tarifa por pago atrasado y no una multa.',
		text106:'Reembolso anticipado:',
		text107:'Los prestatarios no serán penalizados por el pago anticipado. ',
		text108:'Predeterminado:',
		text109: 'Si el prestatario no paga a tiempo, deberá pagar todo el principal y los intereses pendientes inmediatamente sin previo aviso ni exigencia. ',
		text110:'Costo:',
		text111: 'Si el prestatario no cumple con sus obligaciones de pago a tiempo, el prestatario acepta hacerse cargo de todos los costos incurridos por el prestamista en el proceso de cobro, incluidos, entre otros, los honorarios de los abogados. ',
		text112:'Divisibilidad:',
		text113:'Si se determina que alguna disposición de este Acuerdo es inválida o inaplicable, dicha disposición será nula, pero todas las demás disposiciones seguirán siendo válidas y vinculantes. ',
		text114:'Efecto legal:',
		text115:'Este Acuerdo es legalmente vinculante para las partes y puede ejecutarse y ejecutarse dentro de los Estados Unidos y Europa. Las partes declaran que tienen la autoridad para celebrar este Acuerdo. ',
		text116:'Varios:',
		text117: 'Sujeto a los términos de este Acuerdo, la Compañía pagará en la moneda del saldo de la cuenta. Ambas partes tienen la autoridad para celebrar este Acuerdo. Ninguna de las partes infringirá los derechos de un tercero ni violará cualquier otro acuerdo entre las partes. ',
		text118:'Ley aplicable:',
		text119:'Este Acuerdo se interpretará y aplicará de conformidad con las leyes del país donde se encuentra el Prestatario. ',
		text120:'Acuerdo completo:',
		text121: 'Ambas partes confirman y acuerdan que este acuerdo constituye el acuerdo completo entre las partes. Si cualquiera de las partes desea cambiar, agregar o modificar algún término, deberá hacerlo por escrito y firmado por ambas partes. ',
		text122:'Ambas partes aceptan los términos anteriores y firman de la siguiente manera:',
		text123:'Prestador:{xieyimingcheng}',
		text124:'"Prestatario"',
		text125:'Firma del prestatario:',
		text126:'Fecha de firma:{shengxiaori} {shengxiaoyue}mes {shengxiaonian}',
		text127:'Firma',
		text128:'Proporcione la misma firma auténtica que aparece en su documento de identidad',
		text129:'Volver a firmar',
		text130:'Confirmar',
		text131:'Cancelar',
		text132:'Monto de reembolso',
		text133:'Fecha de pago',
		text134:'Reembolso',
		text135:'Descargar PDF',
		
		text136:'Por favor verifique su nombre real primero',
		text137:'Verificado',
	}
}
