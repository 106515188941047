import { createI18n } from 'vue-i18n'
import en from './en'
import tc from './tc'
import tr from './tr'
import vi from './vi'
import es from './es'
import it from './it'
import fr from './fr'
import de from './de'
import ru from './ru'
import ko from './ko'
import ms from './ms'
import jp from './jp_bak'
import zh from "./zh";



let locale = sessionStorage.getItem('locale') || '';

if (locale === '') {
    locale = 'en';
    sessionStorage.setItem('locale', locale);
}

export const setupI18n = (app) => {
    const i18n = createI18n({
        globalInjection: true,
        legacy: false,
        locale: locale,
        messages: {
            en,
            tc,
            tr,
            vi,
            es,
            it,
            fr,
            de,
            ru,
            ko,
            ms,
            jp,
            zh
        }
    })
    app.use(i18n)
}
