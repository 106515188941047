export default {
	account: {
		Start_making_money_plan: "金儲け計画を始める",
		Market: "市場",
		Migital_currency: "デジタル通貨",
		Foreign_exchange: "外国為替",
		Noble_metal: "貴金属",
		Send_Cryop_Now: "今すぐフリーズを送信",
		Send: "送信する",
		Receive: "受け取る",
		Buy: "購入",
		Currency_account: "通貨口座",
		Contract_account: "契約口座",
		Transfer: "移行",
		Select_a_wallet: "ウォレットを選択してください",
		Select_Coin: "コインを選択",
		Confirm: "確認する",
		Wallet: "財布",
		Availavle: "利用可能",
		Frozen: "フリーズする",
		Convert: "変化",
		Receiving_Address: "お届け先の住所",
		Amount: "量",
		Max: "最大",
		Send_Now: "今すぐ送信",
		Please_check_if: "資産の損失を避けるために、送信する前に配送先住所が正しいかどうかを確認してください。",
		new1: '暗号通貨',
		new2: 'インデックス',
		new3: 'バルク',
		new4: '外国為替',
		new5: '総資産',
	},
	home: {
		USDTAccount: 'USDT アカウント',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: '承認する',
		ETHTodayProfit: 'ETH今日の利益',
		totalRevenue: '総収入',
		portfolio: '投資する',
		reliableSecurity: '安心のセキュリティ保証',
		stableReliable: '安定的かつ確実な投資',
		convenienEasy: '便利で簡単な操作',
		pleaseLookForward: '楽しみにしていてください...',
		rateOfReturn: '回答率',
		walletnot: 'MetaMask または任意の Ethereum 拡張ウォレットをインストールしてください',
		wallettip: 'エクスペリエンスに影響を与えないように、アドレスをバインドしてください',
	},
	"964128-0": "日",
	"964128-1": "あっ!",
	"964128-2": "取引記録がありません。リストは空です。",
	"652934-0": "AI 定量取引",
	"652934-1": "AI 定量額",
	"652934-2": "本日の利益",
	"652934-3": "総利益",
	"652934-4": "資金の安全性",
	"652934-5": "安定した利益",
	"652934-6": "簡単な操作",
	"652934-7": "もっと見る",
	"652934-8": "AI 定量を作成",
	"652934-9": "期間",
	"652934-10": "額",
	"652934-11": "リターンレート",
	"652934-12": "クオータ",
	"652934-13": "確認",
	"652934-14": "上部情報",
	"652934-15": "テーマモード",
	"652934-16": "テスト---",
	"314962-0": "Google 2FA",
	"314962-1": "バインドする Google Authenticator をダウンロード",
	"314962-2": "確認コードをコピー",
	"314962-3": "確認コードを入力してください",
	"314962-4": "リクエストが失敗しました:",
	"314962-5": "認証コードがコピーされました",
	"314962-6": "テキストをコピーできません:",
	"314962-7": "コピーに失敗しました。もう一度お試しください。",
	"199917-0": "設定",
	"199917-1": "通知",
	"199917-2": "Google 2FA",
	"199917-3": "電子メール",
	"199917-4": "言語",
	"199917-5": "バージョン",
	"199917-6": "ログアウト",
	"ConnectW": "ウォレットを接続する",
	"dappbrowser": "このウェブサイトは、分散型ウォレット DAPP ブラウザからのアクセスのみを受け入れます。",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "利息を得る",
	"$": "$",
	"ETH Today's Profit": "ETH今日の利益",
	"Wallet": "財布",
	"Record": "注文",
	"Support": "顧客サービス",
	"General": "包括的な",
	"Notifications": "通知する",
	"Invite Friends": "友達を招待",
	"FAQ": "よくある問題",
	"Privacy & Security": "プライバシーとセキュリティ",
	"Legality": "プライバシーポリシー",
	"Authenticator": "確認済み",
	"Settings": "設定",
	"Change Language": "言語を変えてください",
	"Daytime mode": "デイモード",
	"Black mode": "ナイトモード",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

    // marketInfo.vue
    'Open':'オープニング',
    'High':'最高',
    'Low':'最低',
    'Close':'閉まっている',
    'Up':'買い占めます',
    'Down':'買うか売るか',
    'Time':'時間',
    'TransactionFee':'手数料',
    'AvailableBalance':'利用可能残高',
    'submit':'購読',
    'Direction':'方向',
    'Profit':'利益',
    'Quantity':'量',
    'Price':'価格',
    'different':'さまざまなスケールをクリックして最小制限を表示します',
    'lessthan':'少なくともそれ以上',
    'least':'少しでも：',
    'Transactionhistory':'取引記録',
    'Transactionrecords':'取引記録',
    'Number':'量',
    'Profitloss':'利益の損失',
	'Ror':'戻る',
	'operate':'操作する',
	'About_us': '私たちについて',





	//钱包
	TransactiónDetails: '取引詳細',
	dealdetails: '取引詳細',
	ViewTransactionHistory: '取引履歴の表示',
	Deposit: '補充する',
	Rechargeaddress: '保存された値のアドレス：',
	DepositAmount: '入金額：',
	EnterDepositAmount: '入金額を入力してください',
	EnterHash: 'ハッシュ値を入力してください',
	Hash: 'ハッシュ値：',
	Voucherimage: '認証情報の画像：',
	Continue: '続く',
	Withdraw: '撤回する',
	Withdrawal: '取得する：',
	EnterAmounttoWithdraw: '出金額を入力してください',
	Available: "使える：",
	ReceiveAddress: '受信アドレス：',
	EnterWalletAddress: 'ウォレットアドレスを入力してください',
	WithdrawalDesc: '英語での出金には1%の手数料がかかり、入金前にネットワークノードからの確認が必要となりますので、知らない人に仮想通貨を送金しないでください。',
	Exchange: '交換',
	Send: '送信する:',
	Max: '最大:',
	rechargeAmount: 'ストアドバリューの金額を入力してください',
	hashcode: 'ハッシュ値とスクリーンショットを同時に空にすることはできません',
	correcthashcode: '正しいハッシュ値を入力してください',
	maximumwithdrawalamount: '出金額の上限は、',
	Incorrectamountformat: '金額の形式が正しくありません',
	copy: 'コピー',


	//tab2
	"Total Profit": "利益総額",
	"Today's Profit": "今日の利益",
	"What is Automated Trading?": "自動売買とは？",
	"Learn about earning": "収入について知る",
	"Staking Period": "誓約期間",
	"Staking Amount": "誓約数量",
	"Yield": "利点",
	"Limit": "限界",
	"Subscribe": "購読する",

	//tab3
	"Proof of Stake": "ステークの証明",
	"ETH Today is Price": "ETH 本日の価格",
	"ETH Today's Increase": "ETH今日の増加分",
	"Joint Staking": "共同誓約",
	"Individual Staking": "個人的な誓約",
	"tab3long1": "参加者はスマートコントラクトを通じてペアになり、共同ベットを実現し、合計ベット額は 32 ETH に達します。",
	"tab3long2": "独立した賭博では、参加者をマッチングするためのスマートコントラクトは必要ありません。 個人ベットはベット額が 32 ETH に達すると完了します",


	//web_view
	"webviewlong1": "暗号通貨のエアドロップは、ブロックチェーンベースの新興プロジェクトの成長を支援します。 彼らのルーツは、報酬や共通の目標を通じてコミュニティのメンバーを結び付けます。 エアドロップが成功すると、コミュニティが強化され、プロジェクトが改善され、エンド ユーザーに報酬がもたらされます。 長年にわたり、私たちは Proof-of-Work (POW) ブロックチェーン上で多くのエアドロップが実行されるのを見てきました。 プルーフ・オブ・ステーク (POS) イーサリアム チェーンは、数十のネットワークにわたる数百のプロジェクトに対する暗号通貨のエアドロップをサポートするようになりました。 PoS が支配的なコンセンサス パラダイムになるにつれて、イーサリアム ネットワーク上のエアドロップの数は衰える兆しがありません。",
	"webviewlong2": "暗号通貨をステーキングすることで、ステークした暗号通貨の量に応じて貴重なエアドロップ報酬のロックを解除する資格が得られます。",
	"webviewlong3": "報酬エクイティは株式保有者のみが利用でき、何百もの異なるプロジェクトのエアドロップから得られ、単一の独立したエアドロップよりもはるかに高い利回りが得られます。",
	"webviewlong4": "取引所に入金する仮想通貨所有者にとって、報酬のエアドロップは困難な場合が多く、また一部の管理者はエアドロップをまったくサポートしていません。 これが、独自の暗号通貨を管理することが重要である理由です。 イーサリアムは非管理的なエンティティであり、すべての主要ネットワークにステーキングできます。",
	"Introduction": "導入",
	"webviewlong5": "主要なブロックチェーン プラットフォームであるイーサリアムは、イーサリアム 2.0 アップグレードを通じてプルーフ オブ ワーク (PoW) コンセンサス メカニズムからプルーフ オブ ステーク (PoS) メカニズムに移行しています。 PoS ステーキングはこの変革の重要な部分であり、潜在的に報酬を獲得しながらネットワークのセキュリティと運用をサポートする機会をユーザーに提供します。 この記事では、イーサリアム PoS ステーキングの概要とその利点について説明します。",
	"Ethereum 2.0 and Proof of Stake (PoS)": "イーサリアム 2.0 とプルーフ オブ ステーク (PoS)",
	"webviewlong6": 'Ethereum 2.0 は、スケーラビリティ、セキュリティ、復元力を向上させるために設計された主要なネットワーク アップグレードです。 重要な変更の 1 つは、エネルギーを大量に消費する PoW コンセンサス メカニズムから、より環境に優しい PoS メカニズムへの移行です。 PoS では、保有する仮想通貨の量に基づいて新しいブロックを作成し、担保として「ステーク」する意思のあるバリデーターがトランザクションを確認するために選択されます。',
	"The Staking Process": "誓約プロセス",
	"webviewlong7": "イーサリアム PoS ステーキングに参加するには、ユーザーはイーサリアム 2.0 入金契約に入金して少なくとも 32 ETH をステーキングする必要があります。 ETH はステークされると一定期間ロックされ、ネットワークのセキュリティを確保するための担保として機能します。 バリデーターは、賭けられたETHの量とオンラインアクティビティに基づいて選択されます。 彼らは、新しいブロックの提案と検証、およびネットワーク上のトランザクションの確認を担当します。",
	"Staking Rewards and Risks": "ステーキングの報酬とリスク",
	"webviewlong8": "PoS ステーキングに参加することで、ユーザーは新たに鋳造された ETH と取引手数料の形で報酬を獲得できます。 報酬は賭けられたETHの量と全体的なネットワークアクティビティに基づいて決まります。 ただし、ステーキングにはリスクが伴います。たとえば、バリデーターが悪意を持って行動したり、オンラインを継続的に維持できなかった場合に罰則が課される可能性があります。 さらに、ステーキングされた ETH は一定期間ロックされるため、流動性が低く、価格変動の影響を受けやすくなります。",
	"Joint Staking and Individual Staking": "共同誓約書と個人誓約書",
	"webviewlong9": "個別にステーキングするには十分な 32 ETH がないユーザーには、共同ステーキングという別のオプションがあります。 共同ステーキングでは、複数のユーザーが自分の ETH をプールして、必要なステーキング デポジットに達します。 このプーリングは多くの場合、スマート コントラクトまたはステーキング サービスを通じて促進され、ユーザーが少量の ETH でイーサリアム PoS ステーキングに参加できるようになります。",
	"Conclusion": "結論は",
	"webviewlong10": "イーサリアム PoS ステーキングはイーサリアム 2.0 アップグレードの重要な側面であり、ネットワーク セキュリティをサポートし、分散化を改善し、報酬を獲得する機会をユーザーに提供します。 ステーキングプロセス、その報酬とリスクを理解することで、ユーザーはイーサリアムネットワークのこの重要な進化への参加について情報に基づいた決定を下すことができます。",


	//mywallet
	"Total": "合計",

	//record
	"Account": "アカウント",
	"interest-bearing": "利子",
	"POS Staking": "POS 誓約",
	"convert": "交換",
	"transaction": "貿易",
	"pledge": "モーゲージ",
	"recordno": "まだ何も聞いていませんね。 リストは空です。",
	"Oops": "おっと",
	"recharge": "補充する",
	"payment": "支払う",
	"staking": "誓約",
	"income": "所得",
	"Pledge quantity": "誓約数量",
	"cycle": "サイクル",
	"Days Remaining": "残りの日数",
	"cumulative gain": "累積ゲイン",
	"Price increase": "価格が上がる",
	"Price decrease": "価格が下がる",
	"Opening price": "始値",
	"Closing price": "終値",
	"loss": "損失",
	"pfofit": "利益",
	"amount": "量",

	//notifiCations
	"notifiCationslong1": "注: VIP 登録では、対応するアカウント全体の保存値に基づいてレベルが決定されます。 この範囲では、メンバーシップ レベルを決定するために、既存のアカウントから引き出した後のストアド バリューの合計額を差し引く必要があります。",
	"notifiCationslong2": "会員期間もレベルに応じて分かれています。 メンバーシップを維持するには、メンバーシップの特典を享受するには、指定された期間内に毎回少なくとも 5,000 USDT をリチャージする必要があります。 メンバーシップを補充するたびにサイクルが再計算され、累積することができます。",
	"notifiCationslong3": "カスタマーサービスは会員レベルに応じて分かれています。 オフィシャルカスタマーサービスはAPPスタッフが担当し、会員に統一したサービスを提供します。 より良いサービスを提供するため、専任秘書の数には制限を設けております。 専任の個人秘書がユーザーに専用の受付サービスや財務管理のアドバイスを提供します。",
	"notifiCationslong4": "事前にカスタマーサービスに連絡して、充電の予約をしてください。 指定された時間内にリチャージを完了すると、対応するメンバーシップ レベルに基づいて追加の予約リチャージ報酬を受け取ることができます。",
	"notifiCationslong5": "VIP1: UID アカウントに基づいて、累計額が 10,000 USD の場合、177 USDT の報酬を獲得できます。 VIPレベルに到達し、一般会員メダルを獲得すると、ポイントがアカウントにエアドロップされます。",
	"notifiCationslong6": "VIP2: UID アカウントに基づいて、蓄積された価値が 30,000 USD に達すると、777 USDT を受け取ります。 ボーナス額は、VIP レベルに到達し、ブロンズ会員バッジを受け取った直後にアカウントに入金されます。",
	"notifiCationslong7": "VIP3: UID アカウントによると、累計額が 70,000 US ドルの場合、1,777 USDT を受け取ります。 VIP レベルに到達し、シルバー メンバー メダルを受け取ると、ポイントがアカウントにエアドロップされます。 さらに、次回の予約金額に対してさらに 1.5% のボーナスが付与されます。",
	"notifiCationslong8": "VIP4: UID アカウントによると、蓄積された価値は 150,000 米ドルで、2,777 USDT が付与されます。 VIP レベルに到達し、ゴールド メンバー メダルを受け取ると、ポイントがアカウントにエアドロップされます。 さらに、次回の予約のチャージ額にさらに 2% のボーナスが付与されます。",
	"notifiCationslong9": "VIP5: UID アカウントに基づいて、累計保存価値が 300,000 USD に達すると、4,777 USDT がギフトとして与えられます。 VIP レベルに到達し、ダイヤモンド VIP バッジを受け取ると、ポイントがアカウントにエアドロップされます。 さらに、次回の予約の保存金額に対してさらに 2.5% のボーナスが付与されます。",
	"notifiCationslong10": "VIP6: UID アカウントに基づいて、累計保存価値が 800,000 USD に達し、16,888 USDT がギフトとして与えられます。 ボーナス額は、VIP レベルに到達し、Black Diamond 会員バッジを取得した直後にアカウントにエアドロップされ、次回の事前登録リチャージ額の 3% が追加で獲得されます。 財務上のアドバイスを提供したり、税務問題に対処したりするための専任の個人秘書がつきます。",
	"notifiCationslong11": "VIP7: UID アカウントに基づいて、累計保存価値が 300 万米ドルに達すると、ヨーロッパへの 15 日間の豪華旅行 (割引あり) が提供されます。 CoinBposグローバル終身名誉VIPを受賞し、クラウンメンバーシップメダルを受賞しました。 次回の予約にチャージすると、さらに 3.5% のボーナスが得られます。",
	"notifiCationslong12": "VIP8: UID アカウントによると、蓄積された価値が 800 万米ドルに達すると、CoinBpos モロッコの年次宴会ギフト券を獲得できます。 報酬: 1 BTC および CoinBpos グローバル生涯名誉 VIP を受け取ります。 次回の予約時に追加で 4% のボーナスを獲得できます。",
	"notifiCationslong13": "VIP9: UID アカウントに基づいて、合計 3,000 万米ドルを入金すると、CoinBpos 株の 3% を取得できます。 そして年間 3% の利益配当をお楽しみください。 Cronix 純金記念ピンを無料でプレゼント。",

	//invite
	"Refer and Earn": "紹介して獲得する",
	"Copy Link": "リンクをコピーする",

	//faq
	"Frequently Asked Questions": "よくある質問",

	//authenticator
	"mailbox": "メールボックス",
	"Front photo": "縦長の写真",
	"Back photo": "背面写真",
	"Handheld ID photo": "証明写真を持っている",
	"define": "もちろん",

	//legality
	"legalitylong1": "Coinbpos は複数の仮想通貨企業で構成されており、仮想通貨コミュニティの構築、成長、コミュニティの安定性の維持を支援するツールを共同で提供し、数千もの分散型アプリケーションを強化し、仮想通貨の未来を推進し、伝統的な通貨の進化を促進しています。繁栄。",
	"legalitylong2": "Coinbpos 内の各企業は、そのサービスに関連する個人データの処理のデータ管理者として機能します。各サービスのデータ管理者は次のとおりです。：",
	"legalitylong3": "1. ブロックチェーン技術 - イーサリアムブロックチェーン。",
	"legalitylong4": "2. 分散型ウォレット - トラストウォレット、メタマスク、暗号ウォレット。",
	"legalitylong5": "3. 取引テクノロジー – Coinbase、Crypto、linch、Binance。",
	"legalitylong6": "プライバシーポリシーは、Coinbposが関連するデータ保護法の下で義務的に個人データを処理する方法を詳細に説明しています。EUの「汎用データ保護条例」（GDPR）を含む、",
	"legalitylong7": 'カリフォルニア州消費者プライバシー法 (CCPA) およびその他の一般法 (総称して「データ保護法」*)。',

	//language
	"language": "言語",
	"Confirm": "確認する",

	"newtext1": "やめる",
	"newtext2": "キャンセル",
	"newtext3": "このアカウントからログアウトしてもよろしいですか？",
	"Confirm": "確認する",
	"Cancel": "キャンセル",
	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "ログイン",
	"newtext9": "ユーザー名",
	"newtext10": "携帯電話番号",
	"newtext11": "ユーザー名を入力してください",
	"newtext12": "携帯電話番号を入力してください",
	"newtext13": "パスワード",
	"newtext14": "パスワードを入力してください",
	"newtext15": "まだアカウントがありません",
	"newtext16": "登録",
	"newtext17": "国",
	"newtext18": "国を選択してください",
	"newtext19": "携帯電話番号認証コード",
	"newtext20": "確認コードを入力してください",
	"newtext21": "メールアドレス",
	"newtext22": "メールアドレスを入力してください",
	"newtext23": "パスワードの確認",
	"newtext24": "確認用のパスワードを入力してください",
	"newtext25": "アカウントをお持ちです",
	"newtext26": "確認コードを送信",
	"newtext27": "携帯電話番号が間違っています",
	"newtext28": "正常に送信されました",
	"newtext29": "登録成功",

	"newtext30": '通貨',
	"newtext31": '外国為替',
	"newtext32": '貴金属',
	
	"newtext33": 'アカウント',
	"newtext34": 'プラットフォームアクティビティ',
	"newtext35": 'オンライン顧客サービス',
	"newtext36": '招待',
	"newtext37": 'KYC 検証',
	"newtext38": '設定',
	wallet_p: {
		text1: "アドレスをコピー",
		text2: '確認',
		text3: '交換元',
		text4: "交換先",
		text5: "MAX",
		text6: "招待",
		text7: '紹介報酬を獲得',
		text8: "あなたの友人がAI仲裁に参加し、マイニングマシンをレンタルすると、あなたは紹介報酬を獲得できます",
		text9: "コピー",
		text10: 'リンクを共有',
		text11: "KYC認証",
		text12: "国",
		text13: "国を選択してください",
		text14: "名",
		text15: "あなたの名を入力してください",
		text16: "姓",
		text17: "あなたの姓を入力してください",
		text18: "証明書の種類",
		text19: "証明書の種類を選択してください",
		text20: "証明書番号",
		text21: "証明書番号を入力してください",
		text22: "あなたの身分証明書の表側の写真をアップロードしてください",
		text23: "あなたの身分証明書の裏側の写真をアップロードしてください",
		text24: "あなたのパスポートの表側の写真をアップロードしてください",
		text25: "あなたのパスポートの裏側の写真をアップロードしてください",
		text26: "メールアドレス",
		text27: "あなたのメールアドレスを入力してください",
		text28: "送信",
		text29: "認証コード",
		text30: "あなたの認証コードを入力してください",
		text31: "ID",
		text32: "パスポート",
		text33: "キャンセル",
		text34: "国/地域",
		text35: "認証のために正しい情報を入力してください",
		text36: "送信成功！",
		text37: "メールアドレスの形式が不正です！",
		text38: "運転免許証",
		text39: "運転免許証の正面の写真をアップロードしてください",
		text40: "運転免許証の裏面の写真をアップロードしてください",
		
		text41: "IDカード番号",
		text42: "ID 番号を入力してください",
		text43: "パスポート番号",
		text44: "パスポート番号を入力してください",
		text45: "運転免許証番号",
		text46: "運転免許証番号を入力してください",
		
		text47:'運転免許証を持っている写真をアップロードしてください',
		text48:'ID カードを持っている写真をアップロードしてください',
		text49:'パスポートを持っている写真をアップロードしてください',
		text50:'デモアカウント',
	},
	jiaoyi: {
		text1: '2番目の契約',
		text2: '永久契約',
		text3: 'スポット取引',
		text4: '今すぐ委任してください',
		text5: 'アカウントの種類',
		text6: '納期',
		text7: '価格帯',
		text8: '購入価格',
		text9: '料金',
		text10: 'バランス',
		text11: '少なくとも',
		text12: '期待される',
		text13: '購入金額',
		text14: '方向',
		text15: '購入価格',
		text16: 'リアルタイム価格',
		text17: 'カウントダウン',
		text18: '私の契約',
		text19: '利益',
		text20: '契約',
		text21: '配送価格',
		text22: '納期',
		text23: '市場価格',
		text24: '上昇',
		text25: '秋',
		text26: '購入価格',
		text27: '最大値',
		text28: '利益',
		text29: '手数料',
		text30: '購入',
		text31: '販売',
		text32: '購入数量',
		text33: 'リアルアカウント',
		text34: '仮想アカウント',
		text35: 'まだデータがありません',
		text36: '現在の位置',
		text37: '歴史委員会',
		text38: 'クローズポジション',
		text39: 'マージン',
		text40: '損益',
		text41: 'ホールド',
		text42: '購入記録',
		text43: '販売記録',
		text44: '利用可能',
		text45: '折ります',
		text46: '認証成功',
		text47: '購入時間',
		text48: '未読',
		text49: '読む',
		text50: 'ホストの注文',
		text51: 'トータルアービトラージ',
		text58: '今日の収益',
		text52: '人工知能ロボットの仕組み',
		text53: '裁定取引商品',
		text54: '天',
		text55: '金額',
		text56: '毎日の収入',
		text57: '通貨の種類',
		text59: 'AI アービトラージに参加',
		text60: 'ゼロリスク、迅速なリターン',
		text61: 'AI アービトラージ',
		text62: '期待収益',
		text63: 'アービトラージコインタイプ',
		text64: 'エスクロー金額',
		text65: '今すぐホスト',
		text66: 'USDT ウォレットに送られる毎日の収入',
		text67: '投資ファンドゼロリスク',
		text68: '資金はいつでも引き出す​​ことができます',
		text69: '人工知能は 24 時間稼働します',
		text70: '累計収入',
		text71: '有効期限',
		text72: 'すべて',
		text73: '進行中',
		text74: '完了',
		text75: 'ホストの詳細',
		text76: '通貨を選択',
		text77: 'テクニカルサポート',
		text78: 'メールを送信',
	},
	tabbar:{
		text1:'ホームページ',
		text2:'市場',
		text3:'Ai の定量化',
		text4:'ローン',
		text5:'アカウント',
		text6:'アカウント残高',
		text7:'今日の収益',
		text8:'返品率',
		text9:'もっと',
		text10:'近日公開予定',
		text11:'新しいコインの先行販売',
		text12:'デジタル資産の旅に乗り出しましょう',
		text13:'今すぐ購入',
		text14:'操作が簡単',
		text15:'AI定量取引',
		text16:'安全/安定/シンプル',
		text17:'トランザクションの作成',
		text18:'合計コミッション',
		text19:'このリンクを友達と共有すると、最大 100 ドルを獲得できます。 ',
		text20:'おすすめリンク',
		text21:'ソーシャル ソフトウェアを通じて友達と共有',
	},
	tab4: {
		text1: "アカウント残高",
		text2: "今日の収益",
		text3: "AI量化",
		text4: "収益率",
		text5: "入金",
		text6: "電信送金",
		text7: "出金",
		text8: "入金",  // 注意：これは重複項目であり、適切な置換または削除が必要かもしれません
		text9: "ウォレットアドレス",
		text10: "コピー",
		text11: "証明書のアップロード",
		text12: "入金アドレスを正しく確認してください。誤った送金は取り消しできない可能性があります。入金は5ブロックの確認を受け取った後にアカウントに反映されます。",
		text13: "電信送金",
		text14: "電信送金を開始する前に:",
		text15: "電信送金を開始する前に、正確な電信送金口座情報を取得するためにカスタマーサービスチームに連絡してください。この手順は、お客様の資金の安全と円滑な到着を保証します。",
		text16: "電信送金処理時間:",
		text17: "電信送金を開始する前に、正確な電信送金口座情報を取得するためにカスタマーサービスチームに連絡してください。この手順は、お客様の資金の安全と円滑な到着を保証します。",
		text18: "電信送金過程中のサポート:",
		text19: "電信送金の過程中に問題や質問があれば、いつでもカスタマーサービスチームに連絡してください。お客様にスムーズな取引体験を提供するために、支援とサポートを提供することに取り組んでいます。",
		text20: "金額",
		text21: "出金通貨",
		text22: "確認",
		text23: "受取アドレスを必ず確認してください。誤った送金は取り消しできません。出金には3%の手数料が発生し、5つのネットワーク確認を受け取った後にアカウントに反映されます。",
		text24: "オプション注文",
		text25: "コントラクト注文",
		text26: "AI量化注文",
		text27: "履歴",
		text28: "友人を招待",
		text29: "よくある質問",
		text30: "お問い合わせ",
		text31: "会社情報",
		text32: "言語の変更",
		text33: "ログアウト"
	},
	uploadProof: {
		text1: "証明書のアップロード",
		text2: "画像を選択",
		text3: "プレビュー",
		text4: "入金のスクリーンショットをアップロード",
		text5: "金額",
		text6: "トランザクションハッシュ",
		text7: "後ろ6桁",
		text8: "暗号通貨の支払いスクリーンショットをアップロードし、提出後、審査を待ちます",
		text9: "確認"
	},
	newadd:{
		text1:'成功',
		text2:'失敗',
		text3:'やるべきこと',
		text4:'私のクラウントークン',
		text5:'供給',
		text6:'販売済み',
		text7:'プレセールは終了します',
		text8:'金額',
		text9:'交換',
		text10:'技術的特徴',
		text11:'新しい通貨は、高速な取引速度、低い手数料、高い取引セキュリティに加え、スマートコントラクト機能とプライバシー保護技術を備えたブロックチェーン技術を使用しています。 ',
		text12:'プロジェクトのビジョン',
		text13:'Xincoin のビジョンは、世界をリードするデジタル資産取引プラットフォームとなり、より安全で便利、効率的なデジタル資産取引サービスをユーザーに提供することです。 ',
		text14:'アプリケーションシナリオ',
		text15:'新しい通貨には、デジタル通貨取引、デジタル証券取引、デジタル資産取引、支払い決済、サプライチェーン金融、その他の分野を含む幅広い応用シナリオがあります。 ',
		text16:'融資額',
		text17:'身元を確認して、より多くのサービスを受けてください! ',
		text18:'検証を開始',
		text19:'借りたいのですが...',
		text20:'貸出期間',
		text21:'日',
		text22:'日利率',
		text23:'利息総額',
		text24:'融資後 1 日間は利息が支払われませんが、その後は利息が支払われます。 ',
		text25:'今すぐ借りる',
		text26:'ローン記録',
		text27:'まだローンはありません',
		text28:'ローン情報が見つかりません',
		text29:'融資限度額の範囲を超えています',
		text30:'申請中',
		text31:'進行中',
		text32:'終了',
		text33:'開く',
		text34:'閉じる',
		text35:'レコード',
		text36:'デポジット',
		text37:'撤退',
		text38:'おっと!',
		text39:'まだ取引記録がありません。リストは空です。 ',
		text40:'金額を入力してください',
		text41:'通常',
		text42:'終了',
		
		text43:"ログインから始めましょう!",
		text44:"メール",
		text45:"メールアドレスを入力してください...",
		text46:"パスワード",
		text47:"パスワードを入力してください...",
		text48:"パスワードをお忘れですか?",
		text49:"ログイン",
		text50:"アカウントをお持ちではありませんか?",
		text51:"登録",
		text52:"認証コードが送信されました",
		text53:"検証",
		text54:"登録から始めましょう!",
		text55:"パスワードの確認",
		text56:"推奨/プロモーション コード",
		text57:"紹介コード/プロモーション コードを入力してください (オプション)",
		text59:"すでにアカウントをお持ちですか?",
		text60:"メールアドレスを入力してください",
		text61:"パスワードを入力してください",
		text62:"確認用パスワードを入力してください",
		
		text63:'ユーザー認証',
		text64:'クレジットスコア',
		text65:'検討中',
		text66:'承認されました',
		text67:'KYC 書類を提出していただきありがとうございます。私たちのチームが 48 時間以内にあなたの情報を確認します。審査結果を今しばらくお待ちください。',
		text68:'取引を終了する',
		text69:'終了成功',
		
		text70:'ステータス',
		text71:'通貨を送信',
		text72:'受け取り金額',
		
		text73:'早期終了',
		text74:'早期終了にはペナルティが発生します',
		text75:'損害賠償額の清算',
		text76:'返金金額',
		
		text77:'ローンの詳細',
		text78:'ローンの詳細を確認して確認してください',
		text79:'あなたのローン金額',
		text80:'貸出期間',
		text81:'確認して署名してください',
		text82:'ローン契約',
		text83:'今すぐ借りる',
		text84:'ローン契約書をよく読んで署名してください',
		text85:"以下の当事者は、{shengxiaori} {shengxiaoyue} 月 {shengxiaonian} (「発効日」) の日付で融資契約 (「{xieyimingcheng}」) を締結します。",
		text86:'借り手:',
		text87:'名前:',
		text88:'姓:',
		text89:'アドレス:',
		text90:'電話番号:',
		text91:'貸し手:',
		text92:'名前:',
		text93:'総称して「当事者」といいます。 ',
		text94:'返済条件:',
		text95:'融資額：{dknum}',
		text96:'借り手は ${dknum} (「ローン」) を返済することに同意します。',
		text97:'金利：{interest}%',
		text98:'合意された金利は {interest}% です (日次ベースで計算されます)。',
		text99:'融資期間:{tian}',
		text100:'このローンの期間は {tian} 日です。',
		text101:'返済方法：',
		text102:'借り手は、期限が来たら貸し手に元金 ${dknum} と利息 ${zonglixinum} を返済することに同意します。',
		text103:'返済の適用:',
		text104:'支払い遅延手数料: {zhinajin} * 日',
		text105:'借り手が期日までに返済できなかった場合，借り手は、{zhinajin} の遅延損害金 (日割り計算) を支払うものとします，この手数料は、ペナルティではなく、支払い遅延手数料とみなされるべきです。',
		text106:'早期返済:',
		text107:'借り手には繰り上げ返済に対するペナルティは課されません。 ',
		text108:'デフォルト:',
		text109: '借り手が期日通りに返済できない場合、借り手は通知や要求なしに、未払いの元本と利息をすべて直ちに支払うものとします。',
		text110:'コスト:',
		text111: '借り手が期日までに返済義務を履行できない場合、借り手は弁護士費用を含むがこれに限定されない、回収プロセスで貸し手が負担するすべての費用を負担することに同意します。',
		text112:'可分性:',
		text113:'本契約のいずれかの条項が無効または法的強制力がないと判明した場合、その条項は無効となりますが、他のすべての条項は引き続き有効で拘束力のあるものとします。',
		text114:'法的効果:',
		text115:'本契約は当事者に対して法的拘束力があり、米国およびヨーロッパ内で締結および執行される可能性があります。」 両当事者は、本契約を締結する権限があることを表明します。 ',
		text116:'さまざま:',
		text117: '本契約の条件に従い、当社は口座残高の通貨で支払うものとします。」 両当事者は本契約を締結する権限を有します。 いずれの当事者も、第三者の権利を侵害したり、当事者間のその他の合意に違反したりしてはなりません。 ',
		text118:'適用法:',
		text119:'本契約は、借り手が所在する国の法律に従って解釈および施行されるものとします。',
		text120:'完全な同意:',
		text121: '両当事者は、この契約が両当事者間の完全な合意を構成することを確認し、同意します。 いずれかの当事者が条件の変更、追加、修正を希望する場合は、書面で両当事者が署名する必要があります。 ',
		text122:'両当事者は上記の条件に同意し、次のように署名します:',
		text123:'貸し手:{xieyimingcheng}',
		text124:'"借り手"',
		text125:'借り手の署名:',
		text126:'署名日:{shengxiaori} {shengxiaoyue}月 {shengxiaonian}',
		text127:'署名',
		text128:'ID カードと同じ本物の署名を提供してください',
		text129:'再署名',
		text130:'確認',
		text131:'キャンセル',
		text132:'返済金額',
		text133:'返済日',
		text134:'返済',
		text135:'PDF をダウンロード',
		
		text136:'まず本名を確認してください',
		text137:'確認済み',
	}

}
