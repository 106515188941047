<template>
  <div class="box">
    <div class="wrapper">
        <slot></slot>
    </div>
  </div>
</template>

<script setup>


</script>

<style scoped lang="scss">
.box{
    border-radius: 5px;
    background-image: linear-gradient(134deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
    -webkit-backdrop-filter: blur(125.5px);
    backdrop-filter: blur(125.5px);
    padding: 1px;
    .wrapper {
        width: 100%;
        height: 100%;
        background-color: #202020;
        border-radius: 5px;
    }
}
</style>
