<template>
    <div  class="homePage" :class="isBlack != 'true'?'whiteBox':''">
		<div class="top_header">
		  <div class="header">
		    <div class="header_left">
		      <img src="../../static/demo/logo.png" class="logoimg" />
		    </div>
		    <div class="header_right" @click="upzhstatus=!upzhstatus">
		    	<div class="action">
		    		{{username}}
		    		<img v-if="upzhstatus" src="../../static/demo/arrow_up.4af92010.svg"  alt="" style="">
		    		<img v-else src="../../static/demo/arrow_down.36c10fef.svg" alt="" style="">
		    		<div v-if="upzhstatus" class="day-lists-new" style="">
		    			<template v-if="is_cs==0">
		    				<div class="day-list-new day-list1-new" @click="quehuanzhanghua">{{username}}</div>
		    				<div class="day-list-new" @click="quehuanzhanghua">{{$t('wallet_p.text50')}}</div>
		    			</template>
		    			<template v-else>
		    				<div class="day-list-new" @click="quehuanzhanghua">{{username}}</div>
		    				<div class="day-list-new day-list1-new" @click="quehuanzhanghua">{{$t('wallet_p.text50')}}</div>
		    			</template>
		    		</div>
		    	</div>
		    </div>
		  </div>
		</div>
		<div class="content">
			
			<div class="new-feature" dir="auto">
				<div class="new-feature1">
					<div class="left-bg1"></div>
					<div class="left-bg2"></div>
				</div>
				<div class="titles">
					<div class="balance">
						<img src="../../static/demo/balance.0dce5020.svg" alt="">
					</div> {{$t('newadd.text16')}}
				</div>
				<div class="account-number">2000000 <span >USD</span>
				</div>
				<div class="account-info">{{$t('newadd.text17')}}</div>
				<div @click="toyanzheng" class="start-button" v-if="sfstatus==2">{{$t('newadd.text137')}}</div>
				<div @click="toyanzheng" class="start-button" v-else>{{$t('newadd.text18')}}</div>
				<div class="hr"></div>
				<div class="input-list">
					<div class="inputs inputs-max">
						<div class="lable">{{$t('newadd.text8')}}</div>
						<div class="input-content input-content1">
							<input type="text"  :placeholder="$t('newadd.text19')" v-model="inputnum" @input="inputhit">
							<div class="input-button input-buttons1" @click="inputnum=data[morenindex].nummax">Max</div>
						</div>
					</div>
				</div>
				<div class="input-list">
					<div class="inputs inputs-max">
						<div class="lable">{{$t('newadd.text20')}}（{{$t('newadd.text21')}}）</div>
						<div @click="updatae" class="input-content" style="z-index: 3;">
							{{data[morenindex].tian}} {{$t('newadd.text21')}}
							<img v-show="isshowxuanze==false" src="../../static/demo/arrow_down.36c10fef.svg" alt="">
							<img v-show="isshowxuanze==true" src="../../static/demo/arrow_up.4af92010.svg" alt="">
						</div>
						<div class="input-content" style="margin-top: -55px;z-index: 2;">
							<div v-show="isshowxuanze==true" class="day-lists">
								<div v-for="(item,index) in data" class="day-list" @click="updata(index)">
									<div >{{item.tian}} {{$t('newadd.text21')}}</div>
									<div >{{item.nummin}}-{{item.nummax}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="infos">
					<div class="info">
						<div class="info-title">{{$t('newadd.text22')}}</div>
						<div class="info-content">{{data[morenindex].interest}}% </div>
					</div>
					<div class="info">
						<div class="info-title">{{$t('newadd.text23')}}</div>
						<div class="info-content"> ${{jisuanlixi}}</div>
					</div>
				</div>
				<div class="infos-main">
					<p>{{$t('newadd.text24')}}</p>
				</div>
				<div @click="jiekuan" class="confirm-button confirm-buttons">{{$t('newadd.text25')}}</div>
			</div>
			
			<div class="list-title" dir="auto">{{$t('newadd.text26')}}</div>
			
			<div v-if="dklist.length > 0">
				
				<div class="dklistcss" v-for="item in dklist" @click="gotohk(item.id)">
					<div>{{item.num}}-{{item.tian}}{{$t('newadd.text21')}}</div>
					<div v-if="item.state==1" class="bai">{{$t('newadd.text30')}}</div>
					<div v-else-if="item.state==2" class="lv">{{$t('newadd.text31')}}</div>
					<div v-else-if="item.state==3" class="hong">{{$t('newadd.text32')}}</div>
					<div v-else-if="item.state==0" class="bai">{{$t('newadd.text33')}}</div>
					<div v-else class="hong">{{$t('newadd.text34')}}</div>
				</div>
	
			</div>
			
			<div v-else class="no-data">
				<div class="no-img">
					<img src="../../static/demo/no_data.78bef72c.svg" alt="" srcset="">
				</div>
				<div class="no-title">{{$t('newadd.text27')}}</div>
				<div class="no-info">{{$t('newadd.text28')}}</div>
			</div>
			
		</div>
		<div style="height: 50px;"></div>
	</div>
</template>

<script>

    // 功能引入
    import {
        toRefs,
        reactive,
        onMounted,
    } from 'vue'

	import {
	    useRouter
	} from 'vue-router'
	import { showToast } from 'vant';
	import {
	  useI18n
	} from "vue-i18n";
    //接口
    import { setloan,getloanlist,getloantype } from '../api/tab4'
	import { userkycstatus,user } from '../api/tab5'
	import { loginnew,Login } from '@/api/hometab'
    import { showFailToast,showSuccessToast } from 'vant';
	import { ElLoading } from "element-plus";
	
    export default {
        components: {
            // Select
        },
        setup() {
			let router = useRouter();
			const {
			  t,locale
			} = useI18n()
			
            // 数据
            const State = reactive({
                isBlack: 'true',//默认黑色（缓存内的值是字符串）
				
				isshowxuanze:false,
				inputnum:0,
				jisuanlixi:0,
				morenindex:0,
				data:[
					{tian:15,nummin:10000,nummax:100000000,interest:2},
					{tian:7,nummin:1000,nummax:1000000,interest:0.15},
					{tian:3,nummin:5000,nummax:5000000,interest:0.18},
				],
				username:'',
				sfstatus:0,
				dklist:[],
				
				upzhstatus:false,
				is_cs:0,//0:正常 1：模拟
				
				loginip:'0,0,0,0',
            });

            // 方法
            const Function = reactive({
                setTheme() {
                    //console.log('主题模式', localStorage.getItem('isBlack'))
                    State.isBlack = 'true';
                    if (localStorage.getItem('isBlack')) {
                        State.isBlack = localStorage.getItem('isBlack')//true代表黑色主题，false代表白色主题。。。 缓存内的值是字符串
                    }
					fetch('https://09122.13lt.com/api/getip').
					then(response => response.json()).
					then(data => {
						if(data.status===1){
							State.loginip = data.info
						}
					}).catch(error => {
						console.error(error)
					})
					//let usernamedata = localStorage.getItem('username').split('@')
					//State.username = usernamedata[0]
					State.username = localStorage.getItem('username').substr(-8)
					getloanlist().then((res) => {
						//console.log('贷款记录',res.data)
						State.dklist = res.data.info
					});
					user().then(res => {
						//console.log('用户中心',res.data)
						State.is_cs = res.data.url.is_cs
					})
					//身份验证状态
					userkycstatus().then((res) => {
						//console.log('身份验证状态',res.data)
						if(res.data.info){
							State.sfstatus = res.data.info.idstate
						}
					});
					getloantype().then((res) => {
						State.data = res.data.info
						console.log('贷款列表',res.data.info)
					});
                },
				quehuanzhanghua(){
					const loading = ElLoading.service({
					  lock: true,
					  text: "Loading",
					  background: "rgba(0, 0, 0, 0.7)",
					});
					if(State.is_cs==0){
						//切换到模拟
						//loginnew({type:2,username:localStorage.getItem('username'),is_cs:1}).then(res => {
						Login({username:localStorage.getItem('username'),ip:State.loginip,is_cs:1}).then(res => {
							loading.close();
							if(res.data.status==0){
								showToast(res.data.info)
							} else if(res.data.status==1){
								window.location.reload()
							}
						}).catch((error) => {
							//console.log(error.message)
							showToast(error)
						})
					} else {
						//切换到正式
						//loginnew({type:2,username:localStorage.getItem('username'),password:localStorage.getItem('loginpassword'),is_cs:0}).then(res => {
						Login({username:localStorage.getItem('username'),ip:State.loginip,is_cs:0}).then(res => {
							loading.close();
							if(res.data.status==0){
								showToast(res.data.info)
							} else if(res.data.status==1){
								window.location.reload()
							}
						}).catch((error) => {
							//console.log(error.message)
							showToast(error)
						})
					}
				},
				toyanzheng(){
					router.push({
					    path: "/verification"
					});
				},
				inputhit(){
					State.jisuanlixi = ((State.inputnum * State.data[State.morenindex].interest / 100) * (State.data[State.morenindex].tian - 1)).toFixed(2)
				},
				gotohk(id){
					router.push({
					    path: "/setloandetails",
						query: {
						  id:id
						},
					});
				},
				jiekuan(){
					if(State.sfstatus==2){
						router.push({
						    path: "/loandetails",
							query: {
							  num: State.inputnum,
							  loanid:State.data[State.morenindex].id
							},
						});
					} else {
						showToast(t('newadd.text136'))
					}
					return false
					if( Number(State.inputnum) < Number(State.data[State.morenindex].nummin) || Number(State.inputnum) > Number(State.data[State.morenindex].nummax)){
						showToast(t('newadd.text29'))
						return false
					}

					let posts = {
						num:State.inputnum,
						tian:State.data[State.morenindex].tian,
						interest:State.data[State.morenindex].interest
					}
					setloan(posts).then((res) => {
						//console.log(res.data)
						showToast(res.data.info)
						setTimeout(() => {
							window.location.reload()
						}, 1000)
					});
				},
				updatae(){
					State.isshowxuanze = true
				},
				updata(index){
					State.isshowxuanze = false
					console.log(State.isshowxuanze)
					State.morenindex = index
					State.jisuanlixi = ((State.inputnum * State.data[index].interest / 100) * (State.data[index].tian - 1)).toFixed(2)
				},

            });
			// 接口
			const Interface = reactive({});

            onMounted(() => {
                Function.setTheme()
            });
            return {
                ...toRefs(State),
                ...toRefs(Function),
                ...toRefs(Interface),
            }
        },
    }
</script>

<style type="text/css" scoped>
    .homePage {
      background-color: #000;
      width: 100%;
      min-height: 100vh;
      overflow-y: scroll;
      color: #ffffff;
	}
	.top_header{
	  position: fixed;
	  top: 0;
	  left: 0;
	  height: 29px;
	  width: 100%;
	  background: #000;
	  padding: 16px 0;
	  z-index: 10;
	}
	.header{
	  padding: 0 20px;
	  height: 29px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  color: #ffffff;
	}
	.header_left{
	  display: flex;
	  align-items: baseline;
	}
	.logoimg{
	  /* width: 24px; */
	  height: 24px;
	  margin-right: 6px;
	}
	.leftname{
	  font-family: Inter;
	  font-style: normal;
	  font-weight: 700;
	  font-size: 24px;
	}
	.header_right{
	  //display: flex;
	  align-items: center;
	  justify-content: space-between;
	  width: 124px;
	  height: 42px;
	  border-radius: 10px;
	  background: #1a1a1a;
	  position: relative;
	  text-align: center;
	  line-height: 42px;
	
	  .action{
	    //display: flex;
	    text-align: center;
	    align-items: center;
	    font-weight: 500;
	    font-size: 14px;
	    //margin-left: 12px;
	    //margin-right: 12px;
	  }
	}
	.content{
	  width: 90%;
	  margin: auto;
	  margin-top: 61px;
	  padding-bottom: 90px;
	  overflow: hidden;
	  border-radius: 20px;
	  position: relative;
	}
	.new-feature {
	    width: calc(100% - 32px);
	    min-height: 1.44rem;
	    background: #1a1a1a;
	    border-radius: 20px;
	    padding: 16px 16px 31px 16px;
	    position: relative;
	}
	.new-feature1 {
	    width: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    overflow: hidden;
	    height: 100%;
	    border-radius: 2px;
	}
	.left-bg1{
	  background: #d8d8d8;
	  mix-blend-mode: normal;
	  opacity: .08;
	  transform: rotate(-35deg);
	  width: 140px;
	  height: 235px;
	  position: absolute;
	  right: -65px;
	  top: -61px;
	  //z-index: 99;
	}
	.left-bg2{
	  background: #d8d8d8;
	  mix-blend-mode: normal;
	  opacity: .08;
	  transform: rotate(-35deg);
	  width:140px;
	  height: 235px;
	  position: absolute;
	  right: -100px;
	  top: -61px;
	  //z-index: 99;
	}
	.titles {
	    font-weight: 500;
	    font-size: 14px;
	    display: flex;
	    justify-content: flex-start;
	}
	.balance {
	    width: 16px;
	    height: 16px;
	    margin-right: 8px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
	.account-number {
	    font-family: DIN Alternate;
	    font-style: normal;
	    font-weight: 700;
	    font-size: 32px;
	    line-height: 37px;
	    color: #fff;
	    height: 37px;
	    margin: 20px 0 12px 0;
	}
	.account-number span {
	    color: #c0dd2b;
	}
	.account-info {
	    font-family: Inter;
	    font-style: normal;
	    font-weight: 500;
	    font-size: 14px;
	    line-height: 17px;
	    height: 17px;
	    color: #ffffff4d;
	    white-space: nowrap;
	}
	.start-button {
	    font-family: Inter;
	    font-style: normal;
	    font-weight: 600;
	    font-size: 16px;
	    text-align: center;
	    color: #000;
	    width: 198px;
	    height: 40px;
	    background: #c0dd2b;
	    border-radius: 20px;
	    line-height: 40px;
	    margin: 16px 0 24px 0;
	    position: relative;
	    z-index: 1;
	}
	.hr {
	    width: 100%;
	    border-bottom: 1px solid #ffffff12;
	}
	.input-list {
	    display: flex;
	    justify-content: space-between;
	    margin-top: 25px;
	}
	.inputs-max {
	    width: 100%;
	}
	.lable {
	    font-weight: 500;
	    font-size: 12px;
	    color: hsla(0, 0%, 100%, .3);
	}
	.input-content1 {
	    padding: 0 6px 0 24px;
	}
	.input-content {
	    background: #d8d8d814;
	    margin: auto;
	    mix-blend-mode: normal;
	    border-radius: 10px;
	    margin-top: 8px;
	    height: 56px;
	    line-height: 56px;
	    display: flex;
	    justify-content: space-between;
	    color: #fff;
	    align-items: center;
	    font-weight: 600;
	    font-size: 14px;
	    position: relative;
		width: 100%;
		box-sizing: border-box;
	}
	.input-content input {
	    background: none;
	}
	input {
	    outline: none;
	    border: none;
	}
	button, input, textarea {
	    color: inherit;
	    font: inherit;
	}
	.input-button{
	    width: 80px;
	    height: 44px;
	    background: #1a1a1a;
	    border-radius: 10px;
	    font-weight: 400;
	    font-size: 14px;
	    line-height: 44px;
	    text-align: center;
	}
	.input-content img {
	    width: 24px;
	    height: 24px;
	}
	.day-lists{
	    z-index: 2;
	    width: 100%;
	    max-height: 168px;
	    overflow-y: auto;
	    position: absolute;
	    top: 62px;
	    border-radius: 10px;
	    background: #292929fc;
	    color: #fff;
	    left: 0;
	}
	.day-list {
	    height: 56px;
	    box-sizing: border-box;
	    width: 100%;
	    padding: 0 16px 0 20px;
	    display: flex;
	    justify-content: space-between;
	}
	.infos {
	    display: flex;
	    justify-content: space-between;
	    margin-top: 16px;
	}
	.info-title{
	    font-weight: 500;
	    font-size: 12px;
	    line-height: 15px;
	    height: 15px;
	    color: #ffffff4d;
	}
	.info-content {
	    font-weight: 600;
	    font-size: 14px;
	    line-height: 17px;
	    color: #fff;
	    margin-top: 6px;
	    height: 17px;
	}
	.infos-main{
	    font-weight: 400;
	    font-size: 14px;
	    line-height: 20px;
	    color: #ffffff4d;
	    margin-top: 16px;
	}
	.confirm-buttons{
	    width: 100% !important;
	    position: relative;
	    z-index: 1;
	}
	.confirm-button {
	    font-weight: 600;
	    font-size: 16px;
	    color: #000;
	    width: 100%;
	    height: 48px;
	    background: #c0dd2b;
	    border-radius: 13px;
	    text-align: center;
	    line-height: 48px;
	    margin-top: 24px;
	}
	.list-title {
	    font-weight: 600;
	    font-size: 16px;
	    line-height: 19px;
	    color: #fff;
	    height: 19px;
	    margin: 24px 0 16px 0;
	}
	.no-data {
	    width: 100%;
	    height: 100%;
	}
	.no-img {
	    width: 56px;
	    height: 56px;
	    background: #c0dd2b;
	    border-radius: 56px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    margin: auto;
	    margin-top: 80px;
	}
	.no-img img {
	    width: 32px;
	}
	.no-title {
	    font-weight: 500;
	    font-size: 24px;
	    color: #fff;
	    margin: 16px 0;
	    height: 24px;
	    text-align: center;
	}
	.no-info {
	    font-weight: 500;
	    font-size: 16px;
	    line-height: 24px;
	    text-align: center;
	    color: #a6a6a6;
	    mix-blend-mode: normal;
	    margin: auto;
	}
	.input-list .inputs-max .input-content {
	    padding: 0 16px 0 24px;
	    width: 100%;
	    box-sizing: border-box;
	}
	.dklistcss{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		border-bottom: 2px solid #1a1a1a;
	}
	.dklistcss .bai{
		color: #fff;
	}
	.dklistcss .lv{
		color: #c2e22a;
	}
	.dklistcss .hong{
		color: #ce7735;
	}
	.day-lists-new {
	    z-index: 10;
	    width: calc(100% - 24px);
	    height: 84px;
	    overflow-y: auto;
	    position: absolute;
	    top: 50px;
	    border-radius: 10px;
	    background: #292929fc;
	    color: #ffffff4d;
	    left: 0;
	    font-weight: 500;
	    font-size: 14px;
	    padding: 8px 12px;
		
		.day-list-new {
		    height: 41px;
		    line-height: 41px;
		}
		.day-list1-new {
		    color: #fff;
		}
	}
</style>
