export default {
	"964128-0": "天",
	"964128-1": "哎呀!",
	"964128-2": "您还没有交易记录。列表为空。",
	"652934-0": "AI量化交易",
	"652934-1": "Ai量化金额",
	"652934-2": "今日收益",
	"652934-3": "总收益",
	"652934-4": "资金安全",
	"652934-5": "收益稳定",
	"652934-6": "操作简单",
	"652934-7": "展开更多",
	"652934-8": "创建Ai量化",
	"652934-9": "期限",
	"652934-10": "金额",
	"652934-11": "回报率",
	"652934-12": "额度",
	"652934-13": "确认",
	"652934-14": "顶部信息",
	"652934-15": "主题模式",
	"652934-16": "测试---",
	"314962-0": "谷歌 2FA",
	"314962-1": "下载Google Authenticator进行绑定",
	"314962-2": "复制验证码",
	"314962-3": "输入验证码",
	"314962-4": "请求失败:",
	"314962-5": "验证码已复制",
	"314962-6": "无法复制文本:",
	"314962-7": "复制失败，请重试",
	"199917-0": "设置",
	"199917-1": "通知",
	"199917-2": "谷歌 2FA",
	"199917-3": "电子邮件",
	"199917-4": "语言",
	"199917-5": "版本",
	"199917-6": "退出",
	"Confirm":'确认',
	"Cancel":'取消',
	account: {
		 Start_making_money_plan: "开始赚钱计划",
		 Market: "市场",
		 Migital_currency: "数位货币",
		 Foreign_exchange: "外汇",
		 Noble_metal: "贵金属",
		 Send_Cryop_Now: "立即发送冻结",
		 Send: "传送",
		 Receive: "收到",
		 Buy: "买",
		 Currency_account: "货币帐户",
		 Contract_account: "合约帐户",
		 Transfer: "转移",
		 Select_a_wallet: "选择钱包",
		 Select_Coin: "选择硬币",
		 Confirm: "确认",
		 Wallet: "钱包",
		 Availavle: "可用的",
		 Frozen: "冻结",
		 Convert: "转变",
		 Receiving_Address: "收货地址",
		 Amount: "数量",
		 Max: "最大限度",
		 Send_Now: "现在发送",
		 Please_check_if: "寄送前请检查您的收货地址是否正确，以免造成资产损失提现费用为",
		 new1: '加密货币',
		 new2: '指数',
		 new3: '大宗',
		 new4: '外汇',
		 new5: '总资产',
	},
	home: {
		USDTAccount: 'USDT 帐户',
		USTD: 'USDT',
		USDT: 'USDT',
		USTC: 'USDC',
		open: '授权',
		ETHTodayProfit: 'ETH今日利润',
		totalRevenue: '总收入',
		portfolio: '投资',
		reliableSecurity: '可靠的安全保障',
		stableReliable: '投资稳定可靠',
		convenienEasy: '操作方便、简单',
		pleaseLookForward: '敬请期待...',
		rateOfReturn: '回报率',
		walletnot: '请安装 MetaMask 或任何以太坊扩充钱包',
		wallettip: '请绑定您的地址，以免影响体验',
	},
	"ConnectW": "连接钱包",
	"dappbrowser": "本网站仅接受去中心化钱包DAPP浏览器的访问。",
	"Coins POS": "CoinB Pos",
	"9dcf43fa47": "9dcf43fa47",
	"Earn Interest": "赚取利息",
	"$": "$",
	"ETH Today's Profit": "ETH今日利润",
	"Wallet": "钱包",
	"Record": "订单",
	"Support": "客服",
	"General": "综合",
	"Notifications": "通知",
	"Invite Friends": "邀请好友",
	"FAQ": "常见问题",
	"Privacy & Security": "隐私与安全",
	"Legality": "隐私权政策",
	"Authenticator": "实名认证",
	"Settings": "设定",
	"Change Language": "改变语言",
	"Daytime mode": "日间模式",
	"Black mode": "夜间模式",
	"BTC": "BTC",
	"Bitcoin": "Bitcoin",
	"ETH": "ETH",
	"ethereum": "ethereum",
	"USDT": "USDT",
	"tether": "tether",
	"USDC": "USDC",
	"usd-coin": "usd-coin",

	// marketInfo.vue
	'Open': '开盘',
	'High': '最高',
	'Low': '最低',
	'Close': '闭盘',
	'Up': '买涨',
	'Down': '买跌',
	'Time': '时间',
	'TransactionFee': '手续费',
	'AvailableBalance': '可用余额',
	'submit': '订阅',
	'Direction': '方向',
	'Profit': '利润',
	'Quantity': '数量',
	'Price': '价格',
	'different': '点击不同比例可显示最低限制',
	'lessthan': '至少不能小于',
	'least': '最少：',
	'Transactionhistory': '交易记录',
	'Transactionrecords': '交易记录',
	'Number': '数量',
	'Profitloss': '利润损失',
	'Ror': '回报',
	'operate': '操作',
	'About_us': '关于我们',





	//钱包
	TransactiónDetails: '交易明细',
	dealdetails: '交易详情',
	ViewTransactionHistory: '查看交易记录',
	Deposit: '充值',
	Rechargeaddress: '充值地址：',
	DepositAmount: '存款金额：',
	EnterDepositAmount: '输入存款金额',
	EnterHash: '输入哈希值',
	Hash: '哈希值：',
	Voucherimage: '凭证影像：',
	Continue: '继续',
	Withdraw: '提现',
	Withdrawal: '取回：',
	EnterAmounttoWithdraw: '输入提款金额',
	Available: "可用的：",
	ReceiveAddress: '接收地址：',
	EnterWalletAddress: '输入钱包地址',
	WithdrawalDesc: '以英文提现需要1%的手续费，需要网路节点确认后才能到帐，请勿将加密货币转给陌生人',
	Exchange: '兑换',
	Send: '传送:',
	Max: '最大限度:',
	rechargeAmount: '请输入充值金额',
	hashcode: '哈希值和截图不能同时为空',
	correcthashcode: '请输入正确的哈希值',
	maximumwithdrawalamount: '最大提款金额为',
	Incorrectamountformat: '金额格式不正确',
	copy: '复制',


	//tab2
	"Total Profit": "利润总额",
	"Today's Profit": "今日利润",
	"What is Automated Trading?": "什么是自动交易？",
	"Learn about earning": "了解收入",
	"Staking Period": "质押期",
	"Staking Amount": "质押数量",
	"Yield": "效益",
	"Limit": "限额",
	"Subscribe": "订阅",

	//tab3
	"Proof of Stake": "权益證明",
	"ETH Today is Price": "ETH 今日价格",
	"ETH Today's Increase": "ETH今日涨幅",
	"Joint Staking": "联合质押",
	"Individual Staking": "个人质押",
	"tab3long1": "透过智能合约配对参与者，实现联合下注，总下注金额达到32 ETH。",
	"tab3long2": "独立下注不需要透过智能合约来配对参与者。 达到32 ETH的下注金额，即可完成个人下注",


	//web_view
	"webviewlong1": "加密货币空投有助于新兴的基于区块链的项目的发展。 他们的根源透过奖励和共同目标将社区成员联系在一起。 成功空投的结果是更强大的社群、更好的专案以及对最终用户的奖励。 多年来，我们看到许多空投是在工作量證明（POW）区块链上进行的。 股权證明（POS）以太坊链现在支援数十个网路中的数百个项目加密货币空投。 随著 PoS 成为主导共识范式，以太坊网路上的空投数量并没有显示出放缓的迹象。",
	"webviewlong2": "透过质押您的加密货币，您有资格根据您质押的加密货币数量解锁有价值的空投奖励。",
	"webviewlong3": "奖励股权只提供给权益持有者，它来自数百个不同的专案空投，其收益率远高于任何一个独立的空投。",
	"webviewlong4": "对于在交易所存入的加密货币所有者来说，空投奖励通常很困难，而且有些托管人根本不支援空投。 这就是为什么管理自己的加密货币很重要。 以太坊是一个非托管实体，可以在所有主要网路上进行质押",
	"Introduction": "介紹",
	"webviewlong5": "领先的区块链平台以太坊正在透过以太坊2.0升级从工作量證明（PoW）共识机制过渡到权益證明（PoS）机制。 PoS 质押是这项转变的重要组成部分，为用户提供支援网路安全和营运的机会，同时有可能获得奖励。 本文概述了以太坊 PoS 质押及其好处。",
	"Ethereum 2.0 and Proof of Stake (PoS)": "以太坊 2.0 和权益證明 (PoS)",
	"webviewlong6": '以太坊 2.0 是一项重大网路升级，旨在提高可扩展性、安全性和永续性。 关键的变化之一是从能源密集的 PoW 共识机制转向更环保的 PoS 机制。 在 PoS 中，验證者被选择创建新区块并根据他们持有的加密货币数量并愿意「抵押」作为抵押品来确认交易。',
	"The Staking Process": "质押过程",
	"webviewlong7": "要参与以太坊 PoS 质押，用户必须透过将其存入以太坊 2.0 存款合约来质押至少 32 ETH。 一旦质押，ETH 就会被锁定一段时间，并作为确保网路安全的抵押品。 验證者是根据抵押的 ETH 数量及其线上活动来选择的。 他们负责提议和验證新区块，以及确认网路上的交易。",
	"Staking Rewards and Risks": "质押奖励和风险",
	"webviewlong8": "透过参与 PoS 质押，用户可以获得新铸造的 ETH 和交易费用形式的奖励。 奖励基于质押的 ETH 数量和整体网路活动。 然而，质押也伴随著风险，例如，如果验證者行为恶意或未能持续保持在线，则可能会受到处罚。 此外，质押的 ETH 会被锁定一段时间，使其缺乏流动性并容易受到价格波动的影响。",
	"Joint Staking and Individual Staking": "联合质押与个人质押",
	"webviewlong9": "对于没有足够 32 ETH 单独质押的用户来说，联合质押提供了另一种选择。 在联合质押中，多个用户将他们的 ETH 集中在一起以达到所需的质押金。 这种池化通常透过智能合约或质押服务来促进，允许用户以较小数量的 ETH 参与以太坊 PoS 质押。",
	"Conclusion": "结论",
	"webviewlong10": "以太坊 PoS 质押是以太坊 2.0 升级的一个重要方面，为用户提供支援网路安全、改善去中心化和赚取奖励的机会。 透过了解质押过程、其回报和风险，用户可以就参与以太坊网路的这一关键演变做出明智的决定。",


	//mywallet
	"Total": "总额",

	//record
	"Account": "帐户",
	"interest-bearing": "利息",
	"POS Staking": "POS 质押",
	"convert": "兑换",
	"transaction": "交易",
	"pledge": "抵押",
	"recordno": "你还没有任何消息。 该列表为空。",
	"Oops": "哎呀",
	"recharge": "充值",
	"payment": "支付",
	"staking": "质押",
	"income": "收益",
	"Pledge quantity": "质押数量",
	"cycle": "周期",
	"Days Remaining": "剩余天数",
	"cumulative gain": "累积增益",
	"Price increase": "价格上涨",
	"Price decrease": "价格下降",
	"Opening price": "开盘价",
	"Closing price": "收盘价",
	"loss": "损失",
	"pfofit": "利润",
	"amount": "数量",

	//notifiCations
	"notifiCationslong1": "注：VIP注册是根据对应整体帐户的储值金额来决定您的等级。 此区间需扣除现有帐户提领后的总储值金额来决定会员等级。",
	"notifiCationslong2": "会员期限也根据不同等级进行划分。 为维持您的会员资格，您需要在规定时间内每次充值至少5,000 USDT才能享有会员权益。 每次储值会员资格后，周期重新计算并可累积。",
	"notifiCationslong3": "客户服务依会员等级进行划分。 官方客服由APP工作人员提供，为广大会员提供统一服务。 专属秘书数量有限，保證更好的服务。 专属私人秘书为使用者提供专属接待服务和理财建议。",
	"notifiCationslong4": "提前联络客服预约储值。 在规定时间内完成储值，可依对应会员等级额外获得预约储值奖励。",
	"notifiCationslong5": "VIP1：基于UID帐户，累计储值10,000 USD，可获得177 USDT奖励。 达到VIP等级并获得普通会员勋章后，积分将空投至帐户。",
	"notifiCationslong6": "VIP2：基于UID帐户，累计储值达30,000 USD，送777 USDT。 奖金金额将在达到VIP等级并获得铜牌会员勋章后立即存入帐户。",
	"notifiCationslong7": "VIP3：根据UID帐户，累计储值7万美元，即可获赠1777 USDT。 达到VIP等级并获得银卡会员勋章后，积分将空投至帐户。 此外，您将在下次预订储值金额的基础上获得额外1.5%的奖励。",
	"notifiCationslong8": "VIP4：根据UID帐户，累计储值15万美元，获赠2,777 USDT。 达到VIP等级并获得金牌会员勋章后，积分将空投至帐户。 此外，您将在下次预订储值金额上获得额外 2% 的奖励。",
	"notifiCationslong9": "VIP5：基于UID帐户，累计储值达300,000 USD，赠送4777 USDT。 达到VIP等级并获得钻石VIP徽章后，积分将空投至帐户。 此外，下次预订储值金额还将额外获得2.5%的奖励。",
	"notifiCationslong10": "VIP6：基于UID帐户，累计储值达800,000 USD，赠送16,888 USDT。 奖金金额将在达到VIP等级并获得黑钻会员徽章后立即空投至帐户，并额外获得下次预登记充值金额的3%。 您将有一名私人专属秘书来提供任何财务建议或处理税务问题。",
	"notifiCationslong11": "VIP7：基于UID帐户，累计储值金额达300万美元，赠送15天欧洲豪华之旅（有折扣）。 授予CoinBpos全球终身荣誉VIP，并获得皇冠会员勋章。 下次预约储值将可额外获得3.5%的奖励。",
	"notifiCationslong12": "VIP8：根据UID帐户，累计储值达800万美元，可获得CoinBpos摩洛哥年宴礼券一张。 奖励：1 BTC，并获得CoinBpos全球终身荣誉VIP。 下次预约储值可额外获得4%奖励。",
	"notifiCationslong13": "VIP9：基于UID帐户，累计入3000万美元，即可获得CoinBpos 3%的股份。 并享有每年3%的利润分红。 免费赠送 Cronix 纯金纪念徽章。",

	//invite
	"Refer and Earn": "推荐并赚取",
	"Copy Link": "复制连结",

	//faq
	"Frequently Asked Questions": "经常问的问题",

	//authenticator
	"mailbox": "邮件",
	"Front photo": "正面照片",
	"Back photo": "背面照片",
	"Handheld ID photo": "手持证件照",
	"define": "确定",

	//legality
	"legalitylong1": "Coinbpos由多家加密货币公司组成，他们共同提供工具来帮助加密社群创建、发展、维护社群稳定，为数以千计的去中心化应用程式提供动力，推动加密货币的未来以及推动传统货币的数位化和促进全球经济繁荣。",
	"legalitylong2": "Coinbpos 内的每家公司均充当处理与其服务相关的个人资料的资料控制者，每项服务的资料控制者是：",
	"legalitylong3": "1.区块链技术－以太坊区块链。",
	"legalitylong4": "2.去中心化钱包-Trust钱包、MetaMask和加密钱包。",
	"legalitylong5": "3. 交易技术-Coinbase、Crypto、linch、Binance。",
	"legalitylong6": "隐私政策详细说明了Coinbpos如何根据其在相关数据保护法律下的义务处理个人数据，包括欧盟的《通用数据保护条例》（GDPR），",
	"legalitylong7": '《加州消费者隐私法》（CCPA）和其他一般法律（统称为「资料保护法」*）。',

	//language
	"language": "语言",
	"Confirm": "确认",

	"newtext1": "退出",
	"newtext2": "取消",
	"newtext3": "确定退出此账号吗？",
	"Confirm": "确认",
	"Cancel": "取消",
	"newtext4": "中文简体",
	"newtext5": "中文繁体",
	"newtext6": "English",
	"newtext7": "日本語",

	"newtext8": "登录",
	"newtext9": "用户名",
	"newtext10": "手机号",
	"newtext11": "请输入用户名",
	"newtext12": "请输入手机号",
	"newtext13": "密码",
	"newtext14": "请输入密码",
	"newtext15": "还没有帐户",
	"newtext16": "注册",
	"newtext17": "所在国家",
	"newtext18": "选择国家",
	"newtext19": "手机号验证码",
	"newtext20": "请输入验证码",
	"newtext21": "邮箱地址",
	"newtext22": "请输入邮箱地址",
	"newtext23": "确认密码",
	"newtext24": "请输入确认密码",
	"newtext25": "拥有一个帐户",
	"newtext26": "发送验证码",
	"newtext27": "手机号不正确",
	"newtext28": "发送成功",
	"newtext29": "注册成功",

	"newtext30": '货币',
	"newtext31": '外汇',
	"newtext32": '贵金属',
	
	"newtext33": '账户',
	 "newtext34": '平台活动',
	 "newtext35": '在线客服',
	 "newtext36": '邀请',
	 "newtext37": 'KYC 验证',
	 "newtext38": '设置',
	wallet_p: {
		text1: "复制地址",
		text2: '确认',
		text3: '从...交换',
		text4: "交换至",
		text5: "最大",
		text6: "邀请",
		text7: '获取推荐奖励',
		text8: "当您的朋友参与AI套利并租用挖矿机时，您可以获得推荐奖励",
		text9: "复制",
		text10: '分享链接',
		text11: "开始验证",
		text12: "国家",
		text13: "请选择一个国家",
		text14: "名字",
		text15: "请输入您的名字",
		text16: "姓氏",
		text17: "请输入您的姓氏",
		text18: "证书类型",
		text19: "请选择证书类型",
		text20: "证书编号",
		text21: "请输入证书编号",
		text22: "请上传您的身份证正面照片",
		text23: "请上传您的身份证反面照片",
		text24: "请上传您的护照正面照片",
		text25: "请上传您的护照反面照片",
		text26: "电子邮件",
		text27: "请输入您的电子邮件",
		text28: "发送",
		text29: "验证码",
		text30: "请输入您的验证码",
		text31: "身份证",
		text32: "护照",
		text33: "取消",
		text34: "国家/地区",
		text35: "请输入正确的信息进行身份验证提交",
		text36: "发送成功！",
		text37: "电子邮件格式错误！",
		text38: "驾照",
		text39: "请上传您驾照的正面照片",
		text40: "请上传您驾照的反面照片",
		
		text41: "身份证号码",
		text42: "请输入身份证号码",
		text43: "护照号码",
		text44: "请输入护照号码",
		text45: "驾照号码",
		text46: "请输入驾照号码",
		
		text47:'请上传您手持驾照的照片',
		text48:'请上传您手持身分证的照片',
		text49:'请上传您手持护照的照片',
		text50:'模拟帐户',
	},
	jiaoyi: {
		 text1: '秒合约',
		 text2: '永续合约',
		 text3: '现货交易',
		 text4: '立即委托',
		 text5: '帐户类型',
		 text6: '交货时间',
		 text7: '价格区间',
		 text8: '购买价格',
		 text9: '费用',
		 text10: '余额',
		 text11: '至少',
		 text12: '预期',
		 text13: '购买金额',
		 text14: '方向',
		 text15: '购买价格',
		 text16: '即时价格',
		 text17: '倒数计时',
		 text18: '我的合约',
		 text19: '利润',
		 text20: '合约',
		 text21: '交货价格',
		 text22: '交货时间',
		 text23: '市价',
		 text24: '涨',
		 text25: '跌',
		 text26: '购买价格',
		 text27: '最大',
		 text28: '利润',
		 text29: '手续费',
		 text30: '买入',
		 text31: '卖出',
		 text32: '购买数量',
		 text33: '真实帐户',
		 text34: '虚拟帐户',
		 text35: '暂无数据',
		 text36: '当前持仓',
		 text37: '历史委托',
		 text38: '平仓',
		 text39: '保证金',
		 text40: '盈亏',
		 text41: '持有',
		 text42: '买进纪录',
		 text43: '卖出记录',
		 text44: '可用',
		 text45: '折合',
		 text46: '认证成功',
		 text47: '购买时间',
		 text48: '未读',
		 text49: '已读',
		 text50: '托管订单',
		 text51: '总套利',
		 text58: '今日收益',
		 text52: '人工智慧机器人是如何工作的',
		 text53: '套利产品',
		 text54: '天',
		 text55: '金额',
		 text56: '每日收入',
		 text57: '货币类型',
		 text59: '加入AI套利',
		 text60: '零风险，快速回报',
		 text61: 'AI套利',
		 text62: '预期收益',
		 text63: '套利硬币类型',
		 text64: '托管金额',
		 text65: '立即托管',
		 text66: '每日收入发送到您的USDT钱包',
		 text67: '投资基金零风险',
		 text68: '您可以随时取回资金',
		 text69: '人工智能每天24小时工作',
		 text70: '累计收益',
		 text71: '到期时间',
		 text72: '全部',
		 text73: '进行中',
		 text74: '已完成',
		 text75: '托管详情',
		 text76: '选择币种',
		 text77: '技术支持',
		 text78: '发送邮件',
	},
	tabbar:{
		text1:'首页',
		text2:'市场',
		text3:'Ai量化',
		text4:'贷款',
		text5:'账户',
		text6:'账户余额',
		text7:'今日收益',
		text8:'回报率',
		text9:'更多',
		text10:'即将推出',
		text11:'新币预售',
		text12:'踏上数字财富之旅',
		text13:'立即购买',
		text14:'操作简单',
		text15:'AI量化交易',
		text16:'安全 / 稳定 / 简单',
		text17:'创建交易',
		text18:'总佣金',
		text19:'与你的朋友分享此链接，可获得高达 $100 的奖励。',
		text20:'推荐链接',
		text21:'通过社交软件与您的朋友分享',
	},
	tab4:{
		text1: "账户余额",
		text2: "今日收益",
		text3: "AI量化",
		text4: "回报率",
		text5: "存款",
		text6: "电汇",
		text7: "提款",
		text8: "存款",
		text9: "钱包地址",
		text10: "复制",
		text11: "上传凭证",
		text12: "请仔细检查存款地址以确保正确，因为错误的转账可能无法被撤销。存款需要获得 5 个区块的确认才能记入您的账户。",
		text13: "电汇",
		text14: "发起电汇前:",
		text15: "在发起电汇之前，请联系我们的客户服务团队获取准确的电汇账户信息。这一步确保您资金的安全和顺利到账。",
		text16: "电汇处理时间:",
		text17: "在发起电汇之前，请联系我们的客户服务团队获取准确的电汇账户信息。这一步确保您资金的安全和顺利到账。",
		text18: "电汇过程中的协助:",
		text19: "如果您在电汇过程中遇到任何问题或有疑问，请随时联系我们的客户服务团队。我们致力于提供协助和支持，确保为您提供一个顺畅的交易体验。",
		text20: "金额",
		text21: "提款货币",
		text22: "确认",
		text23: "请务必确认您的接收地址，因为错误的转账是无法撤销的。提现需要支付 3% 的手续费，并需要在获得 5 个网络确认之后才能记入您的账户。",
		text24: "期权订单",
		text25: "合约订单",
		text26: "Ai量化订单",
		text27: "历史记录",
		text28: "邀请好友",
		text29: "常见问答",
		text30: "联系我们",
		text31: "关于我们",
		text32: "语言更改",
		text33: "退出登陆"
	},
	uploadProof: {
		text1: "上传凭证",
		text2: "选择图片",
		text3: "预览",
		text4: "上传您的存款截图",
		text5: "金额",
		text6: "交易哈希",
		text7: "后6位数字",
		text8: "请上传您的加密货币支付截图，并在提交后等待审核",
		text9: "确认"
	},
	newadd:{
		text1:'成功',
		text2:'失败',
		text3:'待办',
		text4:'我的 Cown 代币',
		text5:'供应',
		text6:'已售出',
		text7:'预售结束',
		text8:'金额',
		text9:'兑换',
		text10:'技术特点',
		text11:'新币采用区块链技术，具有快速交易速度、低手续费、高交易安全性，以及智能合约功能和隐私保护技术。',
		text12:'项目愿景',
		text13:'新币的愿景是成为全球领先的数字资产交易平台，为用户提供更安全、便捷和高效的数字资产交易服务。',
		text14:'应用场景',
		text15:'新币具有广泛的应用场景，包括数字货币交易、数字证券交易、数字资产交易、支付结算、供应链金融等领域。',
		text16:'贷款额度',
		text17:'验证您的身份并获得更多服务！',
		text18:'开始验证',
		text19:'我想借...',
		text20:'贷款期限',
		text21:'天',
		text22:'日利率',
		text23:'总利息金额',
		text24:'贷款后 1 天内无需支付利息，之后需支付利息。',
		text25:'现在借款',
		text26:'借款记录',
		text27:'尚未借款',
		text28:'找不到您的贷款信息',
		text29:'超过贷款额度范围',
		text30:'申请中',
		text31:'进行中',
		text32:'已结束',
		text33:'开启',
		text34:'关闭',
		text35:'记录',
		text36:'存款',
		text37:'提款',
		text38:'哎呀!',
		text39:'您还没有交易记录。列表为空。',
		text40:'请输入金额',
		text41:'正常',
		text42:'已结束',
		
		text43:"让我们从登录开始！",
		text44:"邮箱",
		text45:"输入您的邮箱...",
		text46:"密码",
		text47:"输入您的密码...",
		text48:"忘记密码？",
		text49:"登录",
		text50:"没有账户？",
		text51:"注册",
		text52:"验证码已发送",
		text53:"验证",
		text54:"让我们从注册开始！",
		text55:"确认密码",
		text56:"推荐/促销代码",
		text57:"输入推荐/促销代码（可选）",
		text59:"已有账户？",
		text60:"请输入您的电子邮件地址",
		text61:"请输入您的密码",
		text62:"请输入确认密码",
		
		text63:'用户认证',
		text64:'信用评分',
		text65:'待审核',
		text66:'审核通过',
		text67:'感谢您提交 KYC 文件。我们的团队将在48小时内审核您的信息。请耐心等待审核结果。',
		text68:'终止交易',
		text69:'终止成功',
		
		text70:'状态',
		text71:'发送货币',
		text72:'接受金额',
		
		text73:'提前终止',
		text74:'提前终止将导致罚款',
		text75:'违约金',
		text76:'退款金额',
		
		text77:'借款详情',
		text78:'请检查您的借款详情并确认',
		text79:'您借款金额',
		text80:'借款期限',
		text81:'请确认并签署',
		text82:'借款协议',
		text83:'立即借款',
		text84:'请仔细阅读借款协议并提供签名',
		text85:"以下各方签订日期为{shengxiaori} {shengxiaoyue}月 {shengxiaonian}（“生效日期”）的贷款协议（“{xieyimingcheng}”）：",
		text86:'借款人：',
		text87:'名字：',
		text88:'姓氏：',
		text89:'地址：',
		text90:'电话：',
		text91:'贷款人：',
		text92:'名称：',
		text93:'统称为“双方”。',
		text94:'还款条款：',
		text95:'贷款金额：{dknum}',
		text96:'借款人同意偿还${dknum}（“贷款”）。',
		text97:'利率：{interest}%',
		text98:'商定的利率为{interest}%（按日计算）。',
		text99:'贷款期限：{tian}',
		text100:'这笔贷款的期限是{tian} 天。',
		text101:'还款方式：',
		text102:'借款人同意在到期时偿还贷款人 $ {dknum} 本金和 $ {zonglixinum} 利息。',
		text103:'还款适用性：',
		text104:'滞纳金：{zhinajin} * 日',
		text105:'如果借款人未能按时还款，借款人应支付{zhinajin}的滞纳金（按日计算），该费用应被视为滞纳金而不是罚款。',
		text106:'提前还款：',
		text107:'借款人提前还款不会受到任何处罚。',
		text108:'默认：',
		text109:'如果借款人未能按时还款，借款人应立即支付所有未偿还的本金和利息，无需通知或要求。',
		text110:'费用：',
		text111:'若借款人未能按时履行还款义务，借款人同意承担贷款人在催收过程中产生的一切费用，包括但不限于律师费。',
		text112:'可分割性：',
		text113:'如果本协议的任何条款被发现无效或无法执行，则该条款应作废，但所有其他条款仍应有效并具有约束力。',
		text114:'法律效力：',
		text115:'本协议对双方具有法律约束力，可在美国和欧洲境内签署和执行。 双方声明有权签署本协议。',
		text116:'各种各样的：',
		text117:'根据本协议条款，公司应以账户余额中的货币形式支付。 双方均有权签署本协议。 任何一方均不得侵犯第三方的权利或违反双方之间的任何其他协议。',
		text118:'适用法律：',
		text119:'本协议应根据借款人所在国的法律解释和执行。',
		text120:'整个协议：',
		text121:'双方确认并同意，本协议构成双方之间的完整协议。 如果任何一方希望更改、添加或修改任何条款，则必须以书面形式提出并由双方签署。',
		text122:'双方同意以上条款并签字如下：',
		text123:'贷方：{xieyimingcheng}',
		text124:'“借款人”',
		text125:'借款人签名：',
		text126:'签名日期：{shengxiaori} {shengxiaoyue}月 {shengxiaonian}',
		text127:'签名',
		text128:'请提供与您的身份证上相同的真实签名',
		text129:'重新签署',
		text130:'确认',
		text131:'取消',
		text132:'还款金额',
		text133:'还款日期',
		text134:'还款',
		text135:'下载PDF',
		
		text136:'请先实名认证',
		text137:'已验证',
	}
}
