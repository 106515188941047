<template>
  <van-popup 
      :close-on-click-overlay="false"
      v-model="show" style="background-color:transparent"
  >
      <div class="loadingWrap">
          <van-loading v-if="show" type="spinner" />
      </div>
  </van-popup>
</template>

<script>
export default {
  name:'loading',
  data () {
      return {
          show:true,
      }
  },
  props:{
      // show:{
      //     type:Boolean,  
      //     default:false
      // },
  }
 
}
</script>

<style lang='scss' scoped>
.loadingWrap{
 
 .van-popup{
      background-color:rgba(0,0,0,.7) 
  }
}
</style>